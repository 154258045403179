import styled from 'styled-components';
import * as defaultTheme from '../variables';

const HeaderStyled = styled.header`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  color: ${p => p.theme.textColorWhite};
  z-index: 100;

  @media (${p => p.theme.smMin}) {
    background-color: ${p => p.theme.bgColorBlack};
  }

  .Header__inner {
    width: 100%;
    max-width: ${p => p.theme.maxWidth}px;
    margin: 0 auto;

    @media (${p => p.theme.smMin}) {
      display: flex;
      justify-content: space-between;
      padding: 0 ${p => p.theme.space * 3}px;
    }

    @media (${p => p.theme.lgMin}) {
      padding: 0;
    }
  }

  .Header__main {
    display: flex;
    align-items: center;
    position: relative;
    height: 40px;
    background-color: ${p => p.theme.bgColorBlack};

    @media (${p => p.theme.xsMax}) {
      margin: 0 auto;
      padding: 0 ${p => p.theme.space * 2}px;
    }

    @media (${p => p.theme.smMin}) {
      height: 64px;
    }
  }

  .Header__menu {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    margin-left: -${p => p.theme.space}px;
    font-size: 18px;

    @media (${p => p.theme.smMin}) {
      margin-right: ${p => p.theme.space * 3}px;
    }
  }

  .Header__logo {
    @media (${p => p.theme.xsMax}) {
      position: absolute;
      left: 50%;
      top: 50%;
      height: 34px;
      transform: translate(-50%, -50%);
    }

    @media (${p => p.theme.smMin}) {
      height: 42px;
    }
  }

  .Header__nav {
    display: flex;
    justify-content: space-around;
    align-items: center;

    @media (${p => p.theme.xsMax}) {
      height: 25px;
      background-color: rgba(0, 0, 0, 0.5);
    }
  }

  .Header__navLink {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: ${p => p.theme.space}px;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;

    @media (${p => p.theme.smMin}) {
      font-size: 14px;
    }

    &:not(:last-child) {
      @media (${p => p.theme.smMin}) {
        margin-right: ${p => p.theme.space}px;
      }

      @media (${p => p.theme.lgMin}) {
        margin-right: ${p => p.theme.space * 2}px;
      }
    }
  }

  .Header__navBadge {
    flex-shrink: 0;
    min-width: 20px;
    height: 20px;
    margin-left: ${p => p.theme.space}px;
    padding: 0 ${p => p.theme.space / 2}px;
    color: ${p => p.theme.textColorBlack};
    background-color: ${p => p.theme.yellow};
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    line-height: 20px;
    border-radius: 20px;
  }

  .Header__dropdownLink {
    position: relative;
    display: block;
    padding: 4px 0;
    color: #fff;
    font-size: 12px;
    letter-spacing: 4px;
    text-align: left;
    text-transform: uppercase;
  }
`;

HeaderStyled.defaultProps = {
  theme: defaultTheme,
};

export default HeaderStyled;
