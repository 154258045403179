import React from 'react';

const RulesCa = () => (
  <div>
    <h3>
      <strong>Concours Rockstar Gears of War 5 Règlements officiels</strong>
    </h3>

    <p>
      <strong>
        AUCUN ACHAT OU PAIEMENT REQUIS. UN ACHAT N’AUGMENTERA PAS VOS CHANCES DE
        GAGNER.
      </strong>
    </p>
    <p>
      <strong>
        EN PARTICIPANT AU CONCOURS VOUS CONSENTEZ AUX PRÉSENTS RÈGLEMENTS
        OFFICIELS.
      </strong>
    </p>

    <ol>
      <li>
        <strong>Admissibilité:</strong> Le concours Rockstar Gears 5 (le
        "Concours") est ouvert uniquement aux résidents des 50 États américains
        et du District de Columbia et au Canada, qui ont l'âge de la majorité
        dans la région/province de résidence. Nul là où interdit par la loi.
        Aucun achat requis pour participer au concours. Les employés de
        Rockstar, Inc., 101 Convention Center Dr., Suite 777, Las Vegas, NV
        89109 (le "sponsor"), Brandmovers, Inc., 590, rue Means, Suite 250,
        Atlanta, GA 30318 ("Administrateur"), ou l'une de leurs sociétés
        affiliées, filiales, agences de publicité, ou toute autre société ou
        individu impliqué dans la conception, l'exécution de la production ou de
        la distribution du concours, y compris, sans limitation leur famille
        immédiate (conjoint, parents et beaux-parents, frères et sœurs, l'étape-
        et les enfants et beaux-enfants) et les membres des ménages de chaque
        employé ne sont pas éligibles pour participer ou gagner. Le concours est
        assujetti à toutes les lois fédérales, d'état, et les lois et règlements
        locaux. La participation au concours constitue un accord complet et
        inconditionnel du participant avec ce Règlement officiel et les
        décisions du commanditaire, qui sont finales et obligatoires pour toutes
        les questions liées au concours. Gagner un prix est assujetti à
        l'accomplissement de toutes les exigences énoncées dans les présentes.
      </li>
      <li>
        <strong>Périodes de participation au concours:</strong> Le concours
        débute le 1er Septembre 2019 à 12:00:01 PM Heure de l’Est (“ET”) et se
        termine le 30 novembre 2019 à 11:59:59 AM ET (la “période du concours").
        L'horloge du site Web (définie ci-dessous) est le chronomètre officiel
        de ce concours. La période du concours comprend quatre-vingt-dix (90)
        groupes de participants quotidiens et un groupe de participants au grand
        prix. Aux fins de ce concours, chaque groupe de participations quotidien
        commence à 12 h 00 (heure de l'Est) et se termine à 11 h 59 min 59 s, le
        lendemain matin, chaque jour de la période du concours (chacune, un
        «pool de participations quotidiennes»). ). Le gros lot correspond à la
        période du concours (le «grand prix»).
      </li>
      <li>
        <strong>Comment participer:</strong> Pendant la période du concours,
        cherchez un code sous l'onglet des boîtes spécialement marquées de
        Rockstar Energy Drink ("Code"). Une fois que vous avez votre code, pour
        s'inscrire et pour participer au concours, visitez le{' '}
        <a
          href="https://gears.rockstarenergy.com/"
          target="_blank"
          rel="noreferrer noopener"
        >
          Gears.RockstarEnergy.com
        </a>{' '}
        ("Site Web"), trouvez et remplissez le formulaire d'inscription en
        fournissant votre nom, adresse courriel, numéro de téléphone cellulaire,
        et la création d'un mot de passe pour vous connecter. Remarque : Une
        fois que vous vous êtes enregistrés, saisir votre code à l'endroit
        indiqué sur le site Web afin de recevoir automatiquement une (1) entrée
        en cette journée d' entrée quotidienne, une (1) inscription au bassin
        d'inscription hebdomadaire et une (1) inscription au bassin du Grand
        Prix applicables (basé sur le produit de votre code), en même temps. En
        cas de litige quant à l'identité du participant, l'entrée sera jugée
        faite par le titulaire de compte autorisé de l'adresse e-mail utilisée
        pour participer au concours, tel que déterminé par le commanditaire, à
        sa seule discrétion. L'information doit être saisie au clavier
        manuellement actionné ; utilisation de moyens automatisés de
        participation est interdite et les entrées effectuées par
        l'intermédiaire de tels moyens abusifs seront disqualifies. La
        responsabilité de la réception des bons de participation appartient
        uniquement au participant. Pour participer sans achat et sans obtenir un
        code ("AMOE"), veuillez envoyer une carte 3x5 avec votre nom complet,
        adresse, adresse courriel, numéro de téléphone, date de naissance, et la
        date d'entrée quotidienne que vous souhaitez, et veuillez envoyer à
        l'adresse postale suivante : P O Box 93245 Atlanta, GA 30377, Attn :
        Brandmovers,Inc. , qui doit être reçu par date dans l'entrée et le
        dessin graphique ci-dessous d'ici le 6/12/2019, à entrer dans la
        dernière journée de participation quotidienne, dernière journée de
        participation du bassin hebdomadaire et d'inscription hebdomadaire et
        finale Grand Prix entrée applicable (comme spécifié par le thème
        figurant dans votre AMOE) simultanément. Limite d'une (1) AMOE demande
        par enveloppe provenant de l’extérieur. Toutes les inscriptions
        deviennent la propriété du Commanditaire et ne seront pas reconnues ou
        retournées. La preuve d'envoi du formulaire d'inscription rempli ne
        constitue pas une preuve de réception ou la preuve de participation au
        concours.
      </li>
      <li>
        <strong>TIRAGES AU SORT:</strong> Autour de chacune des dates de tirage
        au sort indiquées dans le tableau ci-dessous du bassin d'entrées
        quotidiennes, un (1) premier prix et cinq (5) gagnants d'un deuxième
        prix seront sélectionnés par un tirage au sort, qui sera effectué par
        l'administrateur parmi tous les candidats admissibles. Les entrées
        reçues dans ce groupe d’entrées quotidiennes (et toutes les entrées non
        gagnantes des groupes d’entrées quotidiennes suivants). Les gagnants
        potentiels des premiers et deuxièmes prix seront notifiés par courrier
        électronique le ou vers le jour du tirage au sort applicable et
        disposeront de soixante-douze (72) heures à compter de l'envoi de la
        notification par l'administrateur aux premier et deuxième gagnants. Si
        le gagnant potentiel du premier ou du deuxième prix ne répond pas à la
        notification du gagnant dans un délai de soixante-douze (72) heures, il
        ou elle perdra son droit au prix et ce prix peut, à la seule discrétion
        du commanditaire, être attribué à un autre gagnant, si le temps le
        permet.
        <h4>PARTICIPATION QUOTIDIENNE ET TABLEAU DE TIRAGE</h4>
        <div style={{ overflowX: 'auto' }}>
          <table>
            <thead>
              <tr>
                <th># de bassin de participation quotidienneDaEntry P</th>
                <th>
                  Bassin de participation quotidienne en ligne à 12:00:01 AM ET
                  le :
                </th>
                <th>Se termine à 11:59:59 AM le:</th>
                <th>Participation AMOE reçu le:</th>
                <th>Tirage au sort le:</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>9/1/2019</td>
                <td>9/2/2019</td>
                <td>9/9/2019</td>
                <td>9/10/2019</td>
              </tr>
              <tr>
                <td>2</td>
                <td>9/2/2019</td>
                <td>9/3/2019</td>
                <td>9/9/2109</td>
                <td>9/10/2019</td>
              </tr>
              <tr>
                <td>3</td>
                <td>9/3/209</td>
                <td>9/4/2019</td>
                <td>9/10/2019</td>
                <td>9/11/2019</td>
              </tr>
              <tr>
                <td>4</td>
                <td>9/4/2019</td>
                <td>9/5/2019</td>
                <td>9/11/2019</td>
                <td>9/12/2019</td>
              </tr>
              <tr>
                <td>5</td>
                <td>9/5/2019</td>
                <td>9/6/2019</td>
                <td>9/12/219</td>
                <td>9/13/2019</td>
              </tr>
              <tr>
                <td>6</td>
                <td>9/6/2019</td>
                <td>9/7/2019</td>
                <td>9/13/2109</td>
                <td>9/16/2019</td>
              </tr>
              <tr>
                <td>7</td>
                <td>9/7/22019</td>
                <td>9/8/2019</td>
                <td>9/14/2019</td>
                <td>9/16/2019</td>
              </tr>
              <tr>
                <td>8</td>
                <td>9/8/2019</td>
                <td>9/9/2019</td>
                <td>9/16/2019</td>
                <td>9/17/2019</td>
              </tr>
              <tr>
                <td>9</td>
                <td>9/9/2019</td>
                <td>9/10/2019</td>
                <td>9/16/2019</td>
                <td>9/17/2019</td>
              </tr>
              <tr>
                <td>10</td>
                <td>9/10/2019</td>
                <td>9/11/2019</td>
                <td>9/17/2019</td>
                <td>9/18/2019</td>
              </tr>
              <tr>
                <td>11</td>
                <td>9/11/2019</td>
                <td>9/12/2019</td>
                <td>9/18/2019</td>
                <td>9/19/2019</td>
              </tr>
              <tr>
                <td>12</td>
                <td>9/12/2019</td>
                <td>9/13/2019</td>
                <td>9/19/2019</td>
                <td>9/20/2019</td>
              </tr>
              <tr>
                <td>13</td>
                <td>9/13/2019</td>
                <td>9/14/2019</td>
                <td>9/20/2019</td>
                <td>9/23/2019</td>
              </tr>
              <tr>
                <td>14</td>
                <td>9/14/2019</td>
                <td>9/15/2019</td>
                <td>9/21/2019</td>
                <td>9/24/2019</td>
              </tr>
              <tr>
                <td>15</td>
                <td>9/15/2019</td>
                <td>9/16/2019</td>
                <td>9/23/2019</td>
                <td>9/24/2019</td>
              </tr>
              <tr>
                <td>16</td>
                <td>9/16/2019</td>
                <td>9/17/2019</td>
                <td>9/23/2019</td>
                <td>9/24/2019</td>
              </tr>
              <tr>
                <td>17</td>
                <td>9/17/2019</td>
                <td>9/18/2019</td>
                <td>9/24/2019</td>
                <td>9/25/2019</td>
              </tr>
              <tr>
                <td>18</td>
                <td>9/18/2019</td>
                <td>9/19/2019</td>
                <td>9/25/2019</td>
                <td>9/26/2019</td>
              </tr>
              <tr>
                <td>19</td>
                <td>9/19/2019</td>
                <td>9/20/2019</td>
                <td>9/26/2019</td>
                <td>9/27/2019</td>
              </tr>
              <tr>
                <td>20</td>
                <td>9/20/2019</td>
                <td>9/21/2019</td>
                <td>9/27/2019</td>
                <td>9/30/2019</td>
              </tr>
              <tr>
                <td>21</td>
                <td>9/21/2019</td>
                <td>9/22/2019</td>
                <td>9/28/2019</td>
                <td>9/30/2019</td>
              </tr>
              <tr>
                <td>22</td>
                <td>9/22/2019</td>
                <td>9/23/2019</td>
                <td>9/30/2019</td>
                <td>10/1/2019</td>
              </tr>
              <tr>
                <td>23</td>
                <td>9/23/2019</td>
                <td>9/24/2019</td>
                <td>9/30/2019</td>
                <td>10/1/2019</td>
              </tr>
              <tr>
                <td>24</td>
                <td>9/24/2019</td>
                <td>9/25/2019</td>
                <td>10/1/2019</td>
                <td>10/2/2019</td>
              </tr>
              <tr>
                <td>25</td>
                <td>9/25/2019</td>
                <td>9/26/2019</td>
                <td>10/2/2019</td>
                <td>10/3/2019</td>
              </tr>
              <tr>
                <td>26</td>
                <td>9/26/2019</td>
                <td>9/27/2019</td>
                <td>10/3/2019</td>
                <td>10/4/2019</td>
              </tr>
              <tr>
                <td>27</td>
                <td>9/27/2019</td>
                <td>9/28/2019</td>
                <td>10/4/2019</td>
                <td>10/7/2019</td>
              </tr>
              <tr>
                <td>28</td>
                <td>9/28/2019</td>
                <td>9/29/2019</td>
                <td>10/5/2019</td>
                <td>10/7/2019</td>
              </tr>
              <tr>
                <td>29</td>
                <td>9/29/2019</td>
                <td>9/30/2019</td>
                <td>10/7/2019</td>
                <td>10/8/2019</td>
              </tr>
              <tr>
                <td>30</td>
                <td>9/30/2019</td>
                <td>10/1/2019</td>
                <td>10/7/2019</td>
                <td>10/8/2019</td>
              </tr>
              <tr>
                <td>31</td>
                <td>10/1/2019</td>
                <td>10/2/2019</td>
                <td>10/8/2019</td>
                <td>10/9/2019</td>
              </tr>
              <tr>
                <td>32</td>
                <td>10/2/2019</td>
                <td>10/3/2019</td>
                <td>10/9/2019</td>
                <td>10/10/2019</td>
              </tr>
              <tr>
                <td>33</td>
                <td>10/3/2019</td>
                <td>10/4/2019</td>
                <td>10/10/2019</td>
                <td>10/11/2019</td>
              </tr>
              <tr>
                <td>34</td>
                <td>10/4/2019</td>
                <td>10/5/2019</td>
                <td>10/11/2019</td>
                <td>10/14/2019</td>
              </tr>
              <tr>
                <td>35</td>
                <td>10/5/2019</td>
                <td>10/6/2019</td>
                <td>10/12/2019</td>
                <td>10/14/2019</td>
              </tr>
              <tr>
                <td>36</td>
                <td>10/6/2019</td>
                <td>10/7/2019</td>
                <td>10/14/2019</td>
                <td>10/15/2019</td>
              </tr>
              <tr>
                <td>37</td>
                <td>10/7/2019</td>
                <td>10/8/2019</td>
                <td>10/14/2019</td>
                <td>10/15/2019</td>
              </tr>
              <tr>
                <td>38</td>
                <td>10/8/2019</td>
                <td>10/9/2019</td>
                <td>10/15/2019</td>
                <td>10/16/2019</td>
              </tr>
              <tr>
                <td>39</td>
                <td>10/9/2019</td>
                <td>10/10/2019</td>
                <td>10/16/2019</td>
                <td>10/17/2019</td>
              </tr>
              <tr>
                <td>40</td>
                <td>10/10/2019</td>
                <td>10/11/2019</td>
                <td>10/17/2019</td>
                <td>10/18/2019</td>
              </tr>
              <tr>
                <td>41</td>
                <td>10/11/2019</td>
                <td>10/12/2019</td>
                <td>10/18/2019</td>
                <td>10/21/2019</td>
              </tr>
              <tr>
                <td>42</td>
                <td>10/12/2019</td>
                <td>10/13/2019</td>
                <td>10/19/2019</td>
                <td>10/21/2019</td>
              </tr>
              <tr>
                <td>43</td>
                <td>10/13/2019</td>
                <td>10/14/2019</td>
                <td>10/21/2019</td>
                <td>10/22/2019</td>
              </tr>
              <tr>
                <td>44</td>
                <td>10/14/2019</td>
                <td>10/15/2019</td>
                <td>10/21/2019</td>
                <td>10/22/2019</td>
              </tr>
              <tr>
                <td>45</td>
                <td>10/15/2019</td>
                <td>10/16/2019</td>
                <td>10/22/2019</td>
                <td>10/23/2019</td>
              </tr>
              <tr>
                <td>46</td>
                <td>10/16/2019</td>
                <td>10/17/2019</td>
                <td>10/23/2019</td>
                <td>10/24/2019</td>
              </tr>
              <tr>
                <td>47</td>
                <td>10/17/2019</td>
                <td>10/18/2019</td>
                <td>10/24/2019</td>
                <td>10/25/2019</td>
              </tr>
              <tr>
                <td>48</td>
                <td>10/18/2019</td>
                <td>10/19/2019</td>
                <td>10/25/2019</td>
                <td>10/28/2019</td>
              </tr>
              <tr>
                <td>49</td>
                <td>10/19/2019</td>
                <td>10/20/2019</td>
                <td>10/26/2019</td>
                <td>10/28/2019</td>
              </tr>
              <tr>
                <td>50</td>
                <td>10/20/2019</td>
                <td>10/21/2019</td>
                <td>10/28/2019</td>
                <td>10/29/2019</td>
              </tr>
              <tr>
                <td>51</td>
                <td>10/21/2019</td>
                <td>10/22/2019</td>
                <td>10/28/2019</td>
                <td>10/29/2019</td>
              </tr>
              <tr>
                <td>52</td>
                <td>10/22/2019</td>
                <td>10/23/2019</td>
                <td>10/29/2019</td>
                <td>10/30/2019</td>
              </tr>
              <tr>
                <td>53</td>
                <td>10/23/2019</td>
                <td>10/24/2019</td>
                <td>10/30/2019</td>
                <td>10/31/2019</td>
              </tr>
              <tr>
                <td>54</td>
                <td>10/24/2019</td>
                <td>10/25/2019</td>
                <td>10/31/2019</td>
                <td>11/1/2019</td>
              </tr>
              <tr>
                <td>55</td>
                <td>10/25/2019</td>
                <td>10/26/2019</td>
                <td>11/1/2019</td>
                <td>11/4/2019</td>
              </tr>
              <tr>
                <td>56</td>
                <td>10/26/2019</td>
                <td>10/27/2019</td>
                <td>11/2/2019</td>
                <td>11/4/2019</td>
              </tr>
              <tr>
                <td>57</td>
                <td>10/27/2019</td>
                <td>10/28/2019</td>
                <td>11/4/2019</td>
                <td>11/5/2019</td>
              </tr>
              <tr>
                <td>58</td>
                <td>10/28/2019</td>
                <td>10/29/2019</td>
                <td>11/4/2019</td>
                <td>11/5/2019</td>
              </tr>
              <tr>
                <td>59</td>
                <td>10/29/2019</td>
                <td>10/30/2019</td>
                <td>11/5/2019</td>
                <td>11/6/2019</td>
              </tr>
              <tr>
                <td>60</td>
                <td>10/30/2019</td>
                <td>10/31/2019</td>
                <td>11/6/2019</td>
                <td>11/7/2019</td>
              </tr>
              <tr>
                <td>61</td>
                <td>10/31/2019</td>
                <td>11/1/2019</td>
                <td>11/7/2019</td>
                <td>11/8/2019</td>
              </tr>
              <tr>
                <td>62</td>
                <td>11/1/2019</td>
                <td>11/2/2019</td>
                <td>11/8/2019</td>
                <td>11/11/2019</td>
              </tr>
              <tr>
                <td>63</td>
                <td>11/2/2019</td>
                <td>11/3/2019</td>
                <td>11/9/2019</td>
                <td>11/11/2019</td>
              </tr>
              <tr>
                <td>64</td>
                <td>11/3/2019</td>
                <td>11/4/2019</td>
                <td>11/11/2019</td>
                <td>11/12/2019</td>
              </tr>
              <tr>
                <td>65</td>
                <td>11/4/2019</td>
                <td>11/5/2019</td>
                <td>11/11/2019</td>
                <td>11/12/2019</td>
              </tr>
              <tr>
                <td>66</td>
                <td>11/5/2019</td>
                <td>11/6/2019</td>
                <td>11/12/2019</td>
                <td>11/13/2019</td>
              </tr>
              <tr>
                <td>67</td>
                <td>11/6/2019</td>
                <td>11/7/2019</td>
                <td>11/13/2019</td>
                <td>11/14/2019</td>
              </tr>
              <tr>
                <td>68</td>
                <td>11/7/2019</td>
                <td>11/8/2019</td>
                <td>11/14/2019</td>
                <td>11/15/2019</td>
              </tr>
              <tr>
                <td>69</td>
                <td>11/8/2019</td>
                <td>11/9/2019</td>
                <td>11/15/2019</td>
                <td>11/18/2019</td>
              </tr>
              <tr>
                <td>70</td>
                <td>11/9/2019</td>
                <td>11/10/2019</td>
                <td>11/16/2019</td>
                <td>11/18/2019</td>
              </tr>
              <tr>
                <td>71</td>
                <td>11/10/2019</td>
                <td>11/11/2019</td>
                <td>11/18/2019</td>
                <td>11/19/2019</td>
              </tr>
              <tr>
                <td>72</td>
                <td>11/11/2019</td>
                <td>11/12/2019</td>
                <td>11/18/2019</td>
                <td>11/19/2019</td>
              </tr>
              <tr>
                <td>73</td>
                <td>11/12/2019</td>
                <td>11/13/2019</td>
                <td>11/19/2019</td>
                <td>11/20/2019</td>
              </tr>
              <tr>
                <td>74</td>
                <td>11/13/20119</td>
                <td>11/14/2019</td>
                <td>11/20/2019</td>
                <td>11/21/2019</td>
              </tr>
              <tr>
                <td>75</td>
                <td>11/14/2019</td>
                <td>11/15/2019</td>
                <td>11/21/2019</td>
                <td>11/22/2019</td>
              </tr>
              <tr>
                <td>76</td>
                <td>11/15/2019</td>
                <td>11/16/2019</td>
                <td>11/22/2019</td>
                <td>11/25/2019</td>
              </tr>
              <tr>
                <td>77</td>
                <td>11/16/2019</td>
                <td>11/17/2019</td>
                <td>11/25/2019</td>
                <td>11/26/2019</td>
              </tr>
              <tr>
                <td>78</td>
                <td>11/17/2019</td>
                <td>11/18/2019</td>
                <td>11/25/2019</td>
                <td>11/26/2019</td>
              </tr>
              <tr>
                <td>79</td>
                <td>11/18/2019</td>
                <td>11/19/2019</td>
                <td>11/25/2019</td>
                <td>11/26/2019</td>
              </tr>
              <tr>
                <td>80</td>
                <td>11/19/2019</td>
                <td>11/20/2019</td>
                <td>11/26/2019</td>
                <td>11/27/2019</td>
              </tr>
              <tr>
                <td>81</td>
                <td>11/20/2019</td>
                <td>11/21/2019</td>
                <td>11/27/2019</td>
                <td>11/28/2019</td>
              </tr>
              <tr>
                <td>82</td>
                <td>11/21/2019</td>
                <td>11/22/2019</td>
                <td>11/28/2019</td>
                <td>11/29/2019</td>
              </tr>
              <tr>
                <td>83</td>
                <td>11/22/2019</td>
                <td>11/23/2019</td>
                <td>11/29/2019</td>
                <td>12/2/2019</td>
              </tr>
              <tr>
                <td>84</td>
                <td>11/23/2019</td>
                <td>11/24/2019</td>
                <td>12/2/2019</td>
                <td>12/3/2019</td>
              </tr>
              <tr>
                <td>85</td>
                <td>11/24/2019</td>
                <td>11/25/2019</td>
                <td>12/2/2019</td>
                <td>12/3/2019</td>
              </tr>
              <tr>
                <td>86</td>
                <td>11/25/2019</td>
                <td>11/26/2019</td>
                <td>12/2/2019</td>
                <td>12/3/2019</td>
              </tr>
              <tr>
                <td>87</td>
                <td>11/26/2019</td>
                <td>11/27/2019</td>
                <td>12/3/2019</td>
                <td>12/4/2019</td>
              </tr>
              <tr>
                <td>88</td>
                <td>11/27/2019</td>
                <td>11/28/2019</td>
                <td>12/4/2019</td>
                <td>12/5/2019</td>
              </tr>
              <tr>
                <td>89</td>
                <td>11/28/2019</td>
                <td>11/29/2019</td>
                <td>12/5/2019</td>
                <td>12/6/2019</td>
              </tr>
              <tr>
                <td>90</td>
                <td>11/29/2019</td>
                <td>11/30/2019</td>
                <td>12/6/2019</td>
                <td>12/9/2019</td>
              </tr>
            </tbody>
          </table>
        </div>
        <p>
          Puis, le ou vers le 9 décembre 2019, un (1) gagnant du grand prix sera
          sélectionné par un tirage au sort, qui sera effectué par
          l'administrateur parmi toutes les participations au grand lot
          admissibles reçues pendant la période du concours. Le gagnant
          potentiel du grand prix sera informé par courrier électronique le ou
          vers le jour du tirage au sort applicable et disposera d'un délai de
          soixante-douze (72) heures à compter de l'envoi de la notification du
          gagnant du grand prix par l'administrateur. Si le gagnant potentiel du
          grand prix ne répond pas à la notification du gagnant dans un délai de
          soixante-douze (72) heures, il perd son droit au grand prix et ce prix
          peut, à la seule discrétion du commanditaire, être attribué à un
          gagnant alternatif, si le temps le permet. Les gagnants du grand prix,
          du premier prix et du deuxième prix seront collectivement appelés
          ci-après «gagnants».
        </p>
        <p>
          Dès réception de la réponse à la notification du gagnant,
          l'administrateur peut envoyer à chaque gagnant potentiel un affidavit
          d'admissibilité et de publicité (le cas échéant) et de dégagement de
          responsabilité (si un résident des États-Unis) OU une déclaration
          d'admissibilité et de dégagement de responsabilité (si un résident
          canadien), qui doivent être remplis, signés et renvoyés à
          l’administrateur dans les sept (7) jours suivant la réception de la
          renonciation. S'il est résident du Canada, le gagnant potentiel doit
          également répondre correctement à une question d'habileté mathématique
          basée sur le temps sans aide d'aucune sorte avant et comme condition
          d'être déclaré gagnant. Si une notification de prix ou une tentative
          de notification est retournée comme non distribuable, ce prix peut
          être annulé et attribué à un autre gagnant, si le temps le permet.
          L'administrateur et le commanditaire ne sont pas responsables des
          documents perdus, en retard, endommagés, retardés ou illisibles. En
          signant la renonciation, les gagnants reconnaissent avoir respecté le
          présent règlement officiel. Les chances de gagner un prix dépendent du
          nombre de participations admissibles reçues dans chaque groupe de
          participation applicable.
        </p>
      </li>
      <li>
        <strong>CONDITIONS POUR D’ATTRIBUTION DE PRIX:</strong> Les gagnants
        doivent rencontrer toutes les conditions d’admissibilité. Dans le cas où
        un gagnant était jugé inadmissible ou incapable de recevoir son prix
        pendant les dates spécifiques, un autre gagnant pourrait être
        sélectionné des bons de participation éligibles reçues si le temps le
        permet.
      </li>
      <li>
        <p>
          <strong>PRIX ET VALEURS APPROXIMATIVES AU DÉTAIL (VAD):</strong> Un
          (1) gagnant du grand prix - Gear 5 x Camions Ford F-150 Raptor 2019 à
          thème Rockstar («Camion»). VAD: 90 000 USD. Si l'attribution du prix
          donne lieu à un événement imposable, le gagnant du grand prix recevra
          également un paiement en espèces du montant nécessaire pour payer ou
          compenser tout impôt sur le revenu applicable au prix (n'excédant pas
          25 000 USD). Si le gagnant du grand prix perd le camion, le gagnant du
          grand prix doit également renoncer à la partie du paiement en espèces.
          Le paiement en espèces sert uniquement à payer ou à compenser les
          impôts sur le revenu du gagnant du grand prix et ne sera pas payé dans
          un autre cas. Si le gagnant du grand prix accepte le camion en tant
          que grand prix, le gagnant du grand prix doit alors accepter la
          livraison du grand prix du commanditaire, ou selon les instructions du
          commanditaire. Tous les autres coûts liés à la livraison et à
          l'acceptation du prix sont à la charge du Gagnant du Grand Prix, y
          compris, sans s'y limiter, les taxes, titre, assurance, licence et
          frais d'enregistrement en excès. Le gagnant du grand prix devra
          remplir et renvoyer un formulaire W-9, un affidavit d’éligibilité et
          une décharge de responsabilité / publicité, ainsi que présenter un
          permis de conduire valide et pourrait également être tenu de présenter
          sa carte de sécurité sociale avant l’attribution du prix. Le gagnant
          du grand prix recevra un formulaire 1099 reflétant la valeur du prix
          et sera responsable du paiement de tous les impôts sur le revenu du
          grand prix. Le gagnant du grand prix accepte de se conformer à toutes
          les lois et à tous les règlements applicables concernant la propriété
          du véhicule, y compris, sans toutefois s'y limiter, ceux qui
          concernent l’immatriculation, l’octroi de licence et l’assurance d’un
          véhicule. Le véhicule décrit, affiché et / ou illustré peut ne pas
          être le camion réel attribué. Le gagnant du grand prix déclare par les
          présentes que le gagnant du grand prix (a) connaît et connaît les
          risques, les dangers, les dangers et les périls inhérents à la
          propriété et / ou à l'utilisation d'un véhicule, (b) comprend et
          reconnaît que la propriété et / ou l'utilisation d'un véhicule peuvent
          être dangereuses et peuvent exposer le propriétaire et / ou
          l'utilisateur à des blessures et à la responsabilité, et (c) assume
          pleinement toutes les responsabilités, responsabilités et risques
          découlant de la possession ou de l'utilisation du véhicule, liée à ou
          liée à la propriété et / ou de l'utilisation du véhicule depuis le
          moment où le véhicule est livré au ramasseur ou récupéré par le
          gagnant, y compris, sans limitation, blessures graves, invalidité
          temporaire ou permanente et décès, indépendamment de la causes.
          GAGNANT AU NOM DE LUI-MÊME OU DE LUI-MÊME, AINSI QUE SES CÉLÈBRES ET
          CESSIONS, BÉNÉVOLE VOLONTAIRE D'ACCEPTER TOUS LES RISQUES DÉCOULANT DE
          LA PROPRIÉTÉ ET / OU DE L'UTILISATION DU VÉHICULE dès la livraison du
          véhicule. ou ramassé par le gagnant du grand prix.
        </p>
        <p>
          Quatre-vingt-dix (90) premiers prix (un (1) gagnant par groupe
          d'entrées quotidiennes) - Une console de jeu Xbox One X Gears 5
          Edition et un jeu Gears 5 Xbox One. VAD: 500,00 USD chacun. Quatre
          cent cinquante (450) deuxièmes prix (cinq (5) gagnants par groupe de
          participation quotidien) - Un jeu Xbox Gears 5. VAD: 60,00 USD chacun.
          Toutes les garanties pour les premiers et deuxièmes prix sont soumises
          aux conditions générales du fabricant, et le gagnant accepte de s’en
          remettre uniquement à ces fabricants pour toute réclamation ou
          garantie.
        </p>
        <p>
          <strong>VAD totale pour tous les prix : 162,000.00$ CAD.</strong>
        </p>
      </li>
      <li>
        <p>
          <strong>PROTECTION DE LA VIE PRIVÉE:</strong> Les entités de promotion
          et leurs agents autorisés permettront de recueillir, utiliser et
          divulguer les renseignements personnels que vous fournissez lorsque
          vous entrez dans la promotion dans le seul but de gérer la promotion
          et le respect des prix, et en participant à cette Promotion, vous
          acceptez la collecte, l'utilisation, la divulgation et la gestion de
          vos renseignements personnels à ces fins. Tous les renseignements
          personnels partagés par les participants seront assujettis à la
          politique de confidentialité du commanditaire. En cas de divergence
          entre la politique de confidentialité du Commanditaire et ces Règles
          officielles, la politique de confidentialité devra régir. Pour obtenir
          une copie de sa politique de confidentialité en ligne complet veuillez
          visiter{' '}
          <a
            href="http://rockstarenergy.com/company/privacy"
            target="_blank"
            rel="noreferrer noopener"
          >
            Http://rockstarenergy.com/company/privacy
          </a>
          . L'information recueillie par le commanditaire dans le cadre de cette
          promotion sera maintenue sur des serveurs situés aux États-Unis.
        </p>
      </li>
      <li>
        <p>
          <strong>
            RÉSIDENTS DU QUÉBEC LITIGES ET RÉSOLUTION DE DIFFÉRENDS.
          </strong>{' '}
          Tout litige concernant la conduite ou l’organisation d’un concours de
          publicité peut être soumis à la Régie pour un jugement. Tout litige
          concernant l’octroi d’un prix peut être soumis à la régie seulement
          dans le but d’aider les partis à prendre une entente.
        </p>
      </li>
      <li>
        <p>
          <strong>Conditions de participation et libération:</strong> en
          s'inscrivant, chaque participant s'engage à : (a) respecter et d'être
          lié par ce Règlement officiel et les décisions du commanditaire, qui
          sont finales et exécutoires pour toutes les questions relatives à ce
          concours ; b) dégager les entités du concours de et contre toute
          réclamation, frais, et la responsabilité, y compris mais non limité à
          la négligence et les dommages de toute nature pour les personnes et
          les biens, y compris mais non limité à l'invasion de la vie privée
          (moins de crédit, l'intrusion, la divulgation publique de faits
          privés, fausses allégations dans l'oeil public ou autre théorie
          juridique), la diffamation, calomnie, diffamation, violation du droit
          de la publicité, de la violation de marque de commerce, droit d'auteur
          ou autres droits de propriété intellectuelle, des dommages à la
          propriété, ou la mort ou des blessures découlant de ou liée à la
          participation d'un participant, la création d'une entrée ou la
          soumission d'une entrée, la participation dans le concours, la
          présentation, l'acceptation ou l'utilisation ou l'abus de prix et/ou
          la diffusion, l'exploitation ou l'utilisation d'entrée ou de la
          soumission ; et (c) d'indemniser, défendre et dégager les entités du
          concours de et contre toute réclamation, frais, et responsabilité (y
          compris les honoraires raisonnables d'avocat) découlant de ou liée à
          la participation d'un participant dans le concours et/ou l'acceptation
          du participant, l'utilisation ou l'abus de prix ou toute partie de
          celle-ci.
        </p>
      </li>
      <li>
        <p>
          <strong>Conditions générales:</strong> Sous réserve seulement de la
          Régie des alcools, des courses et des jeux (la Régie) en ce qui
          concerne les résidents du Québec, le commanditaire se réserve le droit
          d'annuler, suspendre et/ou de modifier le concours, en tout ou en
          partie, pour quelque raison que ce soit, y compris, sans limitation,
          incendies, inondations, catastrophes naturelles ou provoquées par
          l'épidémie de santé d'autres moyens, séisme, explosion, conflit de
          travail ou de grève, d'un acte de Dieu ou d'ennemi public, ou de
          l'équipement, d'émeute ou de troubles civils, de menace terroriste ou
          d'une activité, la guerre (déclarée ou non) ou tout État fédéral ou
          local, l'ordre, la loi ou la réglementation, crise de la santé
          publique, l'ordre d'un tribunal ou d'une juridiction ou si toute
          fraude, pannes techniques ou tout autre facteur hors du contrôle
          raisonnable du commanditaire porte atteinte à l'intégrité ou le bon
          fonctionnement du concours, tel que déterminé par le Commanditaire à
          sa seule discrétion. Si le concours est terminé avant la date de fin,
          le commanditaire (si possible) sélectionnera le gagnant par tirage au
          sort parmi les entrées éligibles, non suspectes reçues à la date de
          l'événement donnant lieu à la résiliation. L'inclusion dans ce tirage
          au sort est chaque participant a la seule et unique recours dans de
          telles circonstances. Le commanditaire se réserve le droit, à sa seule
          discrétion, de disqualifier toute personne qu'il suspecte d'intervenir
          avec le processus de participation ou le fonctionnement du concours ou
          d'avoir agi en violation du présent règlement officiel ou de toute
          autre promotion ou d'une manière antisportive ou perturbatrice. Toute
          tentative par quiconque de nuire délibérément au fonctionnement
          légitime du concours peut constituer une violation du droit pénal et
          civil, et, advenant une telle tentative, le commanditaire se réserve
          le droit de réclamer des dommages-intérêts contre une telle personne
          dans toute la mesure permise par la loi. Seul le type et la quantité
          de prix décrits dans le présent règlement officiel seront attribués.
          L'invalidité ou l'inapplicabilité de toute disposition du présent
          règlement n'affecte pas la validité ou le caractère exécutoire de
          toute autre disposition. Dans le cas où une disposition est jugée
          invalide ou inexécutoire ou illégale, ce règlement est sinon demeurent
          en vigueur et doivent être interprétées conformément à leurs modalités
          comme si la disposition invalide ou illégale n'étaient pas contenues
          dans ce document. Si le promoteur omet de faire respecter l'un des
          termes de ces Règles officielles, cela ne constitue pas une
          renonciation de cette disposition.
        </p>
      </li>
      <li>
        <p>
          <strong>Limitations de responsabilité:</strong> Les entités du
          concours comme définie ci-dessus ne sont pas responsables pour : (1)
          toute information inexacte ou incorrecte, qu'ils soient causés par les
          participants, des erreurs d'impression ou par l'équipement ou les
          programmes associés ou utilisés dans le concours ; (2) Les
          défaillances techniques d'aucune sorte, y compris, mais non limité à,
          perturbations, interruptions ou déconnexions dans les lignes de
          téléphone ou de matériel ou logiciel réseau ; (3) d'intervention
          humaine non autorisée dans n'importe quelle partie du processus de
          candidature ou le concours ; (4) une erreur humaine ou technique
          pouvant se produire à l'administration du concours ou le traitement
          des entrées ; ou (5) toute blessure ou dommage à des personnes ou des
          biens qui peuvent être causés, directement ou indirectement, en tout
          ou en partie, de la participation du participant au concours ou la
          réception ou l'usage ou l'abus de tout prix. Si pour une raison
          quelconque, on confirme avoir supprimé une inscription par erreur,
          perdue, ou autrement détruite ou endommagée, dont le seul recours est
          une autre entrée dans le concours, à condition que s'il n'est pas
          possible d'attribuer une autre entrée en raison de la suppression du
          concours, en tout ou en partie, pour quelque raison que ce soit, le
          Commanditaire peut, à sa discrétion, choisir de tenir à en juger parmi
          toutes les participations admissibles reçues jusqu'à la date de
          l'abandon pour l'un ou tous les prix offerts aux présentes.
          (Participant ou parent/tuteur légal si le participant est une personne
          mineure dans son état de résidence) d'autres accepte et reconnaît que
          le commanditaire se réserve le droit de confisquer ou d'attribuer des
          soldes non réclamés ou des restes de prix à sa seule discrétion.
        </p>
      </li>
      <li>
        <p>
          <strong>Disputes et loi applicable:</strong> le participant convient
          que : (i) tous litiges, réclamations et causes d'action découlant de
          ou lié à ce concours, autres que ceux concernant l'administration du
          concours ou de la détermination du gagnant ou pour tout litige
          découlant de la perte ou de dommages provenant de la participation à
          un prix, doit être résolu individuellement, sans recours à aucune
          forme de recours collectif ; (ii) tout litige découlant de ces Règles
          officielles (à l'exception de tout litige découlant de la perte ou
          dommage causé par l'utilisation de prix) doit être soumise à l'
          arbitrage exécutoire, final effectué dans le Nevada, en vertu du
          règlement d'arbitrage et les procédures de l'arbitrage et de médiation
          judiciaire avant qu'un seul, arbiter neutre, qui est un ancien de
          l'état du Nevada ou à la retraite ou juge de la cour fédérale ayant
          une expérience en matière de divertissement qui doit suivre la loi du
          Nevada et les règles fédérales de la preuve et n'ont pas le pouvoir
          d'accorder des dommages-intérêts punitifs. Chaque partie peut
          appliquer une décision d'arbitrage finale devant tout tribunal
          compétent au Nevada, y compris l'attribution des coûts, frais et
          dépenses encourus dans l'application de la sentence. Malgré ce qui
          précède, les entités du concours ont le droit de demander une
          injonction (sauf indication contraire empêché par toute autre
          disposition de ces Règles officielles) dans l'état et les tribunaux
          fédéraux du Nevada. Tout litige ou toute partie de celle-ci, ou toute
          revendication d'un type précis de mesures (non autrement interdite par
          une autre disposition de ce Règlement officiel), qui peuvent ne pas
          être soumis à l'arbitrage en vertu de l'état applicable ou la loi
          fédérale peut être entendu seulement dans un tribunal compétent dans
          le Nevada ; (iii) toute réclamation, des jugements et des prix doit
          être limitée à de reels frais engagés, y compris les coûts associés à
          l'entrée sur ce concours, mais en aucun cas les frais d'avocat ; et
          (iv) en aucun cas, le participant autorisé à obtenir des prix pour le
          participant, et renonce par droit de réclamation punitifs, accessoires
          et consécutifs et tous autres dommages, autres que pour les réels- de
          leur poche, et tout et tout L'homme d'avoir multiplié les
          dommages-intérêts ou autre. Certaines JURIDICTIONS N'AUTORISENT PAS
          LES LIMITATIONS OU L'EXCLUSION DE RESPONSABILITÉ POUR DOMMAGES
          ACCESSOIRES OU INDIRECTS, LES RÈGLES CI-DESSUS PEUVENT NE PAS
          S'APPLIQUER À VOUS. Tous les problèmes et questions concernant
          l'interprétation, la validité, l'interprétation et l'applicabilité du
          règlement officiel, ou les droits et obligations du participant et du
          promoteur en relation avec le concours, sont régies et interprétées
          conformément aux lois de l'État du Nevada, sans donner effet à tout
          choix d'une loi ou des règles de conflit de lois (qu'il s'agisse de
          l'État du Nevada, ou toute autre juridiction), ce qui entraînerait
          l'application des lois de toute juridiction autre que l'État du
          Nevada.
        </p>
      </li>
      <li>
        <p>
          <strong>Gagnant du concours:</strong> Pour les noms des gagnants
          (disponible après le 9 décembre 2019), veuillez envoyer une enveloppe
          timbrée, imprimée à la main avec votre adresse avant le 9 décembre
          2019 à: Liste des gagnants – Concours Rockstar Gears of War 5,
          Brandmovers, Inc., 590 Means Street, Suite 250, Atlanta, GA 30318.
        </p>
      </li>
      <li>
        <p>
          <strong>Règlements officiels:</strong> Pour obtenir une copie papier
          de ces règlements officiels, veuillez envoyer une enveloppe timbrée,
          imprimée à la main avec votre adresse avant le 6 décembre 2019 à:
          Règlements officiels – Rockstar Gears 5 Sweepstakes, Brandmovers,
          Inc., 590 Means Street, Suite 250, Atlanta, GA 30318. Vous pouvez
          obtenir une copie de ces règlements officiels en les imprimant du site
          web.
        </p>
      </li>
      <li>
        <p>
          <strong>Commanditaire:</strong> Rockstar, Inc., 101 Convention Center
          Dr., Suite 777, Las Vegas, NV 89109.
          <br />
          <strong>Administrateur:</strong> Brandmovers, Inc., 590 Means Street,
          Suite 250, Atlanta, GA 30318.
        </p>
      </li>
    </ol>

    <p>Microsoft n’est pas un commanditaire de ce concours.</p>
  </div>
);

export default RulesCa;
