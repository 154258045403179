import React from 'react';
import PropTypes from 'prop-types';
import noScroll from 'no-scroll';
import ModalStyled from './Modal.style';

// const Modal = ({ children, ...rest }) => {
class Modal extends React.Component {
  componentDidMount() {
    if (typeof window !== 'undefined') {
      noScroll.on();
    }
  }

  componentWillUnmount() {
    if (typeof window !== 'undefined') {
      noScroll.off();
    }
  }

  render() {
    const { children, ...rest } = this.props;
    return (
      <ModalStyled role="dialog" tabIndex="-1" {...rest}>
        <div className="Modal__dialog">
          <div className="Modal__content">{children}</div>
        </div>
      </ModalStyled>
    );
  }
}

Modal.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Modal;
