import React from 'react';

const RulesWalmart = () => (
  <div>
    <p>
      Purchase any Rockstar product and receive special code on receipt. First
      transaction receive unique code printed on receipt to unlock exclusive
      Walmart in-game content pack. For each additional transaction receive a
      unique code to unlock an in-game item. Redeem codes at{' '}
      <a
        href="https://gears.rockstarenergy.com"
        target="_blank"
        rel="noreferrer noopener"
      >
        gears.rockstarenergy.com
      </a>{' '}
      All Xbox Game Pass and Xbox Live Gold rewards are up to 14 days access or
      Gears 5 game specific in-game items starting September 6th. Promotion open
      to 50 U.S. States, age 18+. Offer period begins 9/1/19 at 12:00:00 PM ET;
      ends 11/30/19 at 11:59:59 AM ET. ARV: $4.00. Sponsor: Rockstar, Inc.
      Microsoft, Gears of War & Xbox One are trademarks of Microsoft Group of
      companies. Microsoft & Ford are not sponsors of this promotion. Xbox Game
      Pass & Xbox Live Gold tokens are for 7-days of membership. After Game Pass
      trial ends, in-game items and boosts require Gears 5 game or Xbox Game
      Pass membership (sold separately). ESRB RATING: Mature.
    </p>
  </div>
);

export default RulesWalmart;
