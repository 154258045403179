import React, { Component } from 'react';
import PropTypes from 'prop-types';

import LayoutStyled from './Layout.style';

class Layout extends Component {
  componentDidMount() {
    if (typeof window !== 'undefined') {
      // eslint-disable-next-line global-require
      require('smooth-scroll')('a[href*="#"]', {
        offset: 64,
        topOnEmptyHash: true,
      });
    }
  }

  render() {
    const { children } = this.props;

    return <LayoutStyled>{children}</LayoutStyled>;
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
