import styled from 'styled-components';
import * as defaultTheme from '../variables';

const HeadingStyled = styled.div`
  display: inline-flex;
  flex-direction: column;
  margin: 0;
  padding-left: ${p => p.theme.space}px;
  color: ${p => (p.white ? p.theme.textColorWhite : p.theme.textColorBlack)};
  font-family: 'AzbukaPro';
  font-size: 31px;
  font-weight: 900;
  line-height: 28px;
  text-transform: uppercase;
  border-left: 10px solid
    ${p => (p.white ? p.theme.bgColorWhite : p.theme.bgColorBlack)};

  @media (${p => p.theme.lgMin}) {
    font-size: 48px;
    line-height: 48px;
  }
`;

HeadingStyled.defaultProps = {
  theme: defaultTheme,
};

export default HeadingStyled;
