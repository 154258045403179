import React from 'react';
import cx from 'classnames';
import { FormattedMessage, defineMessages } from 'react-intl';
import { injectIntl, intlShape } from 'gatsby-plugin-intl';

import SocialStyled from './Social.style';
import iconGears from '../../images/icon-gears.svg';
import iconRS from '../../images/icon-rockstar.svg';

import config from './config';

const messages = defineMessages({
  title: {
    id: 'social.title',
    defaultMessage: 'NEVER FIGHT ALONE',
  },
  infoTitle1: {
    id: 'social.infoTitle1',
    defaultMessage: 'GET UPDATES FROM',
  },
  infoTitle2: {
    id: 'social.infoTitle2',
    defaultMessage: 'STAY CONNECTED WITH',
  },
  gears: {
    id: 'social.gears',
    defaultMessage: 'GEARS OF WAR',
  },
  rs: {
    id: 'social.rs',
    defaultMessage: 'ROCKSTAR ENERGY DRINK',
  },
});

const Social = ({ intl }) => {
  const localeConfig = config[intl.locale] || config[Object.keys(config)[0]];

  const linksGears = localeConfig.socialLinksGears.map(link => (
    <a
      className={cx('Social__link', link.className)}
      href={link.url}
      rel="noopener noreferrer"
      target="_blank"
      key={link.className}
    >
      <img src={link.src} alt={link.alt} />
    </a>
  ));

  const linksRs = localeConfig.socialLinksRs.map(link => (
    <a
      className={cx('Social__link', link.className)}
      href={link.url}
      rel="noopener noreferrer"
      target="_blank"
      key={link.className}
    >
      <img src={link.src} alt={link.alt} />
    </a>
  ));

  return (
    <SocialStyled>
      <div className="Social__title">
        <FormattedMessage {...messages.title} />
      </div>
      <div className="Social__inner">
        <div className="Social__block">
          <div className="Social__info">
            <img className="Social__infoImg" src={iconGears} alt="" />
            <div className="Social__infoTitle">
              <FormattedMessage {...messages.infoTitle1} />
              <div className="Social__infoTitle --gears">
                <FormattedMessage {...messages.gears} />
              </div>
            </div>
          </div>
          <div className="Social__links">{linksGears}</div>
        </div>

        <div className="Social__block">
          <div className="Social__info">
            <img className="Social__infoImg" src={iconRS} alt="" />
            <div className="Social__infoTitle">
              <FormattedMessage {...messages.infoTitle2} />
              <div className="Social__infoTitle --rs">
                <FormattedMessage {...messages.rs} />
              </div>
            </div>
          </div>
          <div className="Social__links">{linksRs}</div>
        </div>
      </div>
    </SocialStyled>
  );
};

Social.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(Social);
