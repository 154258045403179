import React, { Component } from 'react';
import PropTypes from 'prop-types';
import onClickOutside from 'react-onclickoutside';

import DropdownControllerStyled from './DropdownController.style';

class DropdownController extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };
  }

  dropdownToggle = () => {
    const { isOpen } = this.state;
    this.setState({ isOpen: !isOpen });
  };

  handleClickOutside = () => {
    this.setState({ isOpen: false });
  };

  render() {
    const { children } = this.props;
    const { isOpen } = this.state;

    return (
      <DropdownControllerStyled>
        {children(isOpen, this.dropdownToggle)}
      </DropdownControllerStyled>
    );
  }
}

DropdownController.propTypes = {
  children: PropTypes.func.isRequired,
};

export default onClickOutside(DropdownController);
