import styled from 'styled-components';

const ModalClose = styled.button`
  position: absolute;
  top: 20px;
  width: 28px;
  height: 28px;
  margin: 0;
  padding: 0;
  background-color: transparent;
  background-image: url('data:image/svg+xml;utf8,<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path fill="none" stroke="%23fff" stroke-linecap="square" stroke-miterlimit="50" stroke-width="2" d="M1.53 1.53l20.94 20.94M1.53 1.53l20.94 20.94m0-20.94L1.53 22.47"/></svg>');
  background-repeat: no-repeat;
  background-size: 24px;
  background-position: center;
  border: 0 none;
  cursor: pointer;

  @media (max-width: 767px) {
    left: 20px;
  }

  @media (min-width: 768px) {
    right: 20px;
  }
`;

export default ModalClose;
