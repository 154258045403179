import React from 'react';
import PropTypes from 'prop-types';

import DropdownStyled from './Dropdown.style';

const Dropdown = ({ children }) => <DropdownStyled>{children}</DropdownStyled>;

Dropdown.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Dropdown;
