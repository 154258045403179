import styled from 'styled-components';
import * as defaultTheme from '../variables';

const ModalSupportStyled = styled.div`
  .ModalSupport__blocks {
    @media (min-width: 1440px) {
      display: flex;
    }
  }

  .ModalSupport__block {
    @media (${p => p.theme.lgMin}) {
      width: 50%;
    }

    &:nth-child(1) {
      @media (${p => p.theme.mdMax}) {
        margin-bottom: ${p => p.theme.space * 3}px;
        padding-bottom: ${p => p.theme.space * 3}px;
      }

      @media (${p => p.theme.lgMin}) {
        padding-right: ${p => p.theme.space * 3}px;
      }
    }

    &:nth-child(2) {
      @media (${p => p.theme.lgMin}) {
        padding-left: ${p => p.theme.space * 3}px;
      }
    }
  }

  .ModalSupport__logo {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    margin-bottom: ${p => p.theme.space * 4}px;

    @media (${p => p.theme.smMin}) {
      height: 80px;
      margin-bottom: ${p => p.theme.space * 3}px;
    }
  }

  .ModalSupport__logoImg {
    max-width: 190px;

    @media (${p => p.theme.smMin}) {
      max-width: 300px;
    }
  }

  .ModalSupport__title {
    margin-bottom: ${p => p.theme.space * 2}px;
    font-family: 'AzbukaPro';
    font-size: 20px;
    font-weight: 900;
    text-transform: uppercase;
    border-bottom: 1px solid #fff;

    @media (${p => p.theme.smMin}) {
      font-size: 24px;
    }
  }

  .ModalSupport__desc {
    margin-bottom: ${p => p.theme.space * 3}px;
    padding: 0;
    /* list-style: none; */
    margin-left: 15px;
  }

  .ModalSupport__descItem {
    @media (${p => p.theme.smMin}) {
      line-height: 2;
    }
  }

  .ModalSupport__descLink {
    color: ${p => p.theme.textColorWhite};
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  .ModalSupport__link {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
    margin: 0;
    padding: 0 64px;
    background-image: none;
    font-weight: 700;
    vertical-align: middle;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 2px;
    white-space: nowrap;
    border: 1px solid transparent;
    cursor: pointer;

    &.--rs {
      color: ${p => p.theme.textColorBlack};
      background-color: ${p => p.theme.yellow};
    }

    &.--xbox {
      color: ${p => p.theme.textColorWhite};
      background-color: ${p => p.theme.darkGreen};
    }
  }
`;

ModalSupportStyled.defaultProps = {
  theme: defaultTheme,
};

export default ModalSupportStyled;
