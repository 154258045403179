import styled from 'styled-components';

const LayoutStyled = styled.div`
  width: 100%;
  height: 100%;
  min-width: 375px;
  color: #000;
  overflow: hidden;
`;

export default LayoutStyled;
