import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { FormattedMessage, defineMessages } from 'react-intl';
import { injectIntl, intlShape } from 'gatsby-plugin-intl';

import ModalRulesStyled from './ModalRules.style';

import Heading from '../Heading/Heading';
import Button from '../Button/Button';
import Modal from '../Modal/Modal';
import ModalClose from '../ModalClose/ModalClose';

import RulesUs from './RulesUs';
import RulesCa from './RulesCa';
import RulesGb from './RulesGb';
import RulesDe from './RulesDe';
import RulesMX from './RulesMX';
import RulesWalmart from './RulesWalmart';
import RulesCircle from './RulesCircle';
import RulesSpeedway from './RulesSpeedway';

const messages = defineMessages({
  title: {
    id: 'modalRules.title',
    defaultMessage: 'Official rules',
  },
  quickLinks: {
    id: 'modalRules.quickLinks',
    defaultMessage: 'Quick Links',
  },
  linkGeneral: {
    id: 'modalRules.linkGeneral',
    defaultMessage: 'General',
  },
  linkFrench: {
    id: 'modalRules.linkFrench',
    defaultMessage: 'French',
  },
  linkWalmart: {
    id: 'modalRules.linkWalmart',
    defaultMessage: 'Walmart',
  },
  linkCircle: {
    id: 'modalRules.linkCircle',
    defaultMessage: 'Circle K',
  },
  linkSpeedway: {
    id: 'modalRules.linkSpeedway',
    defaultMessage: 'Speedway',
  },
  back: {
    id: 'modalRules.back',
    defaultMessage: 'BACK',
  },
});

class ModalRules extends Component {
  constructor(props) {
    super(props);

    this.state = { activeRule: null, isActive: null };
  }

  componentDidMount() {
    this.onSwitchRule('intl');
  }

  onSwitchRule = rule => {
    const activeRule = this.getActiveRule(rule);

    this.setState({ activeRule, isActive: rule });
  };

  getActiveRule = rule => {
    const intlRule = this.getLocalizedSweepsRule();

    return {
      intl: intlRule,
      french: <RulesCa />,
      walmart: <RulesWalmart />,
      circle: <RulesCircle />,
      speedway: <RulesSpeedway />,
    }[rule];
  };

  getLocalizedSweepsRule = () => {
    const { intl } = this.props;
    const { locale } = intl;

    return (
      {
        'en-US': <RulesUs />,
        'en-CA': <RulesUs />,
        'en-GB': <RulesGb />,
        'de-DE': <RulesDe />,
        'es-MX': <RulesMX />,
      }[locale] || <RulesUs />
    );
  };

  render() {
    const { onClose, intl } = this.props;
    const { activeRule, isActive } = this.state;

    return (
      <Modal>
        <ModalClose onClick={onClose} />
        <ModalRulesStyled>
          <Heading white>
            <FormattedMessage {...messages.title} />
          </Heading>
          <div className="ModalRules__desc">
            {intl.locale === 'en-US' && (
              <Fragment>
                <FormattedMessage {...messages.quickLinks} />
                <br />
                <div className="ModalRules__links">
                  <Button
                    className={cx('ModalRules__link', {
                      active: isActive === 'intl',
                    })}
                    size="sm"
                    variant="secondary"
                    onClick={() => this.onSwitchRule('intl')}
                  >
                    <FormattedMessage {...messages.linkGeneral} />
                  </Button>
                  <Button
                    className={cx('ModalRules__link', {
                      active: isActive === 'walmart',
                    })}
                    size="sm"
                    variant="secondary"
                    onClick={() => this.onSwitchRule('walmart')}
                  >
                    <FormattedMessage {...messages.linkWalmart} />
                  </Button>
                  <Button
                    className={cx('ModalRules__link', {
                      active: isActive === 'circle',
                    })}
                    size="sm"
                    variant="secondary"
                    onClick={() => this.onSwitchRule('circle')}
                  >
                    <FormattedMessage {...messages.linkCircle} />
                  </Button>
                  <Button
                    className={cx('ModalRules__link', {
                      active: isActive === 'speedway',
                    })}
                    size="sm"
                    variant="secondary"
                    onClick={() => this.onSwitchRule('speedway')}
                  >
                    <FormattedMessage {...messages.linkSpeedway} />
                  </Button>
                </div>
                <hr />
              </Fragment>
            )}

            {intl.locale === 'en-CA' && (
              <Fragment>
                <FormattedMessage {...messages.quickLinks} />
                <br />
                <div className="ModalRules__links">
                  <Button
                    className={cx('ModalRules__link', {
                      active: isActive === 'intl',
                    })}
                    size="sm"
                    variant="secondary"
                    onClick={() => this.onSwitchRule('intl')}
                  >
                    <FormattedMessage {...messages.linkGeneral} />
                  </Button>
                  <Button
                    className={cx('ModalRules__link', {
                      active: isActive === 'french',
                    })}
                    size="sm"
                    variant="secondary"
                    onClick={() => this.onSwitchRule('french')}
                  >
                    <FormattedMessage {...messages.linkFrench} />
                  </Button>
                </div>
                <hr />
              </Fragment>
            )}

            {activeRule}
          </div>
          <Button
            className="ModalRules__back"
            variant="secondary"
            onClick={onClose}
          >
            <FormattedMessage {...messages.back} />
          </Button>
        </ModalRulesStyled>
      </Modal>
    );
  }
}

ModalRules.propTypes = {
  onClose: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(ModalRules);
