import React from 'react';

const RulesSpeedway = () => (
  <div>
    <p>
      Purchase any Rockstar product, scan your Speedway rewards card at checkout
      or on mobile phone, and receive special code on receipt. Each code unlocks
      standard in-game gear pack. Redeem codes at{' '}
      <a
        href="https://gears.rockstarenergy.com"
        target="_blank"
        rel="noreferrer noopener"
      >
        gears.rockstarenergy.com
      </a>
      . All Xbox Game Pass and Xbox Live Gold rewards are up to 14 days access
      or Gears 5 game specific in-game items starting September 6th. Promotion
      open to 50 U.S. &amp; DC, age 18+. Offer period begins 10/1/19 at 12:00:00
      PM ET; ends 10/31/19 at 11:59:59 AM ET. Sponsor: Rockstar, Inc. Microsoft,
      Gears of War &amp; Xbox One are trademarks of Microsoft Group of
      companies. Microsoft &amp; Ford are not sponsors of this promotion. Xbox
      Game Pass &amp; Xbox Live Gold tokens are for 7-days of membership. After
      Game Pass trial ends, in-game items and boosts require Gears 5 game or
      Xbox Game Pass membership (sold separately). ESRB RATING: Mature.
    </p>
  </div>
);

export default RulesSpeedway;
