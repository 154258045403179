import styled from 'styled-components';
import * as defaultTheme from '../variables';

const FooterStyled = styled.footer`
  padding: ${p => p.theme.space * 5}px 0;
  color: ${p => p.theme.textColorWhite};
  background-color: ${p => p.theme.bgColorBlack};

  @media (${p => p.theme.lgMin}) {
    padding: ${p => p.theme.space * 6}px 0;
  }

  .Footer__inner {
    max-width: ${p => p.theme.maxWidth}px;
    margin: 0 auto;
    padding: 0 ${p => p.theme.space * 3}px;

    @media (${p => p.theme.smMin}) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .Footer__links {
    display: flex;
    align-items: center;
    margin-bottom: ${p => p.theme.space * 4}px;
  }

  .Footer__link {
    font-size: 13px;
    text-transform: uppercase;
  }

  .Footer__linkDivider {
    display: block;
    width: 1px;
    height: 19px;
    margin: 0 12px;
    background-color: ${p => p.theme.bgColorWhite};
  }

  .Footer__rules {
    max-width: 960px;
    margin-bottom: ${p => p.theme.space * 4}px;
    line-height: 16px;

    @media (${p => p.theme.smMin}) {
      margin-bottom: ${p => p.theme.space * 7}px;
      text-align: center;
    }

    p {
      margin: ${p => p.theme.space / 2}px 0;
    }
  }

  .Footer__logos {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 720px;

    @media (${p => p.theme.smMin}) {
      justify-content: space-between;
    }
  }

  .Footer__logoLink {
    margin-right: ${p => p.theme.space * 2}px;
    margin-bottom: ${p => p.theme.space * 5}px;
    transition: opacity ${p => p.theme.transitionFast};

    @media (${p => p.theme.smMin}) {
      margin: 0;
    }

    &:hover {
      opacity: 0.7;
    }

    img {
      max-width: 125px;
      max-height: 95px;
    }
  }

  .Footer__powered {
    width: 100%;
    max-width: 1118px;
    margin: 0 auto;
    margin-top: ${p => p.theme.space}px;
    padding-top: ${p => p.theme.space * 5}px;
    text-align: center;
    border-top: 1px solid #4a4a4a;

    @media (${p => p.theme.smMin}) {
      margin-top: ${p => p.theme.space * 7}px;
    }
  }

  .Footer__poweredLink {
    font-size: 12px;

    span {
      margin-right: 10px;
    }
  }
`;

FooterStyled.defaultProps = {
  theme: defaultTheme,
};

export default FooterStyled;
