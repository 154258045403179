import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import MaskedTextInput from 'react-text-mask';
import * as defaultTheme from '../../variables';

const MaskedInputText = styled(({ error, ...rest }) => (
  <MaskedTextInput {...rest} />
))`
  display: block;
  width: 100%;
  height: 35px;
  padding: 0 12px 1px;
  color: ${p => (p.error ? 'red' : 'inherit')};
  background-color: white;
  font-size: inherit;
  border: none;

  &:focus {
    outline: 0;
  }

  &:disabled {
    background-color: #eee;
    opacity: 1;
    cursor: default;
  }

  &::placeholder {
    opacity: 0.5;
  }
`;

MaskedInputText.displayName = 'MaskedInputText';

MaskedInputText.type = {
  text: 'text',
  email: 'email',
  password: 'password',
  number: 'number',
};

MaskedInputText.propTypes = {
  type: PropTypes.oneOf(Object.values(MaskedInputText.type)),
  error: PropTypes.bool,
  keepCharPositions: PropTypes.bool,
  guide: PropTypes.bool,
  mask: PropTypes.array, // eslint-disable-line react/forbid-prop-types
};

MaskedInputText.defaultProps = {
  theme: defaultTheme,
  type: MaskedInputText.type.text,
  error: false,
  keepCharPositions: false,
  guide: false,
  mask: null,
};

export default MaskedInputText;
