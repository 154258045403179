import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, defineMessages } from 'react-intl';
import { injectIntl, intlShape } from 'gatsby-plugin-intl';

import ModalRulesStyled from '../ModalRules/ModalRules.style';

import Heading from '../Heading/Heading';
import Button from '../Button/Button';
import Modal from '../Modal/Modal';
import ModalClose from '../ModalClose/ModalClose';

import PrivacyUs from './PrivacyUs';
import PrivacyGb from './PrivacyGb';
import PrivacyDe from './PrivacyDe';
import PrivacyMx from './PrivacyMx';

const messages = defineMessages({
  title: {
    id: 'modalPrivacy.title',
    defaultMessage: 'Website Privacy Policy',
  },
  back: {
    id: 'modalPrivacy.back',
    defaultMessage: 'BACK',
  },
});

class ModalPrivacy extends Component {
  getIntlRule = () => {
    const { intl } = this.props;
    const { locale } = intl;

    return (
      {
        'en-US': <PrivacyUs />,
        'en-CA': <PrivacyUs />,
        'en-GB': <PrivacyGb />,
        'de-DE': <PrivacyDe />,
        'es-MX': <PrivacyMx />,
      }[locale] || <PrivacyUs />
    );
  };

  render() {
    const { onClose } = this.props;

    return (
      <Modal>
        <ModalClose onClick={onClose} />
        <ModalRulesStyled>
          <Heading white>
            <FormattedMessage {...messages.title} />
          </Heading>
          <div className="ModalRules__desc">{this.getIntlRule()}</div>
          <Button
            className="ModalRules__back"
            variant="secondary"
            onClick={onClose}
          >
            <FormattedMessage {...messages.back} />
          </Button>
        </ModalRulesStyled>
      </Modal>
    );
  }
}

ModalPrivacy.propTypes = {
  onClose: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(ModalPrivacy);
