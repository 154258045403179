import React from 'react';

const RulesCircle = () => (
  <div>
    <p>
      Purchase any Gears Edition 16oz Rockstar can and receive (1) unique code
      via SMS text message issued through digital display screens at checkout
      per transaction. First code unlocks exclusive in-game gear pack + bonus
      unlock for standard gear pack. Each additional code unlocks bonus standard
      gear pack. Redeem codes at{' '}
      <a
        href="https://rockstarenergy.com/ck"
        target="_blank"
        rel="noreferrer noopener"
      >
        rockstarenergy.com/ck
      </a>
      . All Xbox Game Pass and Xbox Live Gold rewards are up to 14 days access
      or Gears 5 game specific in-game items starting September 6th. Promotional
      giveaway open to residents of 50 U.S. States, age 18+. Promotion period
      begins 9/23/19 at 12:00:00 PM ET and ends 11/30/19 at 11:59:59 AM ET. ARV:
      $4.00. Sponsor: Rockstar, Inc. Microsoft & Ford are not sponsors of this
      promotion. Microsoft, Gears of War & Xbox One are trademarks of Microsoft
      Group of companies. Xbox Game Pass & Xbox Live Gold tokens are for 7 days
      of membership. After Game Pass trial ends, in-game items and boosts
      require Gears 5 game or Xbox Game Pass membership (sold separately). ESRB
      RATING: Mature.
    </p>
  </div>
);

export default RulesCircle;
