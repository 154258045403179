import styled from 'styled-components';
import * as defaultTheme from '../variables';

import CollectorCansBg from '../../images/collectorcans-bg.jpg';

const CollectorCansStyled = styled.section`
  padding: ${p => p.theme.space * 6}px 0;
  color: ${p => p.theme.black};
  background-image: url(${CollectorCansBg});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  .CollectorCans__inner {
    max-width: ${p => p.theme.maxWidth}px;
    margin: 0 auto;

    @media (${p => p.theme.smMin}) {
      padding: 0 ${p => p.theme.space * 2}px;
    }
  }

  .CollectorCans__header {
    margin-bottom: ${p => p.theme.space * 3}px;

    @media (${p => p.theme.smMin}) {
      display: flex;
      align-items: center;
      margin-bottom: ${p => p.theme.space * 6}px;
    }

    @media (${p => p.theme.lgMin}) {
      align-items: flex-end;
      margin-bottom: ${p => p.theme.space * 7}px;
    }
  }

  .CollectorCans__heading {
    flex-shrink: 0;
  }

  .CollectorCans__subtitle {
    padding: 20px;
    line-height: 14px;

    @media (${p => p.theme.smMin}) {
      max-width: 430px;
      margin-left: ${p => p.theme.space * 7}px;
      padding: 0;
      line-height: 24px;
    }
  }

  .CollectorCans__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  .CollectorCans__listItem {
    position: relative;

    @media (${p => p.theme.xsMax}) {
      width: 100%;
      max-width: 360px;

      &:first-child {
        margin-bottom: 40px;
      }
    }

    @media (${p => p.theme.smMin}) {
      width: 380px;
    }

    @media (${p => p.theme.lgMin}) {
      width: 580px;
    }
  }

  .CollectorCans__sliderOver {
    position: absolute;
    top: 0;
    right: 10px;
    bottom: 0;
    font-family: 'AzbukaPro';
    text-align: center;

    @media (${p => p.theme.smMin}) {
      top: 5px;
      right: 20px;
    }

    @media (${p => p.theme.lgMin}) {
      right: 70px;
    }
  }

  .CollectorCans__sliderArt {
    max-width: 135px;
    margin-bottom: 10px;

    @media (${p => p.theme.smMin}) {
      max-width: 145px;
    }

    @media (${p => p.theme.lgMin}) {
      max-width: 200px;
    }
  }

  .CollectorCans__sliderTitle {
    font-size: 18px;
    font-weight: 900;
    line-height: 20px;
  }

  .CollectorCans__sliderDesc {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;

    @media (${p => p.theme.xsMax}) {
      display: none;
    }
  }
`;

CollectorCansStyled.defaultProps = {
  theme: defaultTheme,
};

export default CollectorCansStyled;
