import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, defineMessages } from 'react-intl';
import { injectIntl, intlShape } from 'gatsby-plugin-intl';

import ModalRulesStyled from '../ModalRules/ModalRules.style';

import Heading from '../Heading/Heading';
import Button from '../Button/Button';
import Modal from '../Modal/Modal';
import ModalClose from '../ModalClose/ModalClose';

import TermsUs from './TermsUs';
import TermsGb from './TermsGb';
import TermsDe from './TermsDe';
import TermsMx from './TermsMx';

const messages = defineMessages({
  title: {
    id: 'modalTerms.title',
    defaultMessage: 'REWARD TERMS',
    description: 'Title',
  },
  back: {
    id: 'modalTerms.back',
    defaultMessage: 'BACK',
    description: 'Back Button',
  },
});

class ModalTerms extends Component {
  getIntlTerms = () => {
    const { intl } = this.props;
    const { locale } = intl;

    return (
      {
        'en-US': <TermsUs />,
        'en-CA': <TermsUs />,
        'en-GB': <TermsGb />,
        'de-DE': <TermsDe />,
        'es-MX': <TermsMx />,
      }[locale] || <TermsUs />
    );
  };

  render() {
    const { onClose } = this.props;

    return (
      <Modal>
        <ModalClose onClick={onClose} />
        <ModalRulesStyled>
          <Heading white>
            <FormattedMessage {...messages.title} />
          </Heading>
          <div className="ModalRules__desc">{this.getIntlTerms()}</div>
          <Button
            className="ModalRules__back"
            variant="secondary"
            onClick={onClose}
          >
            <FormattedMessage {...messages.back} />
          </Button>
        </ModalRulesStyled>
      </Modal>
    );
  }
}

ModalTerms.propTypes = {
  onClose: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(ModalTerms);
