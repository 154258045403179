import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { injectIntl, intlShape, Link } from 'gatsby-plugin-intl';

import { FormattedMessage, defineMessages } from 'react-intl';

import HeaderStyled from './Header.style';
import logo from '../../images/header-logo.png';

import DropdownController from '../DropdownController/DropdownController';
import Dropdown from '../Dropdown/Dropdown';
// import ModalController from '../ModalController/ModalController';
// import ModalLogin from '../ModalLogin/ModalLogin';
// import ModalRegister from '../ModalRegister/ModalRegister';
// import ModalRules from '../ModalRules/ModalRules';
// import ModalXbox from '../ModalXbox/ModalXbox';

const messages = defineMessages({
  login: {
    id: 'header.login',
    defaultMessage: 'Log In',
    description: 'Log In',
  },
  logout: {
    id: 'header.logout',
    defaultMessage: 'Log Out',
    description: 'Log Out',
  },
  signup: {
    id: 'header.signup',
    defaultMessage: 'Sign Up',
    description: 'Sign Up',
  },
  rules: {
    id: 'header.rules',
    defaultMessage: 'Rules',
    description: 'Rules Link',
  },
  account: {
    id: 'header.account',
    defaultMessage: 'Account',
    description: 'My Account',
  },
  linkHome: {
    id: 'header.linkHome',
    defaultMessage: 'Home',
    description: 'Home Link',
  },
  linkPrizes: {
    id: 'header.linkPrizes',
    defaultMessage: 'Prizes',
    description: 'Prizes Link',
  },
  linkRewards: {
    id: 'header.linkRewards',
    defaultMessage: 'Rewards',
    description: 'Rewards Link',
  },
  linkMyStuff: {
    id: 'header.linkMyStuff',
    defaultMessage: 'My Stuff',
    description: 'My Stuff Link',
  },
});

const Header = ({ hideLinks, location }) => {
  // console.info(location);
  return (
    <HeaderStyled>
      <div className="Header__inner">
        <div className="Header__main">
          {!hideLinks && (
            <DropdownController>
              {(isOpen, dropdownToggle) => (
                <Fragment>
                  <button
                    className="Header__menu"
                    type="button"
                    onClick={dropdownToggle}
                  >
                    <FontAwesomeIcon icon={faBars} />
                  </button>

                  {isOpen && (
                    <Dropdown>
                      <Link className="Header__dropdownLink" to="/my-stuff">
                        <FormattedMessage {...messages.linkMyStuff} />
                      </Link>

                      {/* <button
                        className="Header__dropdownLink"
                        type="button"
                        onClick={() =>
                          setModalQueue(<ModalRules onClose={popModalQueue} />)
                        }
                      >
                        <FormattedMessage {...messages.rules} />
                      </button> */}
                    </Dropdown>
                  )}
                </Fragment>
              )}
            </DropdownController>
          )}

          <Link to="/">
            <img className="Header__logo" src={logo} alt="" />
          </Link>
        </div>

        {!hideLinks && (
          <Fragment>
            <nav className="Header__nav">
              <Link className="Header__navLink" to="/#">
                <FormattedMessage {...messages.linkHome} />
              </Link>
              <Link className="Header__navLink" to="/#prizes">
                <FormattedMessage {...messages.linkPrizes} />
              </Link>
              <Link className="Header__navLink" to="/#rewards">
                <FormattedMessage {...messages.linkRewards} />
              </Link>
              <Link className="Header__navLink" to="/my-stuff">
                <FormattedMessage {...messages.linkMyStuff} />
                {location.pathname.includes('my-stuff') && (
                  <span className="Header__navBadge">4</span>
                )}
              </Link>
            </nav>
          </Fragment>
        )}
      </div>
    </HeaderStyled>
  );
};

Header.propTypes = {
  hideLinks: PropTypes.bool,
  intl: intlShape.isRequired,
};

Header.defaultProps = {
  hideLinks: false,
};

export default injectIntl(Header);
