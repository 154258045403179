// Fonts
export const fontFamily =
  'Roboto, -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif';

// Line Heights
export const lineHeight = 1.71;

// Sizes
export const maxWidth = 1270;

// space
export const space = 8;

// Colors
export const white = '#fff';
export const black = '#000';
export const red = '#d83022';
export const yellow = '#fff200';
export const green = '#0e7a0d';
export const darkGreen = '#197b1a';
export const blue = '#3fa9f5';
export const darkBlue = '#1f88c9';

export const primary = red;
export const secondary = yellow;
export const success = darkGreen;

export const bgWhite = white;
export const bgBlack = black;
export const textColorBlack = black;
export const textColorWhite = white;
export const bgColorBlack = black;
export const bgColorWhite = white;
export const borderColor = white;
export const placeholderColor = '#c8c8c8';
export const overlayColor = 'rgba(86, 96, 117, 0.7)';
export const linksColor = yellow;
export const linksColorHover = darkBlue;

// Radius
export const radius = 4;

// Transitions
export const transitionFast = '150ms';
export const transitionMid = '300ms';
export const transitionSlow = '500ms';

// Breakpoints
export const xsMax = 'max-width: 767px';
export const smMin = 'min-width: 768px';
export const smMax = 'max-width: 1023px';
export const mdMin = 'min-width: 1024px';
export const mdMax = 'max-width: 1439px';
export const lgMin = 'min-width: 1440px';
export const lgMax = 'max-width: 1899px';
export const xlMin = 'min-width: 1900px';
