import styled from 'styled-components';
import { scaleIn } from '../animations';

const DropdownStyled = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  min-width: 200px;
  padding: 18px;
  color: #fff;
  background-color: #000;
  border-top: 1px solid #fff;
  animation-name: ${scaleIn};
  animation-duration: 0.25s;
  z-index: 1900;
`;

export default DropdownStyled;
