import React from 'react';

import * as escapeFlat from '../../images/collectorcans-escape-artwork.png';
import * as escapeBlueFlat from '../../images/collectorcans-escape-blue-artwork.png';
import * as kaitFlat from '../../images/collectorcans-kait-artwork.png';
import * as kaitRedFlat from '../../images/collectorcans-kait-red-artwork.png';
import * as marcusFlat from '../../images/collectorcans-marcus-artwork.png';
import * as jdFlat from '../../images/collectorcans-jd-artwork.png';
import * as jdYellowFlat from '../../images/collectorcans-jd-yellow-artwork.png';
import * as delFlat from '../../images/collectorcans-del-artwork.png';
import * as delBlueFlat from '../../images/collectorcans-del-blue-artwork.png';
import * as swarmFlat from '../../images/collectorcans-swarm-artwork.png';

import img1Us from '../../images/collectorcans-slide-img-1-en-US.png';
import img2Us from '../../images/collectorcans-slide-img-2-en-US.png';
import img3Us from '../../images/collectorcans-slide-img-3-en-US.png';
import img4Us from '../../images/collectorcans-slide-img-4-en-US.png';
import img5Us from '../../images/collectorcans-slide-img-5-en-US.png';
import img6Us from '../../images/collectorcans-slide-img-6-en-US.png';

import img1Ca from '../../images/collectorcans-slide-img-1-en-CA.png';
import img2Ca from '../../images/collectorcans-slide-img-2-en-CA.png';
import img3Ca from '../../images/collectorcans-slide-img-3-en-CA.png';
import img4Ca from '../../images/collectorcans-slide-img-4-en-CA.png';
import img5Ca from '../../images/collectorcans-slide-img-5-en-CA.png';
import img6Ca from '../../images/collectorcans-slide-img-6-en-CA.png';

import img1Gb from '../../images/collectorcans-slide-img-1-en-GB.png';
import img2Gb from '../../images/collectorcans-slide-img-2-en-GB.png';
import img3Gb from '../../images/collectorcans-slide-img-3-en-GB.png';

import img1De from '../../images/collectorcans-slide-img-1-de-DE.png';
import img2De from '../../images/collectorcans-slide-img-2-de-DE.png';
import img3De from '../../images/collectorcans-slide-img-3-de-DE.png';
import img4De from '../../images/collectorcans-slide-img-4-de-DE.png';

import img1Mx from '../../images/collectorcans-slide-img-1-es-MX.png';
import img2Mx from '../../images/collectorcans-slide-img-2-es-MX.png';

export const configIntl = {
  'en-US': {
    cansGallery: [
      {
        src: img1Us,
        alt: '',
        overlay: (
          <div className="CollectorCans__sliderOver">
            <img className="CollectorCans__sliderArt" src={kaitFlat} alt="" />
            <div className="CollectorCans__sliderTitle">KAIT</div>
            <div className="CollectorCans__sliderDesc">Original</div>
            <div className="CollectorCans__sliderNum">1 of 6</div>
          </div>
        ),
      },
      {
        src: img4Us,
        alt: '',
        overlay: (
          <div className="CollectorCans__sliderOver">
            <img className="CollectorCans__sliderArt" src={jdFlat} alt="" />
            <div className="CollectorCans__sliderTitle">JD</div>
            <div className="CollectorCans__sliderDesc">Punched</div>
            <div className="CollectorCans__sliderNum">2 of 6</div>
          </div>
        ),
      },
      {
        src: img5Us,
        alt: '',
        overlay: (
          <div className="CollectorCans__sliderOver">
            <img className="CollectorCans__sliderArt" src={delFlat} alt="" />
            <div className="CollectorCans__sliderTitle">DEL</div>
            <div className="CollectorCans__sliderDesc">
              Pure Zero Silver Ice
            </div>
            <div className="CollectorCans__sliderNum">3 of 6</div>
          </div>
        ),
      },
      {
        src: img2Us,
        alt: '',
        overlay: (
          <div className="CollectorCans__sliderOver">
            <img className="CollectorCans__sliderArt" src={marcusFlat} alt="" />
            <div className="CollectorCans__sliderTitle">MARCUS</div>
            <div className="CollectorCans__sliderDesc">Original</div>
            <div className="CollectorCans__sliderNum">4 of 6</div>
          </div>
        ),
      },
      {
        src: img3Us,
        alt: '',
        overlay: (
          <div className="CollectorCans__sliderOver">
            <img className="CollectorCans__sliderArt" src={escapeFlat} alt="" />
            <div className="CollectorCans__sliderTitle">ESCAPE</div>
            <div className="CollectorCans__sliderDesc">Punched</div>
            <div className="CollectorCans__sliderNum">5 of 6</div>
          </div>
        ),
      },
      {
        src: img6Us,
        alt: '',
        overlay: (
          <div className="CollectorCans__sliderOver">
            <img className="CollectorCans__sliderArt" src={swarmFlat} alt="" />
            <div className="CollectorCans__sliderTitle">SWARM</div>
            <div className="CollectorCans__sliderDesc">
              Pure Zero Silver Ice
            </div>
            <div className="CollectorCans__sliderNum">6 of 6</div>
          </div>
        ),
      },
    ],
  },
  'en-CA': {
    cansGallery: [
      {
        src: img1Ca,
        alt: '',
        overlay: (
          <div className="CollectorCans__sliderOver">
            <img className="CollectorCans__sliderArt" src={kaitFlat} alt="" />
            <div className="CollectorCans__sliderTitle">KAIT</div>
            <div className="CollectorCans__sliderDesc">Original</div>
            <div className="CollectorCans__sliderNum">1 of 6</div>
          </div>
        ),
      },
      {
        src: img2Ca,
        alt: '',
        overlay: (
          <div className="CollectorCans__sliderOver">
            <img className="CollectorCans__sliderArt" src={jdFlat} alt="" />
            <div className="CollectorCans__sliderTitle">JD</div>
            <div className="CollectorCans__sliderDesc">Punched</div>
            <div className="CollectorCans__sliderNum">2 of 6</div>
          </div>
        ),
      },
      {
        src: img3Ca,
        alt: '',
        overlay: (
          <div className="CollectorCans__sliderOver">
            <img className="CollectorCans__sliderArt" src={delFlat} alt="" />
            <div className="CollectorCans__sliderTitle">DEL</div>
            <div className="CollectorCans__sliderDesc">
              Pure Zero Silver Ice
            </div>
            <div className="CollectorCans__sliderNum">3 of 6</div>
          </div>
        ),
      },
      {
        src: img4Ca,
        alt: '',
        overlay: (
          <div className="CollectorCans__sliderOver">
            <img className="CollectorCans__sliderArt" src={marcusFlat} alt="" />
            <div className="CollectorCans__sliderTitle">MARCUS</div>
            <div className="CollectorCans__sliderDesc">Original</div>
            <div className="CollectorCans__sliderNum">4 of 6</div>
          </div>
        ),
      },
      {
        src: img5Ca,
        alt: '',
        overlay: (
          <div className="CollectorCans__sliderOver">
            <img className="CollectorCans__sliderArt" src={escapeFlat} alt="" />
            <div className="CollectorCans__sliderTitle">ESCAPE</div>
            <div className="CollectorCans__sliderDesc">Punched</div>
            <div className="CollectorCans__sliderNum">5 of 6</div>
          </div>
        ),
      },
      {
        src: img6Ca,
        alt: '',
        overlay: (
          <div className="CollectorCans__sliderOver">
            <img className="CollectorCans__sliderArt" src={swarmFlat} alt="" />
            <div className="CollectorCans__sliderTitle">SWARM</div>
            <div className="CollectorCans__sliderDesc">
              Pure Zero Silver Ice
            </div>
            <div className="CollectorCans__sliderNum">6 of 6</div>
          </div>
        ),
      },
    ],
  },
  'en-GB': {
    cansGallery: [
      {
        src: img1Gb,
        alt: '',
        overlay: (
          <div className="CollectorCans__sliderOver">
            <img
              className="CollectorCans__sliderArt"
              src={kaitRedFlat}
              alt=""
            />
            <div className="CollectorCans__sliderTitle">KAIT</div>
            <div className="CollectorCans__sliderDesc">Original</div>
            <div className="CollectorCans__sliderNum">1 of 3</div>
          </div>
        ),
      },
      {
        src: img2Gb,
        alt: '',
        overlay: (
          <div className="CollectorCans__sliderOver">
            <img
              className="CollectorCans__sliderArt"
              src={jdYellowFlat}
              alt=""
            />
            <div className="CollectorCans__sliderTitle">JD</div>
            <div className="CollectorCans__sliderDesc">Tropical Guava</div>
            <div className="CollectorCans__sliderNum">2 of 3</div>
          </div>
        ),
      },
      {
        src: img3Gb,
        alt: '',
        overlay: (
          <div className="CollectorCans__sliderOver">
            <img
              className="CollectorCans__sliderArt"
              src={escapeBlueFlat}
              alt=""
            />
            <div className="CollectorCans__sliderTitle">ESCAPE</div>
            <div className="CollectorCans__sliderDesc">
              Blueberry Pomegranate Acai
            </div>
            <div className="CollectorCans__sliderNum">3 of 3</div>
          </div>
        ),
      },
    ],
  },
  'de-DE': {
    cansGallery: [
      {
        src: img1De,
        alt: '',
        overlay: (
          <div className="CollectorCans__sliderOver">
            <img
              className="CollectorCans__sliderArt"
              src={kaitRedFlat}
              alt=""
            />
            <div className="CollectorCans__sliderTitle">KAIT</div>
            <div className="CollectorCans__sliderDesc">Original</div>
            <div className="CollectorCans__sliderNum">1 of 4</div>
          </div>
        ),
      },
      {
        src: img2De,
        alt: '',
        overlay: (
          <div className="CollectorCans__sliderOver">
            <img
              className="CollectorCans__sliderArt"
              src={jdYellowFlat}
              alt=""
            />
            <div className="CollectorCans__sliderTitle">JD</div>
            <div className="CollectorCans__sliderDesc">Tropical Guava</div>
            <div className="CollectorCans__sliderNum">2 of 4</div>
          </div>
        ),
      },
      {
        src: img3De,
        alt: '',
        overlay: (
          <div className="CollectorCans__sliderOver">
            <img
              className="CollectorCans__sliderArt"
              src={escapeBlueFlat}
              alt=""
            />
            <div className="CollectorCans__sliderTitle">ESCAPE</div>
            <div className="CollectorCans__sliderDesc">
              Blueberry Pomegranate Acai
            </div>
            <div className="CollectorCans__sliderNum">3 of 4</div>
          </div>
        ),
      },
      {
        src: img4De,
        alt: '',
        overlay: (
          <div className="CollectorCans__sliderOver">
            <img
              className="CollectorCans__sliderArt"
              src={delBlueFlat}
              alt=""
            />
            <div className="CollectorCans__sliderTitle">DEL</div>
            <div className="CollectorCans__sliderDesc">Blue Raspberry</div>
            <div className="CollectorCans__sliderNum">4 of 4</div>
          </div>
        ),
      },
    ],
  },
};

export const configMx = {
  cansGallery: [
    {
      src: img1Mx,
      alt: '',
      overlay: (
        <div className="CollectorCans__sliderOver">
          <img className="CollectorCans__sliderArt" src={kaitRedFlat} alt="" />
          <div className="CollectorCans__sliderTitle">KAIT</div>
          <div className="CollectorCans__sliderDesc">Original</div>
          <div className="CollectorCans__sliderNum">1 of 2</div>
        </div>
      ),
    },
    {
      src: img2Mx,
      alt: '',
      overlay: (
        <div className="CollectorCans__sliderOver">
          <img className="CollectorCans__sliderArt" src={escapeFlat} alt="" />
          <div className="CollectorCans__sliderTitle">ESCAPE</div>
          <div className="CollectorCans__sliderDesc">Original</div>
          <div className="CollectorCans__sliderNum">2 of 2</div>
        </div>
      ),
    },
  ],
};
