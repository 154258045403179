import React from 'react';

const RulesUs = () => (
  <div>
    <h3>
      <strong>Rockstar - Gears 5 - Terms & Conditions</strong>
    </h3>

    <p>
      <strong>Approved Short T&Cs</strong>
    </p>

    <p>
      Open to UK/CI/IoM/ROI residents aged 18+. Normal exclusions apply. Opens:
      1 Sept 2019. Closes: 29 Nov 2019. 2 daily prize draws: 1) For 5 x digital
      versions of the Gears 5 Game for XBOX (450 prizes total) and 2) For 1 x
      Console Bundle: an XBOX Console and digital Gears 5 Game (90 prizes
      total). To enter: purchase Gears 5 edition 500ml Rockstar during promo
      term. Find unique code under ring-pull. Visit{' '}
      <a
        href="https://gears.rockstarenergy.com/en-GB/"
        target="_blank"
        rel="noreferrer noopener"
      >
        gears.rockstarenergy.com/gb
      </a>{' '}
      , register and submit unique code before 29 Nov 2019. 1 purchase: 1 entry.
      ROI & NI: no purchase necessary - see full Ts & Cs. Each valid entry
      places entrant into daily draws for entry date. Max 1 of each prize per
      entrant over term. Full T&Cs on entry website. Internet access required.
      Retain winning product/unique code/receipt. Ring-pull codes also unlock
      in-game items and XBOX Game Pass Access. See full Ts & Cs.
    </p>

    <p>
      <strong>Specific T&Cs</strong>
    </p>

    <ol>
      <li>
        Please read these conditions of entry carefully. By submitting an entry
        to this promotion, you are deemed to have read and accepted them.
      </li>
      <li>
        The <strong>promoter</strong> and <strong>data controller</strong> is
        A.G. BARR p.l.c. (Company No. SC005653) of Westfield House, 4 Mollins
        Road, Cumbernauld G68 9HD <strong>(we, our, us)</strong>.
      </li>
      <li>
        <strong>Who can enter:</strong> This promotion is open to legal
        residents of the UK, Channel Islands, Isle of Man and Republic of
        Ireland who are aged 18 or over at the time of entry, excluding: (i) our
        employees; (ii) the employees of any third party involved in helping us
        with this promotion (e.g. our agency); and (iii) the immediate family
        members of (i) and (ii). We may require any information that we consider
        reasonably necessary in order to verify your eligibility to enter this
        promotion, including ID (passport/driver’s license) for proof of age.
      </li>
      <li>
        <strong>When to enter:</strong> You need to enter between 00:00 on 1
        September 2019 and 23:59 on 29 November 2019 (the{' '}
        <strong>Promotional Period</strong>) to be in with a chance of winning.
      </li>
      <li>
        <strong>Promotion:</strong> The promotion involves 2 daily prize draws
        (i) a digital Gears 5 game for XBOX One prize draw (5 prizes per day)
        and (ii) a Gears 5 console bundle prize draw (1 prize per day).{' '}
        <strong>
          Entrants need (or need to create) a free Microsoft account to enter
          into the prize draws.
        </strong>
      </li>
      <li>
        <p>
          <strong>The daily prize draws:</strong>{' '}
        </p>
        <ul>
          <li>
            <p>
              <strong>What are they?</strong>
            </p>
            <p>
              2 separate prize draws held for each day of the Promotional Period
              for all valid entries received between 00:00 and 23:59 on the day
              in question (i.e. the first prize draws will be for 1 September
              2019 for all valid entries received between 00:00 and 23:59 on
              that day and there will be 89 subsequent daily prize draws meaning
              90 daily prize draws in total).
            </p>
          </li>
          <li>
            <p>
              <strong>When do I enter?</strong>
            </p>
            <p>
              You can enter between 00:00 and 23:59 on any day during the
              Promotional Period. If you submit a valid entry, you will be
              automatically entered into both of the daily prize draws for the
              day you enter.
            </p>
          </li>
          <li>
            <p>
              <strong>When will you hold each daily prize draw?</strong>
            </p>
            <p>
              We will hold the daily prize draws on the dates set out on the
              schedule below:
            </p>
            <table>
              <thead>
                <tr>
                  <th>Daily prize draws </th>
                  <th>Date on which we will hold the draws </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1 - 8 September </td>
                  <td>Mon 9 September</td>
                </tr>
                <tr>
                  <td>9 - 15 September</td>
                  <td>Mon 16 September</td>
                </tr>
                <tr>
                  <td>16 - 22 September</td>
                  <td>Mon 23 September</td>
                </tr>
                <tr>
                  <td>23 - 29 September</td>
                  <td>Mon 30 September</td>
                </tr>
                <tr>
                  <td>30 September - 6 October</td>
                  <td>Mon 7 October</td>
                </tr>
                <tr>
                  <td>7 - 13 October</td>
                  <td>Mon 14 October</td>
                </tr>
                <tr>
                  <td>14 - 20 October</td>
                  <td>Mon 21 October</td>
                </tr>
                <tr>
                  <td>21 - 27 October</td>
                  <td>Mon 28 October</td>
                </tr>
                <tr>
                  <td>28 October - 3 November</td>
                  <td>Mon 4 November</td>
                </tr>
                <tr>
                  <td>4 - 10 November</td>
                  <td>Mon 11 November</td>
                </tr>
                <tr>
                  <td>11 - 17 November</td>
                  <td>Mon 18 November</td>
                </tr>
                <tr>
                  <td>18 - 24 November</td>
                  <td>Mon 25 November</td>
                </tr>
                <tr>
                  <td>25 - 29 November</td>
                  <td>Mon 2 December</td>
                </tr>
              </tbody>
            </table>
          </li>
          <li>
            <p>
              <strong>
                How many winners will there be and what will they win?
              </strong>
            </p>
            <ol type="i">
              <li>
                <p>
                  <strong>Gears 5 console bundle prize draw:</strong>
                </p>
                <ul>
                  <li>
                    There will be 1 winner for each daily prize draw (meaning 90
                    prizes in total).
                  </li>
                  <li>
                    Each winner will win a Gears 5 console bundle which includes
                    an XBOX One console and a Gears 5 digital game for XBOX.
                  </li>
                </ul>
              </li>
              <li>
                <p>
                  <strong>Digital Gears 5 game prize draw:</strong>
                </p>
                <ul>
                  <li>
                    There will be 5 winners for each daily prize draw (meaning
                    450 prizes in total).
                  </li>
                  <li>
                    Each winner will win a digital copy of the Gears 5 Game for
                    XBOX.
                  </li>
                </ul>
              </li>
            </ol>
          </li>
          <li>
            <p>
              <strong>How will winners be selected?</strong>
            </p>
            <p>Winners will be selected at random.</p>
          </li>

          <li>
            <p>
              <strong>How will I know if I’ve won?</strong>
            </p>
            <ol type="i">
              <li>
                <p>
                  <strong>Gears 5 console bundle prize draw:</strong>
                </p>
                <ul>
                  <li>
                    We will notify each winner the day after they have been
                    selected as winner (i.e. we will notify the winner of the 1
                    September 2019 daily prize draws on 10 September 2019). We
                    will contact winners using the contact details provided when
                    entering the promotion.
                  </li>
                  <li>
                    We will ask winners to confirm their postal address so we
                    can post them their prize.
                  </li>
                </ul>
              </li>
              <li>
                <p>
                  <strong>Digital Gears 5 game prize draw:</strong>
                </p>
                <ul>
                  <li>
                    We will notify each winner the day after they have been
                    selected as winner (i.e. we will notify the winner of the 1
                    September 2019 daily prize draws on 10 September 2019). We
                    will contact winners using the e-mail details provided when
                    entering the promotion.
                  </li>
                </ul>
              </li>
            </ol>
          </li>
          <li>
            <p>
              <strong>How/when will I receive my prize?</strong>
            </p>
            <ol type="i">
              <li>
                <p>
                  <strong>Gears 5 console bundle prize:</strong>
                </p>
                <ul>
                  <li>
                    We will send your prize to you through the post within a
                    month of the date on which you provide us with your postal
                    address (after we have notified you about your prize win).
                  </li>
                </ul>
              </li>
              <li>
                <p>
                  <strong>Digital Gears 5 game prize draw:</strong>
                </p>
                <ul>
                  <li>
                    We will send your prize through e-mail when we notify you
                    about your prize win. Our e-mail will contain instructions
                    about how to obtain your digital copy of the digital Gears 5
                    game.
                  </li>
                </ul>
              </li>
            </ol>
          </li>
        </ul>
      </li>

      <li>
        <p>
          <strong>How to enter:</strong>
        </p>
        <ul>
          <li>
            Purchase any Gears 5 promotional pack of 500ml Rockstar during the
            promotional period.
          </li>
          <li>Locate the unique code under the can ring-pull.</li>
          <li>
            <p>
              Visit <strong>gears.rockstarenergy.com/gb</strong>, where you will
              be asked to:
            </p>
            <ol type="i">
              <li>complete the website registration process</li>
              <li>
                submit your name, e-mail address and mobile telephone number;
                and
              </li>
              <li>submit your unique code.</li>
            </ol>
          </li>
        </ul>
        <p>
          Please note that entrants must complete the website registration
          process using their Microsoft account details. Any applicable
          instructions will be highlighted to entrants when accessing the
          promotion entry website.
        </p>
      </li>

      <li>
        <p>
          There are no limits to the number of valid entries you can submit
          during each day of the promotional period. However, the promotional
          website has been designed with controls aimed at limiting fraudulent
          promotional activity, meaning that the following entry parameters
          apply:
        </p>
        <ul>
          <li>
            If you submit 15 invalid unique codes within 24 hours you will be
            timed out of the promotional website for the following 24 hours.
          </li>
        </ul>
      </li>

      <li>
        <p>
          No person may win either of the prizes available under each type of
          prize draw more than once during the promotional period. (i.e.
          Entrants can only win 1 of the Gears 5 console bundles and 1 of the
          Digital Gears 5 games). If you are selected as a winner of a prize
          draw when you have already won the available prize in that prize draw
          then a reserve winner will be selected instead.
        </p>
      </li>

      <li>
        <p>
          Entrants must retain their promotional can of 500ml Rockstar and
          receipt as proof as purchase, as we may need to check them for
          verification purposes.
        </p>
      </li>
      <li>
        <p>
          <strong>
            No Purchase Necessary Route for Northern Ireland and The Republic of
            Ireland Residents only:
          </strong>{' '}
          Republic of Ireland or Northern Ireland residents only may enter the
          promotion without purchase by sending their name, address (including
          postcode), date of birth, daytime telephone number and email address,
          by letter to: "’FAO Rockstar Asst. Brand Manager – Rockstar Gears 5
          Promotion, Westfield House, 4 Mollins Road, Cumbernauld, G68 9HD.''
          Only one entry per person. Only one entry per postal address will be
          accepted. Only one entry per letter. Letters must be received by 26th
          November 2019 and must be correctly stamped. After we have received
          your letter we will send you by email one promotional code for you to
          use to enter the promotion as described in condition 7.
        </p>
      </li>

      <li>
        <p>
          You must submit your own entry. You must not use any software or other
          method to submit multiple entries.
        </p>
      </li>

      <li>
        <p>
          There is no cash or other alternative to a prize (or to any individual
          element of it). We reserve the right to substitute a prize (including
          any individual element of it) for an alternative of equal or greater
          value but only where we deem this necessary as a result of events
          outside our reasonable control. The winner may not transfer his/her
          right to take up his/her prize to anyone else without our consent.
        </p>
      </li>

      <li>
        <p>
          We may, at our sole discretion, opt to re-award a prize to a reserve
          winner selected on the same basis as the original winner if the
          original winner fails our verification or eligibility check, or is
          disqualified under condition 17.
        </p>
      </li>

      <li>
        <p>
          If we have been unable to make contact with a winner within one week
          of our notification of their prize win, we will withdraw their prize
          and no reserve winner will be selected. We will make a reasonable
          number of follow-up attempts to make contact with winners over the
          course of the week before withdrawing their prize for failure to make
          contact with us. We will use the contact details that the winner
          provided to us when they entered the promotion.
        </p>
      </li>

      <li>
        <p>
          We reserve the right to suspend, vary or cancel this promotion and/or
          vary these conditions without notice where we deem it absolutely
          necessary as a result of events outside our reasonable control. In
          these circumstances, we will do all we can to minimise any
          disappointment caused.
        </p>
      </li>

      <li>
        <p>
          We may disqualify anyone who: (i) breaches any of these conditions; or
          (ii) otherwise undermines or jeopardises the integrity of this
          promotion.
        </p>
      </li>

      <li>
        <p>Our decision is final on all matters related to this promotion.</p>
      </li>

      <li>
        <p>
          To the fullest extent allowed by law, we will not be liable for: (i)
          your failure to enter this promotion due to problems with your phone,
          network or internet connection; (ii) your failure to enter this
          promotion due to technical issues with our website; or (iii) any loss,
          damage or injury suffered by the winners in connection with their
          uptake of the prizes.
        </p>
      </li>

      <li>
        <p>
          We will use the personal information you supply to us in connection
          with this promotion to administer this promotion (including by sharing
          it with Rockstar US and our marketing agency). We anticipate that
          winners may wish to engage with us in relation to their prize wins by
          sharing media, images and social media content. We may use any such
          shared content within media we use to promote the company and/or the
          Rockstar brand. We won’t use any personal information supplied or
          obtained in connection with this promotion for any other purposes
          without prior consent. We will delete copies of ID provided by
          entrants after we have finalised our verification checks.
        </p>
      </li>

      <li>
        <p>
          The Promoter is operating a separate scheme under which entrants to
          this Promotion can obtain Gears 5 in-game content. These promotional
          terms are not intended to cover the rules in relation to that separate
          scheme. You can find the applicable details at{' '}
          <a
            href="https://gears.rockstarenergy.com/en-GB/"
            target="_blank"
            rel="noreferrer noopener"
          >
            gears.rockstarenergy.com/gb
          </a>{' '}
          . A Microsoft account will be required to obtain this content.
        </p>
      </li>

      <li>
        <p>
          Prize winners may be required to take part in reasonable unpaid
          publicity in connection with this promotion.
        </p>
      </li>

      <li>
        <p>
          To obtain the surname and county of the winner of the main prize draw,
          send a self-addressed envelope clearly marked “FAO Rockstar Brand
          Asst. Manager – Rockstar Gears 5 Promotion” with details of your
          request to: A.G. BARR p.l.c., Westfield House, 4 Mollins Road,
          Cumbernauld G68 9HD within 3 months of the end of the Promotional
          Period.
        </p>
      </li>

      <li>
        <p>
          These conditions of entry will be interpreted and applied on the basis
          of Scottish law and the Scottish courts will have exclusive
          jurisdiction in respect of any dispute.
        </p>
      </li>
    </ol>
  </div>
);

export default RulesUs;
