import styled from 'styled-components';
import * as defaultTheme from '../variables';

const CodeFormStyled = styled.div`
  width: 335px;

  @media (${p => p.theme.lgMin}) {
    width: 428px;
  }

  .CodeForm__form {
    display: flex;
    align-items: flex-end;

    form {
      width: 100%;
    }
  }

  .CodeForm__tab {
    flex-shrink: 0;
    width: 45px;
    margin-right: ${p => p.theme.space * 2}px;

    @media (${p => p.theme.lgMin}) {
      width: 56px;
    }

    & > img {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  .CodeForm__label {
    display: block;
    margin-bottom: ${p => p.theme.space}px;
    color: ${p => p.theme.white};
    font-size: 13px;
    font-weight: 700;
    letter-spacing: 0.88px;
    text-transform: uppercase;
    text-shadow: 0 0 5px ${p => p.theme.textColorBlack},
      1px 1px 2px ${p => p.theme.textColorBlack},
      2px 2px 2px ${p => p.theme.textColorBlack},
      3px 3px 2px ${p => p.theme.textColorBlack},
      -1px -1px 2px ${p => p.theme.textColorBlack},
      -2px -2px 2px ${p => p.theme.textColorBlack},
      -3px -3px 2px ${p => p.theme.textColorBlack};
  }

  .CodeForm__fieldset {
    display: flex;
    align-items: center;
  }

  .CodeForm__select {
    display: block;
    min-width: 190px;
    margin-bottom: ${p => p.theme.space * 2}px;
    padding: 4px 18px 4px 6px;
    background-color: ${p => p.theme.bgWhite};
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23000' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 6px center;
    background-size: 8px 10px;
    font-size: 13px;
    border: 1px solid ${p => p.theme.borderColor};
    border-radius: 0;
    appearance: none;
  }

  .CodeForm__input {
    width: calc(100% - 40px);
    height: 36px;
    padding: 0 ${p => p.theme.space * 2}px;
    background-color: ${p => p.theme.bgWhite};
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 4px;
    text-transform: uppercase;
    border: 0 none;
    border-radius: 0;

    @media (${p => p.theme.lgMin}) {
      width: calc(100% - 52px);
      height: 52px;
    }

    &::placeholder {
      position: relative;
      color: ${p => p.theme.placeholderColor};
      font-size: 16px;
      letter-spacing: 4px;
      text-transform: uppercase;
    }
  }

  .CodeForm__btnCode {
    flex-shrink: 0;
    width: 40px;
    height: 36px;
    color: #fff;
    vertical-align: middle;
    border: 2px solid #fff;
    transition: background-color ${p => p.theme.transitionFast};

    @media (${p => p.theme.lgMin}) {
      width: 52px;
      height: 52px;
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.5);
    }
  }

  .CodeForm__validate {
    margin-top: ${p => p.theme.space}px;
    padding-left: 61px;

    @media (${p => p.theme.lgMin}) {
      padding-left: 71px;
    }
  }

  .CodeForm__info {
    max-width: 400px;
    margin: 12px 0;
    padding: 2px 12px;
    color: #ff0725;
    background-color: rgba(0, 0, 0, 0.5);
    text-shadow: 0px 0px 7px #000;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.3;
  }

  .CodeForm__alert {
    margin: ${p => p.theme.space}px 0;
  }

  .CodeForm__recaptcha {
    position: relative;
    z-index: 1000;
  }
`;

CodeFormStyled.defaultProps = {
  theme: defaultTheme,
};

export default CodeFormStyled;
