import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, defineMessages } from 'react-intl';
import { injectIntl, intlShape } from 'gatsby-plugin-intl';

import CodeFormStyled from './CodeForm.style';
import CanTab from '../../images/can-tab.svg';

import MaskedInputText from '../Forms/MaskedInputText/MaskedInputText';
import Alert from '../Alert/Alert';

const messages = defineMessages({
  label: {
    id: 'codeForm.label',
    defaultMessage: 'Enter Code from Tab',
    description: 'Label',
  },
  warning: {
    id: 'codeForm.warning',
    defaultMessage:
      'You can continue entering codes to unlock in-game engrams, but the chance to win prizes has ended.',
    description: 'Warning',
  },
  selectAnswer: {
    id: 'codeForm.selectAnswer',
    defaultMessage: 'Select Answer',
    description: 'Select Answer',
  },
});

class CodeForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      code: '',
      questionAnswer: '',
      message: null,
      submitting: false,
    };
  }

  handleSubmit(e) {
    e.preventDefault();
  }

  render() {
    const { message, code, questionAnswer, submitting } = this.state;
    const { intl, hideTab, label } = this.props;

    return (
      <CodeFormStyled>
        <div className="CodeForm__form">
          {hideTab === false && (
            <div className="CodeForm__tab">
              <img src={CanTab} alt="" />
            </div>
          )}

          <form onSubmit={this.handleSubmit}>
            {intl.locale === 'es-MX' && (
              <label
                className="CodeForm__label"
                htmlFor="question"
                style={{ lineHeight: 1 }}
              >
                ¿Quién será el (la) protagonista de Gears 5?
              </label>
            )}
            {intl.locale === 'es-MX' && (
              <select
                className="CodeForm__select"
                id="question"
                value={questionAnswer}
                onChange={this.questionChange}
              >
                <option value="">
                  {`(${intl.formatMessage(messages.selectAnswer)})`}
                </option>
                <option value="Kait">Kait Díaz</option>
                <option value="Marcus">Marcus Fenix</option>
                <option value="Del">Del Walker</option>
                <option value="JD">JD Fenix</option>
              </select>
            )}

            <label className="CodeForm__label" htmlFor="code">
              <FormattedMessage {...messages.label} />
            </label>

            <div className="CodeForm__fieldset">
              <MaskedInputText
                className="CodeForm__input"
                id="code"
                placeholder="XXXX-XXXX-XXXX"
                mask={[
                  /[a-zA-Z0-9]/,
                  /[a-zA-Z0-9]/,
                  /[a-zA-Z0-9]/,
                  /[a-zA-Z0-9]/,
                  '-',
                  /[a-zA-Z0-9]/,
                  /[a-zA-Z0-9]/,
                  /[a-zA-Z0-9]/,
                  /[a-zA-Z0-9]/,
                  '-',
                  /[a-zA-Z0-9]/,
                  /[a-zA-Z0-9]/,
                  /[a-zA-Z0-9]/,
                  /[a-zA-Z0-9]/,
                ]}
                keepCharPositions={false}
                value={code}
                guide
              />
              <button className="CodeForm__btnCode" type="submit">
                <svg
                  width="21"
                  height="20"
                  viewBox="0 0 21 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill="currentColor"
                    d="M11 .1l9.9 9.9-1.32 1.32L11 19.9l-1.32-1.32L17.26 11H0V9h17.26L9.68 1.42z"
                  />
                </svg>
              </button>
            </div>
          </form>
        </div>

        <div className="CodeForm__validate">
          {message || submitting ? (
            <div className="CodeForm__info">
              {message && (
                <Alert className="CodeForm__alert" status={message.status}>
                  {message.value}
                </Alert>
              )}
              {submitting && !message && (
                <Alert className="CodeForm__alert" status="info" loader>
                  Submitting code...
                </Alert>
              )}
            </div>
          ) : null}
        </div>
      </CodeFormStyled>
    );
  }
}

CodeForm.propTypes = {
  intl: intlShape.isRequired,
  setModalQueue: PropTypes.func.isRequired,
  clearModalQueue: PropTypes.func.isRequired,
  redeemMutation: PropTypes.func.isRequired,
  hideTab: PropTypes.bool,
  label: PropTypes.node,
};

CodeForm.defaultProps = {
  hideTab: false,
  label: null,
};

export default injectIntl(CodeForm);
