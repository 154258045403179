import React from 'react';

const RulesUs = () => (
  <div>
    <h3>
      <strong>Rockstar Gears 5 Sweepstakes Official Rules</strong>
    </h3>

    <p>
      <strong>
        NO PURCHASE OR PAYMENT NECESSARY. A PURCHASE WILL NOT IMPROVE YOUR
        CHANCE OF WINNING. VOID WHERE PROHIBITED.
      </strong>
    </p>
    <p>
      <strong>
        BY ENTERING THE SWEEPSTAKES, ENTRANT AGREES TO THESE OFFICIAL RULES.
      </strong>
    </p>

    <ol>
      <li>
        <strong>Eligibility:</strong> The Rockstar Gears 5 Sweepstakes (the
        "Sweepstakes") is open only to legal residents of the 50 United States
        and District of Columbia and Canada, who are the age of majority or
        older in state/province of residence. Void where prohibited by law. No
        purchase necessary to enter the Sweepstakes. Employees of Rockstar,
        Inc., 101 Convention Center Dr., Suite 777, Las Vegas, NV 89109 (the
        "Sponsor"), Brandmovers, Inc., 590 Means Street, Suite 250, Atlanta, GA
        30318 ("Administrator”), or any of their respective affiliates,
        subsidiaries, advertising agencies, or any other company or individual
        involved with the design, production execution or distribution of the
        Sweepstakes (collectively with Sponsor and Administrator, the
        "Sweepstakes Entities") and their immediate family (spouse, parents and
        step-parents, siblings and step- siblings, and children and
        step-children) and household members of each such employee are not
        eligible to enter or win.  The Sweepstakes is subject to all applicable
        federal, state, and local laws and regulations.  Participation
        constitutes entrant's full and unconditional agreement to these Official
        Rules and Sponsor's decisions, which are final and binding in all
        matters related to the Sweepstakes.  Winning a prize is contingent upon
        fulfilling all requirements set forth herein.
      </li>
      <li>
        <strong>Sweepstakes Entry Period:</strong> The Sweepstakes begins on
        September 1, 2019 at 12:00:00 PM Eastern Time (“ET”) (noon) and ends on
        November 30, 2019 at 11:59:59 AM ET (the "Sweepstakes Period").  The
        Website (defined below) clock is the official timekeeper for this
        Sweepstakes. The Sweepstakes Period will consist of ninety (90) daily
        entry pools and one grand prize entry pool. For purposes of this
        Sweepstakes, each daily entry pool begins at 12:00:00 PM ET (noon) and
        ends at 11:59:59 AM ET the following morning each day of the Sweepstakes
        Period (each, a “Daily Entry Pool”). The grand prize pool coincides with
        the Sweepstakes Period (“Grand Prize Pool”).
      </li>
      <li>
        <strong>How to Enter:</strong> During the Sweepstakes Period, look for a
        code under the tab of specially-marked cans of Rockstar Energy Drink
        (“Code”). Then, to register to enter the Sweepstakes, visit{' '}
        <a
          href="https://gears.rockstarenergy.com"
          target="_blank"
          rel="noreferrer noopener"
        >
          gears.rockstarenergy.com
        </a>{' '}
        (“Website”) find and complete the registration form by providing your
        name, email address, mobile number, and either using your existing or
        creating a new Microsoft username and password to log in. Once you’ve
        registered, input your Code where indicated to automatically receive one
        (1) entry into the Grand Prize Entry Pool and one (1) entry into that
        day’s Daily Entry Pool simultaneously. Non-winning entries received in
        any Daily Entry Pool WILL BE carried over to subsequent Daily Entry
        Pools. To enter without purchase and without obtaining a code (“AMOE”),
        send a 3x5 paper/card with the date, your full name, date of birth,
        email address and state of residence, to: Rockstar Gears 5 Sweepstakes
        Entry, PO Box 93245 Atlanta, GA 30377, ATTN: Brandmovers, Inc., to be
        received by 12/6/2019. Limit one (1) AMOE request per outer envelope.
        Use of any device or artifice to enter in violation of the entry limit
        may result in disqualification of all entries. In the event of a dispute
        as to the identity of entrant, entry will be deemed made by the
        authorized account holder of the e-mail address used to enter the
        Sweepstakes, as determined by Sponsor, in its sole discretion. Entry
        information must be manually key-stroked; use of automated means of
        entry is prohibited and entries made via such improper means will be
        disqualified. Responsibility for receipt of entry rests solely with the
        entrant. All entries become the property of Sponsor and will not be
        acknowledged or returned. Proof of submission of completed entry form
        does not constitute proof of receipt or proof of entry in Sweepstakes.
      </li>
      <li>
        <strong>RANDOM DRAWINGS:</strong> On or about each of the Random Drawing
        dates set forth in the Daily Entry Pool chart below, one (1) First Prize
        and five (5) Second Prize winners will be selected by a random drawing,
        to be conducted by Administrator from among all eligible entries
        received in that Daily Entry Pool (and all non-winning entries from
        subsequent Daily Entry Pools). Each potential First and Second Prize
        Winners will each be notified by email on or about the day of the
        applicable random drawing and will have seventy-two (72) hours from the
        time the First and Second Prize Winner notification is sent by
        Administrator to respond. In the event potential First or Second Prize
        Winner fails to respond to the Winner notification with seventy-two (72)
        hours, he/she will forfeit his/her right to the Prize and that Prize
        may, at Sponsor’s sole discretion, be awarded to an alternate Winner,
        time permitting.
        <h4>DAILY ENTRY POOL CHART</h4>
        <div style={{ overflowX: 'auto' }}>
          <table>
            <thead>
              <tr>
                <th>Daily Entry Pool #</th>
                <th>Starts at 12:00:00 PM ET (noon) on:</th>
                <th>Ends at 11:59:59 AM on:</th>
                <th>AMOE Entry Received by</th>
                <th>Random Drawing on:</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>9/1/2019</td>
                <td>9/2/2019</td>
                <td>9/9/2019</td>
                <td>9/10/2019</td>
              </tr>
              <tr>
                <td>2</td>
                <td>9/2/2019</td>
                <td>9/3/2019</td>
                <td>9/9/2109</td>
                <td>9/10/2019</td>
              </tr>
              <tr>
                <td>3</td>
                <td>9/3/209</td>
                <td>9/4/2019</td>
                <td>9/10/2019</td>
                <td>9/11/2019</td>
              </tr>
              <tr>
                <td>4</td>
                <td>9/4/2019</td>
                <td>9/5/2019</td>
                <td>9/11/2019</td>
                <td>9/12/2019</td>
              </tr>
              <tr>
                <td>5</td>
                <td>9/5/2019</td>
                <td>9/6/2019</td>
                <td>9/12/219</td>
                <td>9/13/2019</td>
              </tr>
              <tr>
                <td>6</td>
                <td>9/6/2019</td>
                <td>9/7/2019</td>
                <td>9/13/2109</td>
                <td>9/16/2019</td>
              </tr>
              <tr>
                <td>7</td>
                <td>9/7/22019</td>
                <td>9/8/2019</td>
                <td>9/14/2019</td>
                <td>9/16/2019</td>
              </tr>
              <tr>
                <td>8</td>
                <td>9/8/2019</td>
                <td>9/9/2019</td>
                <td>9/16/2019</td>
                <td>9/17/2019</td>
              </tr>
              <tr>
                <td>9</td>
                <td>9/9/2019</td>
                <td>9/10/2019</td>
                <td>9/16/2019</td>
                <td>9/17/2019</td>
              </tr>
              <tr>
                <td>10</td>
                <td>9/10/2019</td>
                <td>9/11/2019</td>
                <td>9/17/2019</td>
                <td>9/18/2019</td>
              </tr>
              <tr>
                <td>11</td>
                <td>9/11/2019</td>
                <td>9/12/2019</td>
                <td>9/18/2019</td>
                <td>9/19/2019</td>
              </tr>
              <tr>
                <td>12</td>
                <td>9/12/2019</td>
                <td>9/13/2019</td>
                <td>9/19/2019</td>
                <td>9/20/2019</td>
              </tr>
              <tr>
                <td>13</td>
                <td>9/13/2019</td>
                <td>9/14/2019</td>
                <td>9/20/2019</td>
                <td>9/23/2019</td>
              </tr>
              <tr>
                <td>14</td>
                <td>9/14/2019</td>
                <td>9/15/2019</td>
                <td>9/21/2019</td>
                <td>9/24/2019</td>
              </tr>
              <tr>
                <td>15</td>
                <td>9/15/2019</td>
                <td>9/16/2019</td>
                <td>9/23/2019</td>
                <td>9/24/2019</td>
              </tr>
              <tr>
                <td>16</td>
                <td>9/16/2019</td>
                <td>9/17/2019</td>
                <td>9/23/2019</td>
                <td>9/24/2019</td>
              </tr>
              <tr>
                <td>17</td>
                <td>9/17/2019</td>
                <td>9/18/2019</td>
                <td>9/24/2019</td>
                <td>9/25/2019</td>
              </tr>
              <tr>
                <td>18</td>
                <td>9/18/2019</td>
                <td>9/19/2019</td>
                <td>9/25/2019</td>
                <td>9/26/2019</td>
              </tr>
              <tr>
                <td>19</td>
                <td>9/19/2019</td>
                <td>9/20/2019</td>
                <td>9/26/2019</td>
                <td>9/27/2019</td>
              </tr>
              <tr>
                <td>20</td>
                <td>9/20/2019</td>
                <td>9/21/2019</td>
                <td>9/27/2019</td>
                <td>9/30/2019</td>
              </tr>
              <tr>
                <td>21</td>
                <td>9/21/2019</td>
                <td>9/22/2019</td>
                <td>9/28/2019</td>
                <td>9/30/2019</td>
              </tr>
              <tr>
                <td>22</td>
                <td>9/22/2019</td>
                <td>9/23/2019</td>
                <td>9/30/2019</td>
                <td>10/1/2019</td>
              </tr>
              <tr>
                <td>23</td>
                <td>9/23/2019</td>
                <td>9/24/2019</td>
                <td>9/30/2019</td>
                <td>10/1/2019</td>
              </tr>
              <tr>
                <td>24</td>
                <td>9/24/2019</td>
                <td>9/25/2019</td>
                <td>10/1/2019</td>
                <td>10/2/2019</td>
              </tr>
              <tr>
                <td>25</td>
                <td>9/25/2019</td>
                <td>9/26/2019</td>
                <td>10/2/2019</td>
                <td>10/3/2019</td>
              </tr>
              <tr>
                <td>26</td>
                <td>9/26/2019</td>
                <td>9/27/2019</td>
                <td>10/3/2019</td>
                <td>10/4/2019</td>
              </tr>
              <tr>
                <td>27</td>
                <td>9/27/2019</td>
                <td>9/28/2019</td>
                <td>10/4/2019</td>
                <td>10/7/2019</td>
              </tr>
              <tr>
                <td>28</td>
                <td>9/28/2019</td>
                <td>9/29/2019</td>
                <td>10/5/2019</td>
                <td>10/7/2019</td>
              </tr>
              <tr>
                <td>29</td>
                <td>9/29/2019</td>
                <td>9/30/2019</td>
                <td>10/7/2019</td>
                <td>10/8/2019</td>
              </tr>
              <tr>
                <td>30</td>
                <td>9/30/2019</td>
                <td>10/1/2019</td>
                <td>10/7/2019</td>
                <td>10/8/2019</td>
              </tr>
              <tr>
                <td>31</td>
                <td>10/1/2019</td>
                <td>10/2/2019</td>
                <td>10/8/2019</td>
                <td>10/9/2019</td>
              </tr>
              <tr>
                <td>32</td>
                <td>10/2/2019</td>
                <td>10/3/2019</td>
                <td>10/9/2019</td>
                <td>10/10/2019</td>
              </tr>
              <tr>
                <td>33</td>
                <td>10/3/2019</td>
                <td>10/4/2019</td>
                <td>10/10/2019</td>
                <td>10/11/2019</td>
              </tr>
              <tr>
                <td>34</td>
                <td>10/4/2019</td>
                <td>10/5/2019</td>
                <td>10/11/2019</td>
                <td>10/14/2019</td>
              </tr>
              <tr>
                <td>35</td>
                <td>10/5/2019</td>
                <td>10/6/2019</td>
                <td>10/12/2019</td>
                <td>10/14/2019</td>
              </tr>
              <tr>
                <td>36</td>
                <td>10/6/2019</td>
                <td>10/7/2019</td>
                <td>10/14/2019</td>
                <td>10/15/2019</td>
              </tr>
              <tr>
                <td>37</td>
                <td>10/7/2019</td>
                <td>10/8/2019</td>
                <td>10/14/2019</td>
                <td>10/15/2019</td>
              </tr>
              <tr>
                <td>38</td>
                <td>10/8/2019</td>
                <td>10/9/2019</td>
                <td>10/15/2019</td>
                <td>10/16/2019</td>
              </tr>
              <tr>
                <td>39</td>
                <td>10/9/2019</td>
                <td>10/10/2019</td>
                <td>10/16/2019</td>
                <td>10/17/2019</td>
              </tr>
              <tr>
                <td>40</td>
                <td>10/10/2019</td>
                <td>10/11/2019</td>
                <td>10/17/2019</td>
                <td>10/18/2019</td>
              </tr>
              <tr>
                <td>41</td>
                <td>10/11/2019</td>
                <td>10/12/2019</td>
                <td>10/18/2019</td>
                <td>10/21/2019</td>
              </tr>
              <tr>
                <td>42</td>
                <td>10/12/2019</td>
                <td>10/13/2019</td>
                <td>10/19/2019</td>
                <td>10/21/2019</td>
              </tr>
              <tr>
                <td>43</td>
                <td>10/13/2019</td>
                <td>10/14/2019</td>
                <td>10/21/2019</td>
                <td>10/22/2019</td>
              </tr>
              <tr>
                <td>44</td>
                <td>10/14/2019</td>
                <td>10/15/2019</td>
                <td>10/21/2019</td>
                <td>10/22/2019</td>
              </tr>
              <tr>
                <td>45</td>
                <td>10/15/2019</td>
                <td>10/16/2019</td>
                <td>10/22/2019</td>
                <td>10/23/2019</td>
              </tr>
              <tr>
                <td>46</td>
                <td>10/16/2019</td>
                <td>10/17/2019</td>
                <td>10/23/2019</td>
                <td>10/24/2019</td>
              </tr>
              <tr>
                <td>47</td>
                <td>10/17/2019</td>
                <td>10/18/2019</td>
                <td>10/24/2019</td>
                <td>10/25/2019</td>
              </tr>
              <tr>
                <td>48</td>
                <td>10/18/2019</td>
                <td>10/19/2019</td>
                <td>10/25/2019</td>
                <td>10/28/2019</td>
              </tr>
              <tr>
                <td>49</td>
                <td>10/19/2019</td>
                <td>10/20/2019</td>
                <td>10/26/2019</td>
                <td>10/28/2019</td>
              </tr>
              <tr>
                <td>50</td>
                <td>10/20/2019</td>
                <td>10/21/2019</td>
                <td>10/28/2019</td>
                <td>10/29/2019</td>
              </tr>
              <tr>
                <td>51</td>
                <td>10/21/2019</td>
                <td>10/22/2019</td>
                <td>10/28/2019</td>
                <td>10/29/2019</td>
              </tr>
              <tr>
                <td>52</td>
                <td>10/22/2019</td>
                <td>10/23/2019</td>
                <td>10/29/2019</td>
                <td>10/30/2019</td>
              </tr>
              <tr>
                <td>53</td>
                <td>10/23/2019</td>
                <td>10/24/2019</td>
                <td>10/30/2019</td>
                <td>10/31/2019</td>
              </tr>
              <tr>
                <td>54</td>
                <td>10/24/2019</td>
                <td>10/25/2019</td>
                <td>10/31/2019</td>
                <td>11/1/2019</td>
              </tr>
              <tr>
                <td>55</td>
                <td>10/25/2019</td>
                <td>10/26/2019</td>
                <td>11/1/2019</td>
                <td>11/4/2019</td>
              </tr>
              <tr>
                <td>56</td>
                <td>10/26/2019</td>
                <td>10/27/2019</td>
                <td>11/2/2019</td>
                <td>11/4/2019</td>
              </tr>
              <tr>
                <td>57</td>
                <td>10/27/2019</td>
                <td>10/28/2019</td>
                <td>11/4/2019</td>
                <td>11/5/2019</td>
              </tr>
              <tr>
                <td>58</td>
                <td>10/28/2019</td>
                <td>10/29/2019</td>
                <td>11/4/2019</td>
                <td>11/5/2019</td>
              </tr>
              <tr>
                <td>59</td>
                <td>10/29/2019</td>
                <td>10/30/2019</td>
                <td>11/5/2019</td>
                <td>11/6/2019</td>
              </tr>
              <tr>
                <td>60</td>
                <td>10/30/2019</td>
                <td>10/31/2019</td>
                <td>11/6/2019</td>
                <td>11/7/2019</td>
              </tr>
              <tr>
                <td>61</td>
                <td>10/31/2019</td>
                <td>11/1/2019</td>
                <td>11/7/2019</td>
                <td>11/8/2019</td>
              </tr>
              <tr>
                <td>62</td>
                <td>11/1/2019</td>
                <td>11/2/2019</td>
                <td>11/8/2019</td>
                <td>11/11/2019</td>
              </tr>
              <tr>
                <td>63</td>
                <td>11/2/2019</td>
                <td>11/3/2019</td>
                <td>11/9/2019</td>
                <td>11/11/2019</td>
              </tr>
              <tr>
                <td>64</td>
                <td>11/3/2019</td>
                <td>11/4/2019</td>
                <td>11/11/2019</td>
                <td>11/12/2019</td>
              </tr>
              <tr>
                <td>65</td>
                <td>11/4/2019</td>
                <td>11/5/2019</td>
                <td>11/11/2019</td>
                <td>11/12/2019</td>
              </tr>
              <tr>
                <td>66</td>
                <td>11/5/2019</td>
                <td>11/6/2019</td>
                <td>11/12/2019</td>
                <td>11/13/2019</td>
              </tr>
              <tr>
                <td>67</td>
                <td>11/6/2019</td>
                <td>11/7/2019</td>
                <td>11/13/2019</td>
                <td>11/14/2019</td>
              </tr>
              <tr>
                <td>68</td>
                <td>11/7/2019</td>
                <td>11/8/2019</td>
                <td>11/14/2019</td>
                <td>11/15/2019</td>
              </tr>
              <tr>
                <td>69</td>
                <td>11/8/2019</td>
                <td>11/9/2019</td>
                <td>11/15/2019</td>
                <td>11/18/2019</td>
              </tr>
              <tr>
                <td>70</td>
                <td>11/9/2019</td>
                <td>11/10/2019</td>
                <td>11/16/2019</td>
                <td>11/18/2019</td>
              </tr>
              <tr>
                <td>71</td>
                <td>11/10/2019</td>
                <td>11/11/2019</td>
                <td>11/18/2019</td>
                <td>11/19/2019</td>
              </tr>
              <tr>
                <td>72</td>
                <td>11/11/2019</td>
                <td>11/12/2019</td>
                <td>11/18/2019</td>
                <td>11/19/2019</td>
              </tr>
              <tr>
                <td>73</td>
                <td>11/12/2019</td>
                <td>11/13/2019</td>
                <td>11/19/2019</td>
                <td>11/20/2019</td>
              </tr>
              <tr>
                <td>74</td>
                <td>11/13/20119</td>
                <td>11/14/2019</td>
                <td>11/20/2019</td>
                <td>11/21/2019</td>
              </tr>
              <tr>
                <td>75</td>
                <td>11/14/2019</td>
                <td>11/15/2019</td>
                <td>11/21/2019</td>
                <td>11/22/2019</td>
              </tr>
              <tr>
                <td>76</td>
                <td>11/15/2019</td>
                <td>11/16/2019</td>
                <td>11/22/2019</td>
                <td>11/25/2019</td>
              </tr>
              <tr>
                <td>77</td>
                <td>11/16/2019</td>
                <td>11/17/2019</td>
                <td>11/25/2019</td>
                <td>11/26/2019</td>
              </tr>
              <tr>
                <td>78</td>
                <td>11/17/2019</td>
                <td>11/18/2019</td>
                <td>11/25/2019</td>
                <td>11/26/2019</td>
              </tr>
              <tr>
                <td>79</td>
                <td>11/18/2019</td>
                <td>11/19/2019</td>
                <td>11/25/2019</td>
                <td>11/26/2019</td>
              </tr>
              <tr>
                <td>80</td>
                <td>11/19/2019</td>
                <td>11/20/2019</td>
                <td>11/26/2019</td>
                <td>11/27/2019</td>
              </tr>
              <tr>
                <td>81</td>
                <td>11/20/2019</td>
                <td>11/21/2019</td>
                <td>11/27/2019</td>
                <td>11/28/2019</td>
              </tr>
              <tr>
                <td>82</td>
                <td>11/21/2019</td>
                <td>11/22/2019</td>
                <td>11/28/2019</td>
                <td>11/29/2019</td>
              </tr>
              <tr>
                <td>83</td>
                <td>11/22/2019</td>
                <td>11/23/2019</td>
                <td>11/29/2019</td>
                <td>12/2/2019</td>
              </tr>
              <tr>
                <td>84</td>
                <td>11/23/2019</td>
                <td>11/24/2019</td>
                <td>12/2/2019</td>
                <td>12/3/2019</td>
              </tr>
              <tr>
                <td>85</td>
                <td>11/24/2019</td>
                <td>11/25/2019</td>
                <td>12/2/2019</td>
                <td>12/3/2019</td>
              </tr>
              <tr>
                <td>86</td>
                <td>11/25/2019</td>
                <td>11/26/2019</td>
                <td>12/2/2019</td>
                <td>12/3/2019</td>
              </tr>
              <tr>
                <td>87</td>
                <td>11/26/2019</td>
                <td>11/27/2019</td>
                <td>12/3/2019</td>
                <td>12/4/2019</td>
              </tr>
              <tr>
                <td>88</td>
                <td>11/27/2019</td>
                <td>11/28/2019</td>
                <td>12/4/2019</td>
                <td>12/5/2019</td>
              </tr>
              <tr>
                <td>89</td>
                <td>11/28/2019</td>
                <td>11/29/2019</td>
                <td>12/5/2019</td>
                <td>12/6/2019</td>
              </tr>
              <tr>
                <td>90</td>
                <td>11/29/2019</td>
                <td>11/30/2019</td>
                <td>12/6/2019</td>
                <td>12/9/2019</td>
              </tr>
            </tbody>
          </table>
        </div>
        <p>
          Then, on or about December 9, 2019, one (1) Grand Prize Winner will be
          selected by a random drawing, to be conducted by Administrator from
          among all eligible grand prize pool entries received during the
          Sweepstakes Period. The potential Grand Prize Winner will each be
          notified by email on or about the day of the applicable random drawing
          and will have seventy-two (72) hours from the time the Grand Prize
          Winner notification is sent by Administrator to respond. In the event
          potential Grand Prize Winner fails to respond to the Winner
          notification with seventy-two (72) hours, he/she will forfeit his/her
          right to the Grand Prize and that Prize may, at Sponsor’s sole
          discretion, be awarded to an alternate Winner, time permitting. The
          Grand Prize, First Prize and Second Prize winners will collectively be
          referred to herein as “Winners.”
        </p>
        <p>
          Upon receiving the Winner notification response, Administrator may
          send each potential Winner an Affidavit of Eligibility and Publicity
          (where legal) and Liability Release (if a United States resident) OR a
          Declaration of Eligibility and Liability Release (if a Canadian
          resident), which will need to be completed, signed and returned to
          Administrator within seven (7) days of receipt of the Release. If a
          resident of Canada, the potential winner must also correctly answer a
          time-based mathematical skill-testing question without assistance of
          any kind prior to and as a condition of being declared a winner. If
          any Prize notification or attempted notification is returned as
          undeliverable, that Prize may be forfeited and awarded to an alternate
          Winner, time permitting. Administrator and Sponsor are not responsible
          for lost, late, damaged, delayed, or illegible paperwork. By signing
          the Release, Winners acknowledge compliance with these Official Rules.
          Odds of winning a Prize depend upon the number of eligible entries
          received in each applicable entry pool.
        </p>
      </li>
      <li>
        <strong>CONDITIONS FOR AWARDING PRIZE:</strong> Winners must meet all
        eligibility requirements. In the event a potential Winner is deemed
        ineligible, or is not available to claim a Prize during the specified
        dates, then an alternate Winner may be selected at random from eligible
        entries received, time permitting.
      </li>
      <li>
        <p>
          <strong>Prizes and Approximate Retail Values (“ARVS”):</strong>{' '}
          <strong>One (1) Grand Prize Winner -</strong> Gears 5 x Rockstar
          Themed 2019 Ford F-150 Raptor truck (“Truck”). ARV: $90,000 USD. In
          the event that awarding the Prize results in a taxable event, the
          Grand Prize Winner will also receive a cash payment in the amount
          necessary to pay or offset any income taxes applicable to the Prize
          (not to exceed $25,000 USD). If the Grand Prize Winner forfeits the
          Truck, then the Grand Prize Winner must also forfeit the cash payment
          portion. The cash payment is only available to pay or offset income
          taxes of the Grand Prize Winner and will not be paid in any other
          instance. If the Grand Prize Winner accepts the Truck as the Grand
          Prize, then the Grand Prize Winner must accept delivery of Grand Prize
          from Sponsor, or as otherwise instructed by Sponsor. All other costs
          related to prize delivery and acceptance are the responsibility of
          Grand Prize Winner including but not limited to excess taxes, title,
          insurance, license, and registration fees. Grand Prize Winner will be
          required to complete and return a W-9 form, affidavit of eligibility
          and liability/publicity release, and present a valid driver’s license
          and may also be required to present his/her social security card
          before prize will be awarded. Grand Prize Winner will be issued a 1099
          form reflecting the value of the prize and are responsible for paying
          all income taxes on the Grand Prize. The Grand Prize Winner agrees to
          comply with all applicable laws and regulations regarding vehicle
          ownership, including, but not limited to, those dealing with the
          registration, licensing and insuring of a vehicle. Vehicle described,
          displayed and/or pictured may not be the actual Truck awarded. Grand
          Prize Winner hereby represents, and Sponsor and the other sponsors
          rely upon such representation, that the Grand Prize Winner (a) is
          familiar with and has knowledge of the risks, dangers, hazards and
          perils involved in the ownership and/or use of a vehicle, (b)
          understands and acknowledges that the ownership and/or use of a
          vehicle may be dangerous and may expose the owner and/or user to
          injury and liability, and (c) hereby fully assumes any and all
          responsibilities, liabilities and risks arising from, related to, or
          connected with the ownership and/or use of the vehicle from the time
          the vehicle is delivered to or picked up by winner, including, without
          limitation, serious injury, temporary or permanent disability and
          death, regardless of the causes. WINNER ON BEHALF OF HIMSELF OR
          HERSELF, AS WELL AS HIS OR HER HEIRS AND ASSIGNS, VOLUNTARILY ELECTS
          TO ACCEPT ALL RISKS arising from, related to, or connected with the
          ownership and/or use of the vehicle from the time the vehicle is
          delivered to or picked up by the Grand Prize Winner.
        </p>
        <p>
          <strong>Ninety (90) First Prizes</strong> (one (1) winner per Daily
          Entry Pool) – An Xbox One X Gears 5 edition gaming console and a Gears
          5 Xbox One game. ARV: $500.00 USD each.{' '}
          <strong>Four-hundred fifty (450) Second Prizes</strong> (five (5)
          winners per Daily Entry Pool) – A Gears 5 Xbox Game. ARV: $60.00 USD
          each. Any and all guarantees and warranties for the First and Second
          Prize are subject to the manufacturer’s terms and conditions, and the
          winner agrees to look solely to such manufacturers for any such
          warranty or guarantee claim.
        </p>
        <p>
          <strong>Total ARV of all prizes is $162,000.00 USD.</strong>
        </p>
      </li>
      <li>
        <p>
          <strong>PRIVACY:</strong> Promotion Entities and their authorized
          agents will collect, use, and disclose the personal information you
          provide when you enter the Promotion solely for the purposes of
          administering the Promotion and fulfilling prizes, and by entering
          this Promotion you consent to the collection, use, disclosure and
          management of your personal information for said purposes. All
          personal information that Entrants submit will be subject to Sponsor’s
          privacy policy. In the event of any discrepancy between Sponsor’s
          privacy policy and these Official Rules, the privacy policy will
          govern and control. For a copy of Sponsor’s complete online privacy
          policy please visit{' '}
          <a
            href="http://rockstarenergy.com/company/privacy"
            target="_blank"
            rel="noreferrer noopener"
          >
            http://rockstarenergy.com/company/privacy
          </a>
          . Information collected by Sponsor in connection with this promotion
          will be maintained on servers located in the United States.
        </p>
      </li>
      <li>
        <p>
          <strong>QUEBEC RESIDENTS LITIGATION/DISPUTE RESOLUTION.</strong> Any
          litigation respecting the conduct or organization of a publicity
          contest may be submitted to the Régie for a ruling. Any litigation
          respecting the awarding of a prize may be submitted to the Régie only
          for the purpose of helping the parties reach a settlement.
        </p>
      </li>
      <li>
        <p>
          <strong>Entry Conditions and Release:</strong> By entering, each
          entrant agrees to: (a) comply with and be bound by these Official
          Rules and the decisions of the Sponsor which are binding and final in
          all matters relating to this Sweepstakes; (b) release and hold
          harmless the Sweepstakes Entities from and against any and all claims,
          expenses, and liability, including but not limited to negligence and
          damages of any kind to persons and property, including but not limited
          to invasion of privacy (under appropriation, intrusion, public
          disclosure of private facts, false light in the public eye or other
          legal theory), defamation, slander, libel, violation of right of
          publicity, infringement of trademark, copyright or other intellectual
          property rights, property damage, or death or personal injury arising
          out of or relating to a participant's entry, creation of an entry or
          submission of an entry, participation in the Sweepstakes, the
          Submission, acceptance or use or misuse of prize  and/or the
          broadcast, exploitation or use of entry or the Submission; and (c)
          indemnify, defend and hold harmless the Sweepstakes Entities from and
          against any and all claims, expenses, and liabilities (including
          reasonable attorney’s fees) arising out of or relating to an entrant's
          participation in the Sweepstakes and/or entrant's acceptance, use or
          misuse of prize or any portion thereof.
        </p>
      </li>
      <li>
        <p>
          <strong>General Conditions:</strong> Sponsor reserves the right to
          cancel, suspend and/or modify the Sweepstakes, or any part of it, for
          any reason whatsoever, including, without limitation, fire, flood,
          natural or man-made epidemic of health of other means, earthquake,
          explosion, labor dispute or strike, act of God or public enemy,
          satellite or equipment failure, riot or civil disturbance, terrorist
          threat or activity, war (declared or undeclared) or any federal state
          or local government law, order, or regulation, public health crisis,
          order of any court or jurisdiction or if any fraud, technical failures
          or any other factor beyond Sponsor's reasonable control impairs the
          integrity or proper functioning of the Sweepstakes, as determined by
          Sponsor in its sole discretion. If the Sweepstakes is terminated
          before the designated end date, Sponsor will (if possible) select the
          winner by random drawing from all eligible, non-suspect entries
          received as of the date of the event giving rise to the termination. 
          Inclusion in such random drawing shall be each entrant’s sole and
          exclusive remedy under such circumstances.  Sponsor reserves the right
          in its sole discretion to disqualify any individual it finds to be
          tampering with the entry process or the operation of the Sweepstakes
          or to be acting in violation of these Official Rules or any other
          promotion or in an unsportsmanlike or disruptive manner. Any attempt
          by any person to deliberately undermine the legitimate operation of
          the Sweepstakes may be a violation of criminal and civil law, and,
          should such an attempt be made, Sponsor reserves the right to seek
          damages from any such person to the fullest extent permitted by law.
          Only the type and quantity of prizes described in these Official Rules
          will be awarded.  The invalidity or unenforceability of any provision
          of these rules shall not affect the validity or enforceability of any
          other provision.  In the event that any provision is determined to be
          invalid or otherwise unenforceable or illegal, these rules shall
          otherwise remain in effect and shall be construed in accordance with
          their terms as if the invalid or illegal provision were not contained
          herein. Sponsor's failure to enforce any term of these Official Rules
          shall not constitute a waiver of that provision.
        </p>
      </li>
      <li>
        <p>
          <strong>Limitations of Liability:</strong> The Sweepstakes Entities as
          set forth above are not responsible for: (1) any incorrect or
          inaccurate information, whether caused by entrants, printing errors or
          by any of the equipment or programming associated with or utilized in
          the Sweepstakes; (2) technical failures of any kind, including, but
          not limited to malfunctions, interruptions, or disconnections in phone
          lines or network hardware or software; (3) unauthorized human
          intervention in any part of the entry process or the Sweepstakes; (4)
          technical or human error which may occur in the administration of the
          Sweepstakes or the processing of entries; or (5) any injury or damage
          to persons or property which may be caused, directly or indirectly, in
          whole or in part, from entrant's participation in the Sweepstakes or
          receipt or use or misuse of any Prize. If for any reason an entry is
          confirmed to have been erroneously deleted, lost, or otherwise
          destroyed or corrupted, entrant's sole remedy is another entry in the
          Sweepstakes, provided that if it is not possible to award another
          entry due to discontinuance of the Sweepstakes, or any part of it, for
          any reason, Sponsor, at its discretion, may elect to hold judging from
          among all eligible entries received up to the date of discontinuance
          for any or all of the Prizes offered herein. Entrant (or entrant’s
          parent/legal guardian, if entrant is a minor in the state of his/her
          residence) further agrees and acknowledges that Sponsor reserves the
          right to forfeit or award any unclaimed or leftover Prize at its sole
          discretion.
        </p>
      </li>
      <li>
        <p>
          <strong>Disputes/Governing Law:</strong> Entrant agrees that: (i) any
          and all disputes, claims and causes of action arising out of or
          connected with this Sweepstakes, other than those concerning the
          administration of the Sweepstakes or the determination of winner or
          for any disputes arising from the loss or injury from the
          participation in a Prize, shall be resolved individually, without
          resort to any form of class action; (ii) any disputes arising out of
          these Official Rules (except for any disputes arising from the loss or
          injury from the use of Prizes) shall be submitted to final, binding
          arbitration conducted in Nevada, under the Arbitration Rules and
          Procedures of the Judicial Arbitration and Mediation Services Inc.
          before a single, neutral arbitrator who is a former or retired Nevada
          state or federal court judge with experience in entertainment matters
          who shall follow Nevada law and the Federal Rules of Evidence and have
          no authority to award punitive damages. Either party may enforce a
          final arbitration award in any court of competent jurisdiction in
          Nevada, including an award of costs, fees and expenses incurred in
          enforcing the award. Notwithstanding the foregoing, Sweepstakes
          Entities shall be entitled to seek injunctive relief (unless otherwise
          precluded by any other provision of these Official Rules) in the state
          and federal courts of Nevada. Any dispute or portion thereof, or any
          claim for a particular form of relief (not otherwise precluded by any
          other provision of these Official Rules), that may not be arbitrated
          pursuant to applicable state or federal law may be heard only in a
          court of competent jurisdiction in Nevada; (iii) any and all claims,
          judgments and awards shall be limited to actual out-of- pocket costs
          incurred, including costs associated with entering this Sweepstakes,
          but in no event attorneys' fees; and (iv) under no circumstances will
          entrant be permitted to obtain awards for, and entrant hereby waives
          all rights to claim punitive, incidental and consequential damages and
          any other damages, other than for actual out- of-pocket expenses, and
          any and all rights to have damages multiplied or otherwise increased.
          SOME JURISDICTIONS DO NOT ALLOW THE LIMITATIONS OR EXCLUSION OF
          LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE MAY
          NOT APPLY TO YOU. All issues and questions concerning the
          construction, validity, interpretation and enforceability of these
          Official Rules, or the rights and obligations of the entrant and
          Sponsor in connection with the Sweepstakes, shall be governed by, and
          construed in accordance with, the laws of the State of Nevada, without
          giving effect to any choice of law or conflict of law rules (whether
          of the State of Nevada, or any other jurisdiction), which would cause
          the application of the laws of any jurisdiction other than the State
          of Nevada.
        </p>
      </li>
      <li>
        <p>
          <strong>Sweepstakes Winners:</strong> For the Sweepstakes Winner’s
          name (available after December 9, 2019), send a hand-printed,
          self-addressed, stamped envelope by December 6, 2019 to: Winners List,
          Rockstar Gears of War 5 Sweepstakes, Brandmovers, Inc., 590 Means
          Street, Suite 250, Atlanta, GA 30318.
        </p>
      </li>
      <li>
        <p>
          <strong>Official Rules:</strong> For a copy of these Official Rules
          send a hand-printed, self-addressed, stamped envelope by December 6,
          2019 to: Official Rules – Rockstar Gears of War 5 Sweepstakes,
          Brandmovers, Inc., 590 Means Street, Suite 250, Atlanta, GA 30318. A
          copy of the Rules may also be obtained by printing this Website.
        </p>
      </li>
      <li>
        <p>
          <strong>Sponsor:</strong> Rockstar, Inc., 101 Convention Center Dr.,
          Suite 777, Las Vegas, NV 89109.
          <br />
          <strong>Administrator:</strong> Brandmovers, Inc., 590 Means Street,
          Suite 250, Atlanta, GA 30318.
        </p>
      </li>
    </ol>

    <p>Microsoft is not a sponsor of this Promotion.</p>
  </div>
);

export default RulesUs;
