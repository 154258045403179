import React from 'react';

const TermsGb = () => (
  <div>
    {/*
    <h4>GENERAL</h4>

    <ul>
      <li>Rewards are available to redeem starting September 1, 2019 </li>
      <li>Gears 5 releases World-Wide on September 10, 2019</li>
      <li>Ring pull codes for rewards may be entered until March 30, 2020</li>
    </ul>
    */}

    <h4>XBOX GAME PASS</h4>

    <ul>
      <li>
        First ring pull code unlocks 7-days of Xbox Game Pass for Console
        Membership
      </li>
      <li>
        Second ring pull code unlocks additional 7-days of Xbox Game Pass for
        Console Membership
      </li>
      <li>7-day Xbox Game Pass for Console Memberships start 6/9/19</li>
      <li>
        After Game Pass trial ends, in game items and boost(s) require Gears 5
        game or Xbox Game Pass membership (each sold separately).
      </li>
      <li>
        For existing Game Pass subscribers, each ring pull code unlocks 7-days
        of membership credit to a subscriber’s account (14 days maximum with 2
        ring pull codes).
      </li>
      <li>Game Pass catalog varies over time.</li>
      <li>
        Codes for Xbox Game Pass for Console may be redeemed until 30/3/20 by
        visiting Microsoft.com/redeem.
      </li>
    </ul>

    <h4>XBOX LIVE GOLD</h4>

    <ul>
      <li>First ring pull code unlocks 7-days of Xbox Live Gold Membership.</li>
      <li>
        Second ring pull code unlocks additional 7-days of Xbox Live Gold
        Membership.
      </li>
      <li>7-day Xbox Live Gold Membership starts 6/9/19.</li>
      <li>
        After Xbox Live Gold trial ends, boost(s) also require Xbox Live Gold
        membership (sold separately).
      </li>
      <li>
        For existing Xbox Live Gold subscribers, each ring pull code adds 7-days
        of credit to a subscriber’s existing account (14 days maximum with 2
        ring pull codes)
      </li>
      <li>
        Codes for Xbox Live Gold may be redeemed until 30/3/20 by visiting
        Microsoft.com/redeem.
      </li>
    </ul>

    <h4>IN-GAME ITEMS</h4>

    <ul>
      <li>
        • Lancers, Banners, Supply Drops and Boost are earned with the
        redemption of ring pull codes. See in-game reward offerings at
        Gears.Rockstarenergy.com
      </li>
      <li>
        Unlocking any in-game item within Gears 5 requires the Gears 5 game or
        Xbox Game Pass membership (each sold separately). Boost(s) require Xbox
        Live Gold membership (sold separately).
      </li>
      <li>
        Lancers, Banners, and Supply Drops are timed exclusives and may be
        available in future offers
      </li>
      <li>
        Codes for in-game items may be redeemed until 30/3/20 by visiting
        Microsoft.com/redeem.
      </li>
    </ul>
  </div>
);

export default TermsGb;
