import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, defineMessages } from 'react-intl';
import { injectIntl, intlShape } from 'gatsby-plugin-intl';

import FooterStyled from './Footer.style';
import logoOblivion from '../../images/logo-oblivion.svg';

import ModalController from '../ModalController/ModalController';
import ModalSupport from '../ModalSupport/ModalSupport';
import ModalRules from '../ModalRules/ModalRules';
import ModalTerms from '../ModalTerms/ModalTerms';
import ModalPrivacy from '../ModalPrivacy/ModalPrivacy';
import CookieDisclaimer from '../CookieDisclaimer/CookieDisclaimer';

import config from './config';

const messages = defineMessages({
  support: {
    id: 'footer.support',
    defaultMessage: 'SUPPORT',
    description: 'Support',
  },
  rules: {
    id: 'footer.rules',
    defaultMessage: 'RULES',
    description: 'Rules',
  },
  terms: {
    id: 'footer.terms',
    defaultMessage: 'TERMS',
    description: 'Terms',
  },
  privacy: {
    id: 'footer.privacy',
    defaultMessage: 'PRIVACY',
    description: 'Privacy',
  },
  shortRules: {
    id: 'footer.shortRules',
    defaultMessage:
      'Promotion open to 50 U.S. States & DC & Canada who are age of majority+.  Sweeps begins 9/1/19 at 12:00:00 PM ET; ends 11/30/19 at 11:59:59 AM ET.  Sweepstakes prizes are a chance to win.  Sponsor:  Rockstar, Inc.  Microsoft, Gears of War and Xbox One are trademarks of Microsoft Group of companies.  Microsoft and Ford are not sponsors of this promotion.  Xbox Game Pass & Xbox Live Gold tokens offer up to 14-days of membership starting 9/6/19.  Lancers, Banners and Supply Drops are timed exclusives; may be available in future offers.  After Game pass trial ends, in-game items and boosts require Gears 5 game or Xbox Game Pass membership (sold separately).  Boost(s) also require Xbox Live Gold, sold separately after Xbox Live Gold trial(s) ends.  Game Pass catalog varies over time.  Codes for in-game items may be redeemed until 3/30/20 from Microsoft.com/redeem.',
    description: 'shortRules Description',
  },
  powered: {
    id: 'footer.powered',
    defaultMessage: 'powered by',
    description: 'Powered By',
  },
});

const Footer = ({ hideLinks, intl }) => {
  const localeConfig = config[intl.locale] || config[Object.keys(config)[0]];

  const logoLinks = localeConfig.logoLinks.map((link, index) => (
    <a
      className="Footer__logoLink"
      href={link.url}
      rel="noopener noreferrer"
      target="_blank"
      key={index.toString()}
    >
      <img src={link.src} alt={link.alt} />
    </a>
  ));

  const isShowRules = !!(intl.locale === 'en-US' || intl.locale === 'en-CA');

  return (
    <FooterStyled>
      <div className="Footer__inner">
        <div className="Footer__links">
          {!hideLinks && (
            <Fragment>
              <ModalController>
                {(isOpen, handleToggle) => (
                  <Fragment>
                    <button
                      className="Footer__link"
                      type="button"
                      onClick={handleToggle}
                    >
                      <FormattedMessage {...messages.support} />
                    </button>

                    {isOpen && <ModalSupport onClose={handleToggle} />}
                  </Fragment>
                )}
              </ModalController>

              <span className="Footer__linkDivider" />

              <ModalController>
                {(isOpen, handleToggle) => (
                  <Fragment>
                    <button
                      className="Footer__link"
                      type="button"
                      onClick={handleToggle}
                    >
                      <FormattedMessage {...messages.rules} />
                    </button>

                    {isOpen && <ModalRules onClose={handleToggle} />}
                  </Fragment>
                )}
              </ModalController>

              <span className="Footer__linkDivider" />

              <ModalController>
                {(isOpen, handleToggle) => (
                  <Fragment>
                    <button
                      className="Footer__link"
                      type="button"
                      onClick={handleToggle}
                    >
                      <FormattedMessage {...messages.terms} />
                    </button>

                    {isOpen && <ModalTerms onClose={handleToggle} />}
                  </Fragment>
                )}
              </ModalController>

              <span className="Footer__linkDivider" />

              <ModalController>
                {(isOpen, handleToggle) => (
                  <Fragment>
                    <button
                      className="Footer__link"
                      type="button"
                      onClick={handleToggle}
                    >
                      <FormattedMessage {...messages.privacy} />
                    </button>

                    {isOpen && <ModalPrivacy onClose={handleToggle} />}
                  </Fragment>
                )}
              </ModalController>
            </Fragment>
          )}
        </div>

        {!hideLinks && isShowRules && (
          <div className="Footer__rules">
            <p>
              <FormattedMessage {...messages.shortRules} />
            </p>
          </div>
        )}

        <div className="Footer__logos">{logoLinks}</div>

        <div className="Footer__powered">
          <a
            className="Footer__poweredLink"
            href="http://oblivion.live"
            rel="noopener noreferrer"
            target="_blank"
          >
            <FormattedMessage {...messages.powered} />
            <img src={logoOblivion} alt="Oblivion" />
          </a>
        </div>
      </div>

      <CookieDisclaimer />
    </FooterStyled>
  );
};

Footer.propTypes = {
  hideLinks: PropTypes.bool,
  intl: intlShape.isRequired,
};

Footer.defaultProps = {
  hideLinks: false,
};

export default injectIntl(Footer);
