import React from 'react';

const PrivacyUs = () => (
  <div>
    <h2>
      Rockstar – Gears 5 Gewinnspiel Einwilligungserklärung für die Übermittlung
      personenbezogener Daten in die USA
    </h2>
    <p>
      [ ] Ich erkläre hiermit meine Einwilligung zur Übermittlung meiner
      personenbezogenen Daten an die Rockstar, Inc, PO Box 27740, Las Vegas, NV
      89126, USA, als für den Datenschutz verantwortliche Stelle für das
      Rockstar – Gears 5 Gewinnspiel, das in der Zeit vom 1. September 2017 bis
      27. Oktober 2017 stattfindet.
    </p>
    <p>
      Diese Daten betreffen meinen Namen, E-Mail-Adresse sowie meine
      Handynummer, die für die Registrierung zur Teilnahme am Rockstar - Gears 5
      Gewinnspiel unter http://gears.rockstarenergy.com/de einzugeben sind.
    </p>
    <p>
      Mir ist bekannt, dass ein in den USA im Vergleich zu den EU-Ländern
      vergleichbares angemessenes Datenschutzniveau nicht gewährleistet ist. Die
      Daten werden gleichwohl nur zu dem Zweck verarbeitet oder genutzt, zu
      dessen Erfüllung sie übermittelt werden.
    </p>
    <p>
      Mir ist ebenfalls bekannt, dass ich diese Zustimmungserklärung jederzeit
      frei widerrufen kann. In diesem Fall ist meine (weitere) Teilnahme am
      Gewinnspiel nicht möglich.
    </p>
    <p style={{ textAlign: 'center' }}>*****************</p>
  </div>
);

export default PrivacyUs;
