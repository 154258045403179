import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Slider from 'react-slick';
import FsLightbox from 'fslightbox-react';

import arrowUrl from './images/icon_sliderArrow.svg';

const defaultSettings = {
  speed: 500,
  centerPadding: 0,
  slidesToShow: 1,
  arrows: true,
  dots: true,
  variableWidth: true,
  centerMode: false,
  infinite: false,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        arrows: false,
      },
    },
  ],
};

const CarouselComponent = ({ settings, slides, ...props }) => {
  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    slide: 0,
  });

  function openLightboxOnSlide(number) {
    setLightboxController({
      toggler: !lightboxController.toggler,
      slide: number,
    });
  }

  const newSettings = { ...defaultSettings, ...settings };
  const items = slides.map((slide, index) => (
    <div key={slide.src}>
      {slide.link ? (
        <a {...slide.link}>
          <img src={slide.src} alt={slide.alt} />
        </a>
      ) : (
        <img
          src={slide.src}
          alt={slide.alt}
          onClick={() => openLightboxOnSlide(index + 1)}
        />
      )}
      {slide.title && <div className="Slider__title">{slide.title}</div>}
      {slide.desc && <div className="Slider__desc">{slide.desc}</div>}
      {slide.overlay && <div className="Slider__overlay">{slide.overlay}</div>}
    </div>
  ));

  const lightboxSlides = slides.map(slide =>
    slide.lightboxSrc ? slide.lightboxSrc : slide.src,
  );

  return (
    <Fragment>
      <Slider {...newSettings} {...props}>
        {items}
      </Slider>

      <FsLightbox
        toggler={lightboxController.toggler}
        sources={lightboxSlides}
        slide={lightboxController.slide}
      />
    </Fragment>
  );
};

const Carousel = styled(CarouselComponent)`
  position: relative;
  display: block;
  overflow-x: hidden;
  user-select: none;
  touch-action: pan-y;

  .slick-list {
    position: relative;
    display: block;
    margin: 0;
    padding: 0;

    &:focus {
      outline: none;
    }

    &.dragging {
      cursor: pointer;
      cursor: hand;
    }
  }

  .slick-track,
  .slick-list {
    transform: translate3d(0, 0, 0);
  }

  .slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: block;

    &:before,
    &:after {
      display: table;
      content: '';
    }

    &:after {
      clear: both;
    }
  }

  .slick-loading .slick-track {
    visibility: hidden;
  }

  .slick-slide {
    position: relative;
    display: none;
    float: left;
    width: ${p => (p.customWidth ? p.customWidth[0] : 375)}px;
    height: 100%;
    min-height: 1px;
    margin: 0 ${p => p.customWidth && p.margin && 8}px;
    padding: 0 ${p => !p.customWidth && '30px'};

    @media (min-width: 768px) {
      width: ${p => (p.customWidth ? p.customWidth[1] : 447)}px;
    }

    @media (min-width: 1440px) {
      width: ${p => (p.customWidth ? p.customWidth[2] : 690)}px;
      padding: 0 ${p => !p.customWidth && '60px'};
    }

    img {
      display: inline-block;
      cursor: pointer;
    }

    &.slick-loading img {
      display: none;
    }

    &.dragging img {
      pointer-events: none;
    }
  }

  &[dir='rtl'] .slick-slide {
    float: right;
  }

  &.slick-initialized .slick-slide {
    display: block;
  }

  &.slick-loading .slick-slide {
    visibility: hidden;
  }

  &.slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
  }

  .slick-arrow.slick-hidden {
    display: none;
  }

  .slick-prev,
  .slick-next {
    position: absolute;
    top: 0;
    display: block;
    width: ${p => (p.customWidth && p.customWidth[0] < 100 ? 40 : 50)}px;
    height: ${p => (p.settings.dots ? 'calc(100% - 23px)' : '99%')};
    padding: 0;
    color: transparent;
    background-color: transparent;
    font-size: 0;
    line-height: 0;
    cursor: pointer;
    border: none;
    outline: none;
    z-index: 1;

    @media (min-width: 1440px) {
      height: ${p => (p.settings.dots ? 'calc(100% - 111px)' : '99%')};
    }

    &:hover,
    &:focus {
      outline: none;

      &::before {
        opacity: 0.8;
      }
    }

    &.slick-disabled::before {
      opacity: 0.25;
    }

    &::before {
      position: absolute;
      content: '';
      left: 0;
      width: ${p => (p.customWidth && p.customWidth[0] < 100 ? 40 : 50)}px;
      height: ${p => (p.customWidth && p.customWidth[0] < 100 ? 40 : 50)}px;
      margin-top: ${p =>
        p.customWidth && p.customWidth[0] < 100 ? -20 : -25}px;
      background-image: url(${arrowUrl});
      background-repeat: no-repeat;
      background-position: center;
      background-size: ${p =>
        p.customWidth && p.customWidth[0] < 100 ? 40 : 50}px;
      transition: opacity 150ms;
    }
  }

  .slick-prev {
    left: 0;
  }

  .slick-next {
    right: 0;

    &::before {
      transform: scaleX(-1);
    }
  }

  .slick-dots {
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;

    @media (min-width: 1440px) {
      padding: 16px 0;
    }

    li {
      position: relative;
      display: inline-block;
      margin: 0;
      padding: 0;
      cursor: pointer;

      &:not(:last-child) {
        margin-right: 5px;

        @media (min-width: 1440px) {
          margin-right: 8px;
        }
      }

      button {
        display: block;
        width: 10px;
        height: 10px;
        font-size: 0;
        line-height: 0;
        cursor: pointer;
        border: 1px solid #979797;
        border-radius: 50%;
        outline: none;
        background-color: transparent;

        @media (min-width: 1440px) {
          width: 14px;
          height: 14px;
        }

        &:hover {
          outline: none;
        }
      }

      &.slick-active {
        button {
          background-color: ${p => (p.light ? '#fff' : '#000')};
        }
      }
    }
  }

  .Slider__title {
    margin-top: 14px;
    font-family: 'AzbukaPro';
    font-size: 18px;
    font-weight: 900;
    line-height: 20px;
    text-align: center;
  }

  .Slider__desc {
    font-family: 'AzbukaPro';
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
  }

  .Slider__overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
`;

Carousel.propTypes = {
  settings: PropTypes.object,
  slides: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string.isRequired,
      alt: PropTypes.string,
      text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    }).isRequired,
  ),
  customWidth: PropTypes.arrayOf(PropTypes.number),
  margin: PropTypes.bool,
  light: PropTypes.bool,
};

Carousel.defaultProps = {
  settings: defaultSettings,
  customWidth: null,
  margin: true,
  light: false,
};

export default Carousel;
