import React from 'react';

const TermsUs = () => (
  <div>
    <h4>XBOX GAME PASS</h4>

    <ul>
      <li>
        First tab code unlocks 7-days of Xbox Game Pass for Console Membership
      </li>
      <li>
        Second tab code unlocks additional 7-days of Xbox Game Pass for Console
        Membership
      </li>
      <li>7-day Xbox Game Pass for Console Memberships start 9/6/19.</li>
      <li>
        After Game Pass trial ends, in game items and boost(s) require Gears 5
        game or Xbox Game Pass membership (each sold separately).
      </li>
      <li>
        For existing Game Pass subscribers, each tab code unlocks 7-days of
        membership credit to a subscriber’s account (14 days maximum with 2 tab
        codes).
      </li>
      <li>Game Pass catalog varies over time.</li>
      <li>
        Codes for Xbox Game Pass for Console may be redeemed until 3/30/20 by
        visiting Microsoft.com/redeem.
      </li>
    </ul>

    <h4>XBOX LIVE GOLD</h4>

    <ul>
      <li>First tab code unlocks 7-days of Xbox Live Gold Membership.</li>
      <li>
        Second tab code unlocks additional 7-days of Xbox Live Gold Membership.
      </li>
      <li>7-day Xbox Live Gold Membership starts 9/6/19.</li>
      <li>
        After Xbox Live Gold trial ends, boost(s) also require Xbox Live Gold
        membership (sold separately).
      </li>
      <li>
        For existing Xbox Live Gold subscribers, each tab code adds 7-days of
        credit to a subscriber’s existing account (14 days maximum with 2 tab
        codes)
      </li>
      <li>
        Codes for Xbox Live Gold may be redeemed until 3/30/20 by visiting
        Microsoft.com/redeem.
      </li>
    </ul>

    <h4>IN-GAME ITEMS</h4>

    <ul>
      <li>
        Lancers, Banners, Supply Drops and Boost are earned with the redemption
        of tab codes. See in-game reward offerings at{' '}
        <a
          href="http://gears.rockstarenergy.com"
          target="_blank"
          rel="noreferrer noopener"
        >
          Gears.Rockstarenergy.com
        </a>
      </li>
      <li>
        Unlocking any in-game item within Gears 5 requires the Gears 5 game or
        Xbox Game Pass membership (each sold separately). Boost(s) require Xbox
        Live Gold membership (sold separately).
      </li>
      <li>
        Lancers, Banners, and Supply Drops are timed exclusives and may be
        available in future offers
      </li>
      <li>
        Codes for in-game items may be redeemed until 3/30/20 by visiting{' '}
        <a
          href="https://microsoft.com/redeem"
          target="_blank"
          rel="noreferrer noopener"
        >
          Microsoft.com/redeem
        </a>
        .
      </li>
    </ul>
  </div>
);

export default TermsUs;
