import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, defineMessages } from 'react-intl';

import ModalSupportStyled from './ModalSupport.style';
import logoRs from '../../images/logo-rs.svg';
import logoXbox from '../../images/logo-xbox.png';

import Modal from '../Modal/Modal';
import ModalClose from '../ModalClose/ModalClose';

const messages = defineMessages({
  title1: {
    id: 'modalSupport.title1',
    defaultMessage: 'ROCKSTAR PROMO HELP',
  },
  title2: {
    id: 'modalSupport.title2',
    defaultMessage: 'XBOX REWARDS HELP',
  },

  button1: {
    id: 'modalSupport.button1',
    defaultMessage: 'VISIT ROCKSTAR SUPPORT',
  },
  button2: {
    id: 'modalSupport.button2',
    defaultMessage: 'VISIT XBOX SUPPORT',
  },

  desc1rs: {
    id: 'modalSupport.desc1rs',
    defaultMessage: 'Sign-Up/Log-In',
  },
  desc2rs: {
    id: 'modalSupport.desc2rs',
    defaultMessage: 'Can Tab Codes',
  },
  desc3rs: {
    id: 'modalSupport.desc3rs',
    defaultMessage: 'Prize Questions',
  },

  desc1xbox: {
    id: 'modalSupport.desc1xbox',
    defaultMessage: 'Xbox Game Pass',
  },
  desc2xbox: {
    id: 'modalSupport.desc2xbox',
    defaultMessage: 'Xbox Live Gold',
  },
  desc3xbox: {
    id: 'modalSupport.desc3xbox',
    defaultMessage: 'In-Game Items',
  },

  linkText: {
    id: 'modalSupport.linkText',
    defaultMessage: 'click here',
  },
});

class ModalSupport extends Component {
  render() {
    const { onClose } = this.props;

    return (
      <Modal>
        <ModalClose onClick={onClose} />
        <ModalSupportStyled>
          <div className="ModalSupport__blocks">
            <div className="ModalSupport__block">
              <div className="ModalSupport__logo">
                <img className="ModalSupport__logoImg" src={logoRs} alt="" />
              </div>

              <div className="ModalSupport__title">
                <FormattedMessage {...messages.title1} />
              </div>

              <ul className="ModalSupport__desc">
                <li className="ModalSupport__descItem">
                  <FormattedMessage {...messages.desc1rs} />
                </li>
                <li className="ModalSupport__descItem">
                  <FormattedMessage {...messages.desc2rs} />
                </li>
                <li className="ModalSupport__descItem">
                  <FormattedMessage {...messages.desc3rs} />
                </li>
              </ul>

              <a
                className="ModalSupport__link --rs"
                href="https://support.rockstarenergy.com/hc/en-us"
                target="_blank"
                rel="noreferrer noopener"
              >
                <FormattedMessage {...messages.button1} />
              </a>
            </div>

            <div className="ModalSupport__block">
              <div className="ModalSupport__logo">
                <img className="ModalSupport__logoImg" src={logoXbox} alt="" />
              </div>

              <div className="ModalSupport__title">
                <FormattedMessage {...messages.title2} />
              </div>

              <ul className="ModalSupport__desc">
                <li className="ModalSupport__descItem">
                  <FormattedMessage {...messages.desc1xbox} />{' '}
                  <a
                    className="ModalSupport__descLink"
                    href="https://support.xbox.com/en-US/billing/xbox-subscriptions/xbox-game-pass-faq"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <FormattedMessage {...messages.linkText} />
                  </a>
                </li>
                <li className="ModalSupport__descItem">
                  <FormattedMessage {...messages.desc2xbox} />{' '}
                  <a
                    className="ModalSupport__descLink"
                    href="https://support.xbox.com/en-US/browse/my-account/xbox-live-membership"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <FormattedMessage {...messages.linkText} />
                  </a>
                </li>
                <li className="ModalSupport__descItem">
                  <FormattedMessage {...messages.desc3xbox} />{' '}
                  <a
                    className="ModalSupport__descLink"
                    href="https://support.xbox.com/en-US/games/game-titles/gears-of-war-info#cefab4c8b81e4189bf820ffc63ab57ab"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <FormattedMessage {...messages.linkText} />
                  </a>
                </li>
              </ul>

              <a
                className="ModalSupport__link --xbox"
                href="https://support.xbox.com/en-US/"
                target="_blank"
                rel="noreferrer noopener"
              >
                <FormattedMessage {...messages.button2} />
              </a>
            </div>
          </div>
        </ModalSupportStyled>
      </Modal>
    );
  }
}

ModalSupport.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default ModalSupport;
