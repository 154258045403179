import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ModalControllerStyled from './ModalController.style';

class ModalController extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      index: null,
    };
  }

  handleToggle = index => {
    const { isOpen } = this.state;
    this.setState({ isOpen: !isOpen, index });
  };

  render() {
    const { children, ...rest } = this.props;
    const { isOpen, index } = this.state;

    return (
      <ModalControllerStyled {...rest}>
        {children(isOpen, idx => this.handleToggle(idx), index)}
      </ModalControllerStyled>
    );
  }
}

ModalController.propTypes = {
  children: PropTypes.func.isRequired,
};

export default ModalController;
