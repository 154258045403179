import React from 'react';

const TermsMx = () => (
  <div>
    <h3>Terminos de Recompensas</h3>

    <h4>XBOX GAME PASS</h4>

    <ul>
      <li>El primer código desbloquea 7-dias de membresía de Xbox Game Pass</li>
      <li>El segundo codigo desbloquea 7-dias adicionales de Xbox Game Pass</li>
      <li>
        Las membresías 7-dias Xbox Game Pass para consolas inicia el 06/09/19
      </li>
      <li>
        Despues que el plazo del Game Pass finalice, los contenidos del juego y
        el Boost necesitarán el juego Gears5 o La membresía de Xbox Game Pass
        (cada una se vende por separado)
      </li>
      <li>
        Para los suscriptores de Game Pass existentes, cada código desbloquea
        7-días de crédito de membresía a la cuenta de un suscriptor (14 días
        máximo con 2 tapas)
      </li>
      <li>El vencimiento de los Game Pass puede variar</li>
      <li>
        Los códigos para Xbox Game Pass para Consolas podrán registrarse hasta
        el 30/03/2019 visitando Microsoft.com/redeem.
      </li>
    </ul>

    <h4>XBOX LIVE GOLD</h4>

    <ul>
      <li>El primer codigo desbloquea 7-dias de membresía de Xbox Live Gold</li>
      <li>El segundo codigo desbloquea 7-dias adicionales de Xbox Live Gold</li>
      <li>Los 7-días de la membresía de Xbox Live inician el 06/09/19</li>
      <li>
        Despues que el plazo del Xbox Live Gold finalice, el “boost” requerirá
        una membresía de Xbox Live Gold (se vende por separado)
      </li>
      <li>
        Para los suscriptores Xbox Live Gold existentes, cada código de la tapa
        desbloquea 7-días de crédito de membresía en la cuenta de un suscriptor
        (14 días máximo con 2 tapas)
      </li>
      <li>
        Los códigos para Xbox Live Gold podrán registrarse hasta el 30/03/19
        visitando Microsoft.com/redeem.
      </li>
    </ul>

    <h4>IN-GAME ITEMS</h4>

    <ul>
      <li>
        Lancers, Banners, Supply Drops and Boost son obtenidos despues que
        registras el código de tus tapas. Para conocer mas acerca de los premios
        visita{' '}
        <a
          href="http://gears.rockstarenergy.com"
          target="_blank"
          rel="noreferrer noopener"
        >
          Gears.Rockstarenergy.com
        </a>
      </li>
      <li>
        Para desbloquear cualquier elemento del juego Gears 5, requerirás el
        juego Gears 5 o la membresía Xbox Game Pass (se vende por separado).
      </li>
      <li>
        Lancers, Banners y otros articulos del juego estarán disponibles por
        tiempo limitado y tambien en futuras ofertas
      </li>
      <li>
        Los códigos para obtener articulos en el juego podrán ser registrados
        hasta el 30/03/20 visitando Microsoft.com/redeem
      </li>
    </ul>
  </div>
);

export default TermsMx;
