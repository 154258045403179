import React from 'react';

const TermsDe = () => (
  <div>
    <h4>XBOX GAME PASS</h4>

    <ul>
      <li>1. Code schaltet 7 Tage Xbox Game Pass frei</li>
      <li>2. Code schaltet zusätzliche 7 Tage Xbox Game Pass frei</li>
      <li>7 Tage Xbox Game Pass Zugang startet am 6. September 2019</li>
      <li>
        Nach Ende des Game Pass Test-Zugangs benötigen die in-game items &
        Boosts das Gears 5 Spiel oder eine Xbox Game Pass Mitgliedschaft
        (separat erhältlich)
      </li>
      <li>
        Bei denjenigen, die bereits einen Xbox Game Pass besitzen, erweitert
        jeder Deckellaschen-Code die Mitgliedschaft um 7 Tage (Maximal 14 Tage
        mit 2 Codes)
      </li>
      <li>Game Pass Katalog variiert mit der Zeit</li>
      <li>
        Codes für den Xbox Game Pass für Konsole müssen bis zum 30. März 2020
        auf{' '}
        <a
          href="https://microsoft.com/redeem"
          target="_blank"
          rel="noreferrer noopener"
        >
          Microsoft.com/redeem
        </a>{' '}
        eingelöst werden
      </li>
    </ul>

    <h4>XBOX LIVE GOLD</h4>

    <ul>
      <li>1. Code schaltet 7 Tage Xbox Live Gold Mitgliedschaft frei</li>
      <li>
        2. Code schaltet zusätzliche 7 Tage Xbox Live Gold Mitgliedschaft frei
      </li>
      <li>7 Tage Xbox Live Gold Mitgliedschaft startet am 6. September 2019</li>
      <li>
        Nach Ende des Live Gold Test-Zugangs benötigen die in-game Boosts eine
        Xbox Live Gold Mitgliedschaft (separat erhältlich)
      </li>
      <li>
        Bei denjenigen, die bereits eine Xbox Live Gold Mitgliedschaft besitzen,
        erweitert jeder Deckellaschen-Code die Mitgliedschaft um 7 Tage (Maximal
        14 Tage mit 2 Codes)
      </li>
      <li>
        Codes für den Xbox Live Gold Zugang müssen bis zum 30. März 2020 auf{' '}
        <a
          href="https://microsoft.com/redeem"
          target="_blank"
          rel="noreferrer noopener"
        >
          Microsoft.com/redeem
        </a>{' '}
        eingelöst werden
      </li>
    </ul>

    <h4>IN-GAME ITEMS</h4>

    <ul>
      <li>
        Lancer, Banner, Supply Drops & Boosts werden über die Einlösungen von
        Deckellaschen-Codes gewonnen. Mehr auf{' '}
        <a
          href="http://gears.rockstarenergy.com"
          target="_blank"
          rel="noreferrer noopener"
        >
          Gears.Rockstarenergy.com
        </a>
      </li>
      <li>
        Das Freischalten jeglicher in-game Items für Gears 5 erfordert das Gears
        5 Spiel oder eine Xbox Game Pass Mitgliedschaft (separat erhältlich).
        Boosts erfordern eine Xbox Live Gold Mitgliedschaft (ebenfalls separat
        erhältlich).
      </li>
      <li>
        Lancer, Banner und Supply Drops sind exklusiv & zeitlich begrenzt
        verfügbar. Eventuell werden sie in zukünftigen Angeboten erneut
        berücksichtigt.
      </li>
      <li>
        Codes für in-game items müssen bis zum 30. März 2020 auf{' '}
        <a
          href="https://microsoft.com/redeem"
          target="_blank"
          rel="noreferrer noopener"
        >
          Microsoft.com/redeem
        </a>{' '}
        eingelöst werden
      </li>
    </ul>
  </div>
);

export default TermsDe;
