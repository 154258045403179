import React, { Component, Fragment } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import cx from 'classnames';

import { setCookie, getCookie } from '../../helpers/cookies';

import CookieDisclaimerStyled from './CookieDisclaimer.style';

import ModalController from '../ModalController/ModalController';
import ModalPrivacy from '../ModalPrivacy/ModalPrivacy';

const messages = defineMessages({
  title: {
    id: 'cookieDisclaimer.title',
    defaultMessage: 'ABOUT COOKIES',
    description: 'Title',
  },
  desc1: {
    id: 'cookieDisclaimer.desc1',
    defaultMessage:
      'We use cookies to help make this website better, to improve our services and for advertising purposes. You can learn more about our use of cookies in our privacy policy by clicking',
    description: 'Description 1',
  },
  descLink: {
    id: 'cookieDisclaimer.descLink',
    defaultMessage: 'here',
    description: 'Description Link',
  },
  desc2: {
    id: 'cookieDisclaimer.desc2',
    defaultMessage: 'Otherwise, we’ll assume you are OK to continue.',
    description: 'Description 2',
  },
  btn: {
    id: 'cookieDisclaimer.btn',
    defaultMessage: 'CONTINUE',
    description: 'Accept Button',
  },
});

class CookieDisclaimer extends Component {
  constructor() {
    super();
    this.state = {
      showDisclaimer: false,
      runAnimation: false,
    };
  }

  componentDidMount() {
    const cookieDisclaimer = getCookie('cookieDisclaimer');
    if (cookieDisclaimer !== 'dismissed') {
      this.setState({ showDisclaimer: true });
    }
  }

  onHideDisclaimer = () => {
    setCookie('cookieDisclaimer', 'dismissed', 365);

    this.setState(prevState => ({
      runAnimation: !!prevState,
    }));

    setTimeout(() => {
      this.setState({ showDisclaimer: false });
    }, 2000);
  };

  render() {
    const { showDisclaimer, runAnimation } = this.state;

    return (
      <CookieDisclaimerStyled>
        {showDisclaimer && (
          <div
            className={cx('CookieDisclaimer__inner', {
              isShow: showDisclaimer,
              isHide: runAnimation,
            })}
          >
            <h5 className="CookieDisclaimer__title">
              <FormattedMessage {...messages.title} />
            </h5>
            <div className="CookieDisclaimer__desc">
              <FormattedMessage {...messages.desc1} />{' '}
              <ModalController style={{ display: 'inline-block' }}>
                {(isOpen, handleToggle) => (
                  <Fragment>
                    <button
                      className="CookieDisclaimer__modalBtn"
                      type="button"
                      onClick={handleToggle}
                    >
                      <strong>
                        <FormattedMessage {...messages.descLink} />
                      </strong>
                    </button>

                    {isOpen && <ModalPrivacy onClose={handleToggle} />}
                  </Fragment>
                )}
              </ModalController>
              . <FormattedMessage {...messages.desc2} />
            </div>
            <button
              className="CookieDisclaimer__btn"
              onClick={this.onHideDisclaimer}
            >
              <FormattedMessage {...messages.btn} />
            </button>
          </div>
        )}
      </CookieDisclaimerStyled>
    );
  }
}

export default CookieDisclaimer;
