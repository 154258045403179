import React from 'react';

const RulesMX = () => (
  <div>
    <h3>
      <strong>BASES TERMINOS Y CONDICIONES</strong>
    </h3>

    <p>
      Para participar en la promoción Gana Premios con Rockstar® y Gears 5, se
      deberá dar lectura integra de las siguientes bases y cumplir totalmente
      con los requisitos y condiciones establecidas. La participación en la
      promoción implicará la comprensión y aceptación de las bases (en adelante
      las <strong>“Bases”</strong>).
    </p>

    <ol>
      <li>
        <p>
          <strong>PARTICIPANTES:</strong> El Público en General de la República
          Mexicana, que reúna todos y cada uno de los siguientes requisitos:
        </p>

        <ul>
          <li>Edad: Mayores de edad (18 años en adelante)</li>
          <li>Aceptar sujetarse a las presentes Bases.</li>
          <li>Identificación oficial vigente.</li>
          <li>Seguir y cumplir la mecánica de participación.</li>
        </ul>
      </li>
      <li>
        <strong>PRODUCTOS A PROMOVER:</strong> Productos Rockstar® botella Pet
        500 ml sabor original, marcadas en el empaque con la promoción.
      </li>
      <li>
        <strong>VIGENCIA:</strong> El presente periodo de participación de la
        mecánica inicia a las <strong>00:00:01 horas</strong> (tiempo Ciudad de
        México) <strong>del día</strong> 01 de Septiembre de 2019{' '}
        <strong>y concluye a las 23:59:59</strong>
        (tiempo de la Ciudad de México) <strong>del</strong> 30 de Noviembre de
        2019.
      </li>
      <li>
        <strong>COBERTURA GEOGRÁFICA:</strong> A Nivel Nacional.
      </li>
      <li>
        <p>
          <strong>MECÁNICA:</strong> El participante deberá:
        </p>

        <ol>
          <li>
            Comprar el producto a promover en cualquiera de los puntos de venta
            de la República Mexicana.
          </li>
          <li>
            El participante deberá ingresar y registrarse en el sitio WEB{' '}
            <a
              href="https://gears.rockstarenergy.com/mx"
              target="_blank"
              rel="noreferrer noopener"
            >
              http://gears.rockstarenergy.com/mx
            </a>{' '}
            or medio de su teléfono celular inteligente o cualquier computadora
            de escritorio o portátil. Deberá de ingresar los datos: Nombre,
            correo electrónico y teléfono. Para poder finalizar el registro el
            participante deberá aceptar BTC y aviso de privacidad.
          </li>
          <li>
            Ya registrado el participante, deberá registrar el código único
            marcado dentro de su taparrosca. Inmediatamente aparecerá en la
            pantalla una pregunta sobre el juego Gears 5. El Participante deberá
            responder la pregunta que le aparecerá al ingresar un código
            correcto, haciéndose posible acreedor al instante al premio que le
            corresponda, dependiendo del número de participación que sea la
            suya. En caso de que no responda correctamente la pregunta el
            participante no será acreedor a ningún premio en esa participación.
          </li>
          <li>
            El participante podrá registrar códigos únicos de manera ilimitada
            tomando en cuenta que podrá ser acreedor a únicamente una consola de
            Xbox™ durante todo el periodo de la promoción. También podrá ser
            acreedor a únicamente un juego de Gears 5. Adicionalmente el
            participante podrá ganar ilimitadamente los diferentes protectores
            de pantalla que se estarán entregando a lo largo de la promoción de
            acuerdo a las tablas mencionadas más adelante.
          </li>
        </ol>
      </li>
      <li>
        <p>
          <strong>INCENTIVOS OFRECIDOS</strong>
        </p>

        <table>
          <thead>
            <tr>
              <th>CANTIDAD</th>
              <th>DESCRIPCION</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>36</td>
              <td>
                A Xbox™ GEARS 5 Console Limited Edition branded with Gears5
              </td>
            </tr>
            <tr>
              <td>150</td>
              <td>Juegos de GEARS 5 Ultimate Edition</td>
            </tr>
            <tr>
              <td>100,000</td>
              <td>
                7 Day Xbox Game Pass and Xbox Live Gold membership + 1 in-game
                supply drop + in-game banner + 1 in-game boost
              </td>
            </tr>
            <tr>
              <td>100,000</td>
              <td>1 in-game supply drop + in-game banner + 1 in-game boost</td>
            </tr>
            <tr>
              <td>100,000</td>
              <td>4 in-game lancer skins + 1 in-game boost</td>
            </tr>
            <tr>
              <td>100,000</td>
              <td>1 in-game Boost</td>
            </tr>
          </tbody>
        </table>

        <p>
          <strong>
            a. Estos incentivos serán repartidos de acuerdo a la siguiente
            tabla:
          </strong>
        </p>

        <table>
          <thead>
            <tr>
              <td>SEMANA</td>
              <td>
                <strong># PARTICIPACIÓN</strong>
              </td>
              <td>
                <strong>PREMIO CORRESPONDIENTE</strong>
              </td>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td rowspan="3">
                <strong>SEMANA 1</strong>
              </td>
              <td>19</td>
              <td>
                1 CONSOLAS Xbox™ GEARS5 Console Limited Edition branded with
                Gears5 con garantía estándar
              </td>
            </tr>
            <tr>
              <td>1, 3, 5, 7, 9, 11, 12, 14, 17, 18</td>
              <td>10 Juegos GEARS 5 Ultimate Edition</td>
            </tr>
            <tr>
              <td>Todos los números ganan uno de los premios enlistados</td>
              <td>
                a) 7 Day Xbox Game Pass and Xbox Live Gold membership + 1
                in-game supply drop + in-game banner + 1 in-game boost b) 1
                in-game supply drop + in-game banner c) 4 in-game lancer skins +
                1 in-game boost d) + 1 in-game boost
              </td>
            </tr>
            <tr>
              <td rowspan="3">
                <strong>SEMANA 2</strong>
              </td>
              <td>24</td>
              <td>
                1 CONSOLAS Xbox™ GEARS5 Console Limited Edition branded with
                Gears5
              </td>
            </tr>
            <tr>
              <td>2, 4, 5, 8, 10, 16, 20, 22, 25, 29</td>
              <td>10 Juegos GEARS 5 Ultimate Edition</td>
            </tr>
            <tr>
              <td>Todos los números ganan uno de los premios enlistados</td>
              <td>
                a) 7 Day Xbox Game Pass and Xbox Live Gold membership + 1
                in-game supply drop + in-game banner + 1 in-game boost b) 1
                in-game supply drop + in-game banner c) 4 in-game lancer skins +
                1 in-game boost d) + 1 in-game boost
              </td>
            </tr>
            <tr>
              <td rowspan="3">
                <strong>SEMANA 3</strong>
              </td>
              <td>20, 30</td>
              <td>
                2 CONSOLAS Xbox™ GEARS5 Console Limited Edition branded with
                Gears5
              </td>
            </tr>
            <tr>
              <td>6, 11, 12, 15, 24, 26, 28, 29, 35, 39</td>
              <td>10 Juegos GEARS 5 Ultimate Edition</td>
            </tr>
            <tr>
              <td>Todos los números ganan uno de los premios enlistados</td>
              <td>
                a) 7 Day Xbox Game Pass and Xbox Live Gold membership + 1
                in-game supply drop + in-game banner + 1 in-game boost b) 1
                in-game supply drop + in-game banner c) 4 in-game lancer skins +
                1 in-game boost d) + 1 in-game boost
              </td>
            </tr>
            <tr>
              <td rowspan="3">
                <strong>4</strong>
              </td>
              <td>21, 34</td>
              <td>
                2 CONSOLAS Xbox™ GEARS5 Console Limited Edition branded with
                Gears5
              </td>
            </tr>
            <tr>
              <td>9, 30, 31, 32, 38, 40, 42, 43, 48, 55</td>
              <td>10 Juegos GEARS 5 Ultimate Edition</td>
            </tr>
            <tr>
              <td>Todos los números ganan uno de los premios enlistados</td>
              <td>
                a) 7 Day Xbox Game Pass and Xbox Live Gold membership + 1
                in-game supply drop + in-game banner + 1 in-game boost b) 1
                in-game supply drop + in-game banner c) 4 in-game lancer skins +
                1 in-game boost d) + 1 in-game boost
              </td>
            </tr>
            <tr>
              <td rowspan="3">
                <strong>SEMANA 5</strong>
              </td>
              <td>7, 10</td>
              <td>
                2 CONSOLAS Xbox™ GEARS5 Console Limited Edition branded with
                Gears5
              </td>
            </tr>
            <tr>
              <td>2, 17, 22, 28, 31, 36, 39, 41, 43, 47</td>
              <td>10 Juegos GEARS 5 Ultimate Edition</td>
            </tr>
            <tr>
              <td>Todos los números ganan uno de los premios enlistados</td>
              <td>
                a) 7 Day Xbox Game Pass and Xbox Live Gold membership + 1
                in-game supply drop + in-game banner + 1 in-game boost b) 1
                in-game supply drop + in-game banner c) 4 in-game lancer skins +
                1 in-game boost d) + 1 in-game boost
              </td>
            </tr>
            <tr>
              <td rowspan="3">
                <strong>SEMANA 6</strong>
              </td>
              <td>9, 20</td>
              <td>
                2 CONSOLAS Xbox™ GEARS5 Console Limited Edition branded with
                Gears5
              </td>
            </tr>
            <tr>
              <td>3, 5, 11, 15, 17, 21, 25, 27, 33, 39</td>
              <td>10 Juegos GEARS 5 Ultimate Edition</td>
            </tr>
            <tr>
              <td>Todos los números ganan uno de los premios enlistados</td>
              <td>
                a) 7 Day Xbox Game Pass and Xbox Live Gold membership + 1
                in-game supply drop + in-game banner + 1 in-game boost b) 1
                in-game supply drop + in-game banner c) 4 in-game lancer skins +
                1 in-game boost d) + 1 in-game boost
              </td>
            </tr>
            <tr>
              <td rowspan="3">
                <strong>SEMANA 7</strong>
              </td>
              <td>11, 27, 41</td>
              <td>
                3 CONSOLAS Xbox™ GEARS5 Console Limited Edition branded with
                Gears5
              </td>
            </tr>
            <tr>
              <td>1, 3, 9, 13, 18, 19, 23, 29, 33, 37, 40, 46, 52, 54,</td>
              <td>14 Juegos GEARS 5 Ultimate Edition</td>
            </tr>
            <tr>
              <td>Todos los números ganan uno de los premios enlistados</td>
              <td>
                a) 7 Day Xbox Game Pass and Xbox Live Gold membership + 1
                in-game supply drop + in-game banner + 1 in-game boost b) 1
                in-game supply drop + in-game banner c) 4 in-game lancer skins +
                1 in-game boost d) + 1 in-game boost
              </td>
            </tr>
            <tr>
              <td rowspan="3">
                <strong>SEMANA 8</strong>
              </td>
              <td>19, 22, 36</td>
              <td>
                3 CONSOLAS Xbox™ GEARS5 Console Limited Edition branded with
                Gears5
              </td>
            </tr>
            <tr>
              <td>10, 12, 14, 16, 23, 25, 34, 38, 45, 57, 65, 70, 77, 80</td>
              <td>14 Juegos GEARS 5 Ultimate Edition</td>
            </tr>
            <tr>
              <td>Todos los números ganan uno de los premios enlistados</td>
              <td>
                a) 7 Day Xbox Game Pass and Xbox Live Gold membership + 1
                in-game supply drop + in-game banner + 1 in-game boost b) 1
                in-game supply drop + in-game banner c) 4 in-game lancer skins +
                1 in-game boost d) + 1 in-game boost
              </td>
            </tr>
            <tr>
              <td rowspan="3">
                <strong>SEMANA 9</strong>
              </td>
              <td>21, 39, 41, 49</td>
              <td>
                4 CONSOLAS Xbox™ GEARS5 Console Limited Edition branded with
                Gears5
              </td>
            </tr>
            <tr>
              <td>2, 3, 15, 16, 23, 25, 34, 38, 45, 52, 55, 63, 72, 79</td>
              <td>14 Juegos GEARS 5 Ultimate Edition</td>
            </tr>
            <tr>
              <td>Todos los números ganan uno de los premios enlistados</td>
              <td>
                a) 7 Day xbox Game Pass and Xbox Live Gold membership + 1
                in-game supply drop + in-game banner + 1 in-game boost b) 1
                in-game supply drop + in-game banner c) 4 in-game lancer skins +
                1 in-game boost d) + 1 in-game boost
              </td>
            </tr>
            <tr>
              <td rowspan="3">
                <strong>SEMANA 10</strong>
              </td>
              <td>18, 22, 29, 34</td>
              <td>
                4 CONSOLAS Xbox™ GEARS5 Console Limited Edition branded with
                Gears5
              </td>
            </tr>
            <tr>
              <td>
                10, 17, 20, 21, 27, 28, 31, 35, 45, 49, 55, 61, 64, 70, 72, 75
              </td>
              <td>16 Juegos GEARS 5 Ultimate Edition</td>
            </tr>
            <tr>
              <td>Todos los números ganan uno de los premios enlistados</td>
              <td>
                a) 7 Day Xbox Game Pass and Xbox Live Gold membership + 1
                in-game supply drop + in-game banner + 1 in-game boost b) 1
                in-game supply drop + in-game banner c) 4 in-game lancer skins +
                1 in-game boost d) + 1 in-game boost
              </td>
            </tr>
            <tr>
              <td rowspan="3">
                <strong>SEMANA 11</strong>
              </td>
              <td>12, 23, 25, 31, 37, 42</td>
              <td>
                6 CONSOLAS Xbox™ GEARS5 Console Limited Edition branded with
                Gears5
              </td>
            </tr>
            <tr>
              <td>
                11, 17, 19, 21, 22, 26, 35, 38, 45, 52, 55, 61, 63, 69, 72, 79
              </td>
              <td>16 Juegos GEARS 5 Ultimate Edition</td>
            </tr>
            <tr>
              <td>Todos los números ganan uno de los premios enlistados</td>
              <td>
                a) 7 Day Xbox Game Pass and Xbox Live Gold membership + 1
                in-game supply drop + in-game banner + 1 in-game boost b) 1
                in-game supply drop + in-game banner c) 4 in-game lancer skins +
                1 in-game boost d) + 1 in-game boost
              </td>
            </tr>
            <tr>
              <td rowspan="3">
                <strong>SEMANA 12</strong>
              </td>
              <td>9, 18, 22, 28, 38, 46</td>
              <td>
                6 CONSOLAS Xbox™ GEARS5 Console Limited Edition branded with
                Gears5
              </td>
            </tr>
            <tr>
              <td>
                8, 13, 17, 24, 27, 29, 32, 33, 45, 52, 53, 59, 63, 65, 69, 79
              </td>
              <td>16 Juegos GEARS 5 Ultimate Edition</td>
            </tr>
            <tr>
              <td>Todos los números ganan uno de los premios enlistados</td>
              <td>
                a) 7 Day Xbox Game Pass and Xbox Live Gold membership + 1
                in-game supply drop + in-game banner + 1 in-game boost b) 1
                in-game supply drop + in-game banner c) 4 in-game lancer skins +
                1 in-game boost d) + 1 in-game boost
              </td>
            </tr>
          </tbody>
        </table>

        <p>
          En caso de no haber ganadores por semana, los incentivos de{' '}
          <strong>CONSOLAS</strong>
          Xbox<strong>™</strong> GEARS5 Console Limited Edition branded with
          Gears5 y <strong>Juego GEARS 5 Ultimate Edition</strong> que hayan
          sobrado se sumaran a la siguiente y así sucesivamente con base a los
          números asignados durante el periodo de tiempo definido para la
          promoción.
        </p>
      </li>

      <li>
        <p>
          <strong>VALIDACIÓN DE LOS ACREEDORES A INCENTIVOS:</strong> El
          participante deberá de presentar y entregar la taparrosca original con
          el código único registrado sin tachaduras, enmendaduras y legible para
          validar su participación y entrega del incentivo al cual se haya hecho
          acreedor.
        </p>

        <p>
          Los posibles ganadores se validarán semanalmente de acuerdo a la
          vigencia de la promoción.
        </p>
      </li>

      <li>
        <p>
          <strong>PUBLICACIÓN DE RESULTADOS Y ENTREGA DE INCENTIVOS:</strong>{' '}
          Los posibles ganadores serán notificados sobre cuál es el premio que
          ganaron por medio de correo electrónico al que haya sido registrado en
          el sitio Web. De no responder en un período de 5 días naturales a
          partir de ser contactado el participante, éste será automáticamente
          descalificado. Los premios que no hayan sido asignados, porque el
          participante no respondió al correo enviado; serán asignados en las
          últimas 3 semanas a los demás participantes para que puedan ser
          acreedores al premio. Cada posible ganador recibirá su premio
          correspondiente y de conformidad con lo establecido en las presentes
          bases del de la siguiente manera:
        </p>

        <p>
          Una vez que haya sido declarado ganador deberá enviar un correo
          electrónico aceptando el premio con los siguientes documentos:
        </p>

        <ol>
          <li>CURP</li>
          <li>RFC</li>
          <li>Identificación oficial vigente.</li>
          <li>Comprobante de domicilio</li>
          <li>
            Foto de la taparrosca. (Deberá de presentar y entregar la taparrosca
            original sin tachaduras, enmendaduras y legible para reclamar la
            entrega del incentivo, dicha presentación y entrega aplica para
            Xbox™ GEARS 5 Console Limited Edition branded with Gears5 y juegos
            de GEARS 5 Ultimate Edition.
          </li>
          <li>Aviso de privacidad firmado.</li>
          <li>
            Autorización de uso de imagen (En caso de ser requerido por el tipo
            de incentivo ganado).
          </li>
        </ol>

        <p>
          El organizador se pondrá en contacto con los ganadores vía correo
          electrónico para notificar a que incentivo fue acreedor, así como con
          los detalles para la coordinación de la entrega del premio, al recibir
          el premio se firmará una carta de recepción de incentivo, o el premio
          no se podrá entregar.
        </p>

        <p>
          El organizador se coordinará con el ganador del incentivo para
          realizar la entrega de los incentivos, ya sea de manera digital o en
          persona dependiendo del incentivo que haya sido ganado.
        </p>
      </li>

      <li>
        <p>
          <strong>RESTRICCIONES:</strong>
        </p>

        <p>
          <strong>IX.I RESTRICCIONES GENERALES</strong>
        </p>

        <ul>
          <li>
            Los posibles acreedores tendrán este carácter hasta en tanto se
            verifique si cumplieron con los requisitos de participación y que
            cuentan con la documentación o comprobantes que la promoción
            solicite (nota: esto puede ser tapas, tickets de compra o envases,
            etc.) registrados en buen estado, sin tachaduras ni enmendaduras.
          </li>
          <li>
            Los participantes se obligan a no distribuir ninguna parte del Sitio
            web, incluyendo a título enunciativo, más no limitativo, los
            archivos y demás información ingresados en el mismo a través de
            ningún medio, sin la previa autorización por escrito de La
            Organizadora.
          </li>
          <li>
            No alterar o modificar ninguna parte del Sitio web y sus tecnologías
            relacionadas. No eludir, desactivar o manipular de cualquier otra
            forma (o tratar de eludir, desactivar o manipular) las funciones de
            seguridad del Sitio web u otras funciones que impidan o restrinjan
            la utilización o acceso del Sitio web.
          </li>
          <li>
            No utilizar el sitio para ningún fin comercial, de publicidad, para
            realizar alguna actividad comercial, artística, técnico o
            profesional y/o con el fin principal de obtener ingresos. No
            utilizar o activar ningún sistema automatizado tendiente a modificar
            o copiar el contenido del Sitio web.
          </li>
          <li>No recabar datos personales de ningún usuario del Sitio.</li>
          <li>
            En caso de que el participante no pueda hacer uso del incentivo por
            causas imputables al participante, o por caso fortuito o fuerza
            mayor, no aplica compensación o remuneración alguna.
          </li>
          <li>
            No podrán participar en la promoción empleados, becarios,
            contratistas, socios, directivos, prestadores de servicios, ni
            personal de la compañía Organizadora ni de las agencias de operación
            o promocionales de la presente Promoción, ni que pertenezcan a
            Industria de Refrescos, S. de R.L. de CV, Publicidad estratégica
            SE-ED Group S.A. de C.V. y/o a sus filiales y/o subsidiarias, así
            como sus familiares y/o dependientes económicos, o aquellos que
            tengan relación directa o indirecta en la ejecución de la presente
            promoción.
          </li>
          <li>
            La participación en esta promoción y la obtención de dichos
            incentivos, no tendrá costo o requisito adicional por cumplir por
            parte del participante, a lo solicitado en las presentes Bases.
          </li>
          <li>
            Cada participante podrá ser acreedor a máximo una consola Xbox™ y un
            juego de GEARS 5 Ultimate Edition durante la promoción, después de
            eso sólo podrá ganar los demás incentivos.
          </li>
        </ul>

        <p>
          <strong>IX.II MOTIVOS DE DESCALIFICACIÓN</strong>
        </p>

        <p>
          Los participantes sin excepción alguna sólo podrán participar para
          esta Promoción durante la vigencia de la misma y dicha participación
          implica el pleno conocimiento y aceptación de las presente bases,
          obligándose al cumplimiento de las mismas.
        </p>
        <p>
          Las indicadas en la Mecánica y las demás que se indiquen conforme se
          vaya realizando la asignación de acreedores.
        </p>
        <p>
          En caso de haber resultado acreedor a algún incentivo violentando las
          bases y condiciones de participación se deberá de restituir el mismo a
          La Organizadora y responderán contra daños y perjuicios.
        </p>
        <p>
          La Organizadora no se hace responsable por fallas técnicas en el
          sistema de captura de datos o ejecución o desempeño de la dinámica de
          participación.
        </p>
        <p>
          Cualquier ataque o intento de alteración o manipulación al sistema
          informático o al Sitio web o al lugar en el cual se encuentre radicado
          el mismo, genera la descalificación inmediata del participante.
        </p>
        <p>
          Está prohibido violar o intentar violar de cualquier forma la
          seguridad de la página de Internet de LA PROMOCIÓN, incluyendo de
          manera enunciativa y no limitativa: a) acceder a datos no destinados
          al usuario; b) iniciar sesión en un servidor o cuenta en la que el
          usuario no esté autorizado; c) intentar violar la seguridad o
          penetrar, escanear o probar la vulnerabilidad del sistema; d) intentar
          interferir con el servicio a cualquier usuario. e) Utilizar mecanismos
          de automatización para participar en los juegos en línea o para
          modificar cronómetros, contadores, fechas o incrementar puntuación.
        </p>
        <p>
          El responsable de la promoción se reserva expresamente el derecho de
          descalificar a cualquier participante del cual se tenga duda o
          sospecha de que se encuentra interviniendo el sistema de la Promoción,
          sus procedimientos de entrada, ya sea en líneas o bajo cualquier otro
          método, o que esté actuando en contra de los Términos y Condiciones.
        </p>
      </li>

      <li>
        <p>
          <strong>AUTORIZACIÓN DE USO DE DATOS, OBRA E IMAGEN.</strong>
        </p>

        <p>
          Se entenderá que todas las personas que directa o indirectamente
          forman parte o en cualquier otra forma en la presente Promoción, han
          conocido y aceptado íntegramente estas bases, careciendo del derecho a
          deducir reclamo o acción de cualquier naturaleza en contra de a Grupo
          Gepp, S.A.P.I. de C.V., y/o a sus filiales y/o subsidiarias, Industria
          de Refrescos, S. DE R.L. DE CV y y/o de Publicidad Estratégica SE-ED
          Group, S.A. DE C.V., y otorgan, además, su consentimiento a la
          difusión de su imagen con motivo de la presente promoción. El solo
          hecho de que una persona participe en esta promoción, habilitará a a
          Grupo Gepp, S.A.P.I. de C.V., y/o a sus filiales y/o subsidiarias,
          Industria de Refrescos, S. DE R.L. DE CV y/o Publicidad Estratégica
          Se-ed Group, S.A. DE C.V., para divulgar sus publicaciones, su voz,
          las imágenes filmadas y/o las fotografías en la forma que estime
          conveniente, sin que el participante pueda reclamar exclusividad ni
          cobrar derecho alguno por su exhibición o reproducción por cualquier
          medio.La autorización anterior incluye el consentimiento expreso e
          irrevocable de usar o publicar sus materiales en cualquier medio que
          se considere conveniente en términos del artículo 87 de la Ley Federal
          del Derecho de Autor.
        </p>
        <p>
          Los participantes de esta promoción no recibirán pago alguno por estos
          conceptos, y deberá prestar toda su colaboración para su obtención a
          Grupo Gepp, S.A.P.I. de C.V., y/o a sus filiales y/o subsidiarias,
          Industria de Refrescos, S. DE R.L. DE CV y/o Publicidad Estratégica
          SE-ED Group, S.A. DE C.V.
        </p>
      </li>

      <li>
        <p>
          <strong>LÍMITES DE RESPONSABILIDAD DE LA ORGANIZADORA.</strong>
        </p>

        <p>
          Cualquier intento o ejecución de aplicación de programas o sistemas
          que pretendan atacar o vulnerar la Promoción, podrá ser perseguido con
          las acciones legales pertinentes que a criterio del organizador sean
          suficientes y acarrea la inmediata descalificación y anulación de
          participación del consumidor Participante que incurra en tal conducta.
        </p>
        <p>
          En caso de descalificación de un Participante por cualquier motivo,
          los Organizadores conservan el derecho de poder realizar la asignación
          del premio, realizando una nueva selección de los Participantes o
          disponer de éste a su conveniencia.
        </p>
        <p>
          Toda decisión de los Organizadores será inapelable, definitiva y sin
          posibilidad de empate. Asimismo el Organizador se reserva el derecho
          de ampliar o modificar en cualquier momento la mecánica de
          participación y/o los Términos y Condiciones de la presente Promoción,
          por lo que el Participante deberá de consultar las bases y los
          Términos y Condiciones constantemente y hasta el término de la
          Promoción. El Organizador podrá en cualquier momento suspender la
          Promoción o la entrega del incentivo sin responsabilidad alguna, si
          dicha suspensión responde en acato de cualquier disposición emitida
          por cualquier autoridad jurisdiccional, así como si se dieran
          circunstancias de caso fortuito o fuerza mayor o eventos que afecten
          los intereses de la Organizadora.
        </p>
        <p>
          Si por causas ajenas al Organizador se suspendiera, modificara,
          alterara o de cualquier forma se vieran imposibilitados los acreedores
          para disfrutar del incentivo o de la actividad programada, el
          Organizador se reserva el derecho de otorgar opcionalmente algún
          incentivo igual o similar al incentivo que el participante se hizo
          acreedor.
        </p>
        <p>
          De igual forma se procederá en los casos que existieran actos
          políticos, sociales o de salud que pusieran en peligro la integridad
          física de cualquiera de los acreedores. En todos estos casos, el
          Organizador publicará a través de medios impresos y/o a través de su
          página web los motivos en que se fundamenta la realización de
          cualquiera de los actos establecidos en el presente párrafo.
        </p>
        <p>
          Ninguno de los incentivos estará sujeto a cesión ni comercialización
          alguna. La responsabilidad del Organizador concluye al momento de la
          entrega del premio a sus correspondientes acreedores. El Organizador
          no será responsable por el uso que le brinde el Participante al
          premio, ni por procesos judiciales, extrajudiciales o de cualquier
          otra índole que puedan ser interpuestos en relación a un reclamo de
          cualquier tipo de responsabilidad, por hechos acontecidos durante o
          como consecuencia de la entrega del mismo, así como relacionados con
          la calidad, garantías por daños, pérdidas o perjuicios ocasionados por
          el uso y disfrute del incentivo.
        </p>
        <p>
          El Organizador no aceptará, y por ende procederá a descalificar, a
          persona o cliente que no acredite la mayoría de edad legal mediante
          una identificación oficial que así lo establezca, los incentivos serán
          entregados siempre y cuando se haya validado la identidad del acreedor
          correspondiente. El Participante Ganador deberá seguir todas las
          direcciones e instrucciones del Organizador. En caso de incumplimiento
          de cualquiera de estos puntos o de lo estipulado en la mecánica y/o
          Términos y Condiciones de la Promoción, el Participante será
          automáticamente descalificado sin ningún tipo de responsabilidad del
          Organizador.
        </p>
        <p>
          La organizadora se reserva el derecho de modificar las presentes bases
          sin alterar su esencia, en el evento que ocurra un caso fortuito o de
          fuerza mayor, se obliga a comunicar tal modificación a los
          participantes, a través de la red social Facebook de Rockstar® y/o
          correo electrónico registrado en{' '}
          <a
            href="https://gears.rockstarenergy.com/mx"
            target="_blank"
            rel="noreferrer noopener"
          >
            http://gears.rockstarenergy.com/mx
          </a>
        </p>
        <p>
          Por el simple hecho de participar, los concursantes deslindan de toda
          responsabilidad a. Grupo Gepp, S.A.P.I. de C.V., y/o a sus filiales
          y/o subsidiarias Industria de Refrescos, S. DE R.L. DE CV y Publicidad
          Estratégica SE-ED Group, S.A. DE C.V., quienes podrán utilizar la
          información generada durante la promoción para los fines que
          consideren convenientes por lo que por el simple hecho de participar,
          los Participantes otorgan de manera directa e incondicional los
          derechos y autorizaciones correspondientes.
        </p>
        <p>
          Grupo Gepp, S.A.P.I. de C.V., y/o a sus filiales y/o subsidiarias,
          Industria de Refrescos, S. DE R.L. DE CV y Publicidad Estratégica
          SE-ED Group, S.A. DE C.V.no es responsable por los comentarios, datos
          y/o cualquier tipo de información que sean expresados y/o publicados
          por terceros mediante redes sociales como Facebook y/o cualquier otro
          medio, ya que éstas son de exclusiva responsabilidad de quienes las
          emiten.
        </p>
        <p>
          Grupo Gepp, S.A.P.I. de C.V., y/o a sus filiales y/o subsidiarias
          Industria de Refrescos, S. DE R.L. DE CV y Publicidad Estratégica
          SE-ED Group, S.A. DE C.V., se reserva el derecho de verificar, en
          cualquier momento, la validez de la compra de los participantes,
          pudiendo descalificar y rechazar la entrega de la promoción cuando a
          su juicio se haya detectado alguna irregularidad o se perciba que no
          se actúa de buena fe, de modo poco ético o con malas intenciones.
        </p>
      </li>

      <li>
        <p>
          <strong>JURISDICCIÓN APLICABLE:</strong> Cualquier controversia
          suscitada por <strong>La Promoción</strong> y sus complementarias, se
          regirán por las presentes <strong>Bases</strong> y como complemento
          para lo establecido en éstas aplicará la jurisdicción de la Ciudad de
          México, sujetándose todas las partes interesadas a las mismas
          renunciando al que por beneficio de su domicilio presente o futuro
          pudiese corresponderles.
        </p>
      </li>

      <li>
        <p>
          <strong>ORGANIZADORA Y RESPONSABLE DE LA PROMOCIÓN:</strong> El
          organizador de la Promoción es la empresa Industria de Refrescos, S.
          DE R.L. DE CV con RFC IRE820805HA3 y dirección fiscal en Avenida Santa
          Fe 485, Piso 4, Col. Cruz Manca, Cuajimalpa de Morelos, C.P. 05349,
          Ciudad de México, México.
        </p>
      </li>
    </ol>
  </div>
);

export default RulesMX;
