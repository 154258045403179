import { keyframes } from 'styled-components';

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

export const fadeOut = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`;

export const scaleIn = keyframes`
  0% {
    opacity: 0;
    transform: scale(0.8);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
`;

export const scaleOut = keyframes`
  0% {
    opacity: 0;
    transform: scale(1.2);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
`;

export const flyIn = keyframes`
  0% {
    transform: scale(0.95) translateY(110%);
  }

  60% {
    transform: scale(0.95) translateY(110%);
  }

  80% {
    transform: scale(0.95) translateY(0);
  }

  100% {
    transform: scale(1) translateY(0);
  }
`;

export const flyOut = keyframes`
  0% {
    transform: scale(1) translateY(0);
  }

  20% {
    transform: scale(0.95) translateY(0);
  }

  40% {
    transform: scale(0.95) translateY(110%);
  }

  100% {
    transform: scale(0.95) translateY(110%);
  }
`;

export const dashedRotate = keyframes`
  0% {
    stroke-dasharray: 10.053096491487338 10.053096491487338 1 10.053096491487338;
    transform: rotate(0deg);
  }

  50% {
    stroke-dasharray: 10.053096491487338;
    transform: rotate(360deg);
  }

  100% {
    stroke-dasharray: 10.053096491487338 10.053096491487338 1 10.053096491487338;
    transform: rotate(720deg);
  }
`;

export const fadeInUp = keyframes`
  0% {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    transform: none;
  }
`;

export const fadeInUpBlock = keyframes`
  0% {
    opacity: 0;
    transform: translate3d(0, 25px, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    transform: none;
  }
`;

export const blink = keyframes`
  50% {
    opacity: 0;
  }
`;
