import React from 'react';

const PrivacyMx = () => (
  <div>
    <p>
      <strong>AVISO DE PRIVACIDAD - CLIENTES</strong>
    </p>

    <ol>
      <li>
        <p>
          <strong>Responsable del tratamiento de sus datos personales</strong>
        </p>
        <p>
          <strong>Grupo Gepp, S.A.P.I. de C.V., (“GEPP”), ,</strong> persona
          moral, debidamente constituida conforme a las leyes de la República
          Mexicana, con domicilio en Av. Santa Fe No. 485, piso 4, Col. Cruz
          Manca, Ciudad de México, Cuajimalpa de Morelos, C.P. 05349, es la
          persona responsable del tratamiento de sus datos personales.
        </p>
        <p>
          Para el Responsable, el tratar sus datos de manera legítima resulta un
          tema prioritario. Este Aviso de Privacidad complementa cualesquiera
          otros avisos de privacidad simplificados que nuestra organización haya
          puesto a su disposición y resulta supletorio en todo aquello que
          expresamente no refieran tales avisos.
        </p>
      </li>
      <li>
        <p>
          <strong>Departamento de datos personales:</strong>
        </p>
        <p>
          <strong>Domicilio:</strong> Av. Santa Fe No. 485, piso 4, Col. Cruz
          Manca, Ciudad de México., Cuajimalpa de Morelos, C.P. 05349.
          <br />
          <strong>Correo electrónico:</strong>{' '}
          <a href="mailto:datos.personales@gepp.com">
            datos.personales@gepp.com
          </a>
        </p>
      </li>
      <li>
        <p>
          <strong>Finalidades del tratamiento de los datos personales</strong>
        </p>
        <p>
          El Responsable en este acto recaba sus datos para las siguientes
          finalidades:
        </p>
        <ol type="I">
          <li>Para darlo de alta como cliente de nuestros servicios;</li>
          <li>
            Para las finalidades administrativas relacionadas con el desempeño
            de las obligaciones contractuales relativas, así como para el
            desempeño de las obligaciones de ley aplicables;
          </li>
          <li>
            Para contactarlo y ofrecerle promociones de eventos presentes o
            futuros, reportes estadísticos para promotores, organizadores y
            patrocinadores de eventos deportivos a través de boletines y
            newsletters;
          </li>
          <li>
            Para hacerle llegar ofertas de forma personalizada y prestarle
            servicios más apropiados por ejemplo, para hacerle recomendaciones y
            mostrarle el contenido de los productos de la familia GEPP, así como
            promociones, concursos y/o sorteos de la marca, incluyendo
            información publicitaria de productos relativos al acondicionamiento
            físico, a la salud y al cuidado de la imagen personal.
          </li>
          <li>
            Mantener un control sobre el acceso físico a nuestras instalaciones
            y poder detectar alguna incidencia.
          </li>
        </ol>
        <p>
          De conformidad con los lineamientos publicados en el Diario Oficial de
          la Federación el 17 de enero del 2013, le informamos que las
          finalidades necesarias para la existencia, mantenimiento y
          cumplimientos de nuestra relación jurídica son las detalladas en el
          punto 3 referidos anteriormente. En ese sentido, es necesario que
          indique si consiente el tratamiento de sus datos personales con
          relación a las finalidades señaladas en el punto 3, al no ser éstos
          necesarios o indispensables para nuestra relación jurídica.
        </p>
        <p>
          Consiento que mis datos personales sean tratados conforme a todas las
          finalidades referidas en los términos de este aviso de privacidad.
        </p>
      </li>
      <li>
        <p>
          <strong>Datos personales que recabamos</strong>
        </p>
        <p>
          Recabamos sus datos personales de forma personal cuando usted nos los
          proporciona, de manera directa, así como ya sea por teléfono, a través
          de correo electrónico o de nuestro sitio Web. Los datos personales que
          obtenemos son los siguientes: nombre, edad, domicilio, teléfono,
          nacionalidad, correo electrónico, Cedula Única de Registro de
          Población, Registro Federal de Contribuyentes, número de hijos, nombre
          y teléfono de la persona que refirió al cliente, beneficiarios y
          referencias laborales.
        </p>
        <p>
          De conformidad con lo que establece el artículo 9 de la Ley Federal de
          Protección de Datos Personales en Posesión de los Particulares, cuando
          el Responsable recabe de Usted tales datos, le será requerido su
          consentimiento expreso para el tratamiento de estos datos, por lo que
          le solicitaremos que, en su momento, indique si acepta o no el
          tratamiento, a través del mecanismo electrónico que hemos implementado
          para estos efectos, o en su caso, nos proporcione este documento
          debidamente firmado.
        </p>
      </li>
      <li>
        <p>
          <strong>
            Datos personales sensibles, financieros y patrimoniales que
            recabamos
          </strong>
        </p>
        <ol>
          <li>
            Datos Sensibles: El Responsable recaba datos sensibles para llevar a
            cabo las finalidades descritas en el presente aviso de privacidad.
            Dichos datos son los siguientes: Antecedentes de salud. Sus datos
            serán tratados únicamente para las finalidades descritas en este
            Aviso de Privacidad.
          </li>
          <li>
            Datos Financieros y Patrimoniales.- Nuestra organización recaba de
            usted los datos financieros y patrimoniales que resultan necesarios
            para nuestra relación contractual. Dichos datos son los siguientes:
            institución bancaria, sucursal, número de cuentas bancarias para
            transferencia de pago de nómina, reembolso de gastos, pago de
            prestaciones, datos de tarjetas de crédito.
          </li>
        </ol>
      </li>
      <li>
        <p>
          <strong>
            Mecanismos para que el titular pueda manifestar su negativa para el
            tratamiento de sus datos personales para aquellas finalidades que no
            son necesarias, ni hayan dado origen a la relación jurídica con el
            responsable.
          </strong>
        </p>
        <p>
          De manera adicional, utilizaremos su información personal para las
          siguientes finalidades que no son necesarias para el servicio
          solicitado, pero que nos permiten y facilitan brindarle una mejor
          atención:
        </p>
        <ul>
          <li>
            Finalidad secundaria para fines medir el nivel de satisfacción de
            cliente o de la calidad de nuestros productos o servicios.
          </li>
        </ul>
        <p>
          En caso de que no desee que sus datos personales se utilicen para
          estos fines, indíquelo a continuación:
        </p>
        <ul>
          <li>
            No consiento que mis datos personales se utilicen para los fines
            secundarios mencionados en los párrafos anteriores.
          </li>
        </ul>
        <p>
          La negativa para el uso de sus datos personales para estas finalidades
          no podrá ser un motivo para que le neguemos los servicios y productos
          que solicita o contrata con nosotros.
        </p>
      </li>
      <li>
        <p>
          <strong>Uso de Cookies y Web Beacons</strong>
        </p>
        <p>
          Le informamos que no recabamos datos personales a través del uso de
          Cookies o Web Beacons, y otras tecnologías para obtener información
          personal de usted, como pudiera ser la siguiente:
        </p>
        <ul>
          <li>Su tipo de navegador y sistema operativo.</li>
          <li>Las páginas de Internet que visita.</li>
          <li>Los vínculos que sigue.</li>
          <li>La dirección IP.</li>
          <li>El sitio que visitó antes de entrar al nuestro.</li>
        </ul>
      </li>
      <li>
        <p>
          <strong>
            Opciones y medios para limitar el uso o divulgación de datos
            personales
          </strong>
        </p>
        <p>
          Usted puede dejar de recibir mensajes por correo electrónico,
          impresos, por teléfono fijo o celular comunicándose con nuestro
          Responsable de Datos Personales.
        </p>
      </li>
      <li>
        <p>
          <strong>
            Medios para ejercer los derechos de acceso, rectificación,
            cancelación u oposición
          </strong>
        </p>
        <p>
          Usted tiene el derecho de acceder a sus datos personales que poseemos
          y a los detalles del tratamiento de los mismos, así como a
          rectificarlos en caso de ser inexactos o incompletos; cancelarlos
          cuando resulten ser excesivos o innecesarios para las finalidades que
          justificaron su obtención u oponerse al tratamiento de los mismos para
          fines específicos.
        </p>
        <p>
          Los mecanismos que se han implementado para el ejercicio de dichos
          derechos son a través de la presentación de la solicitud (“Solicitud
          de Ejercicio de Derechos ARCO”) la cual deberá ser solicitada a
          nuestro Responsable de Datos Personales, enviando un correo
          electrónico al señalado previamente, acompañando la siguiente
          información:
        </p>
        <ol>
          <li>Nombre y domicilio completo;</li>
          <li>
            Identificación con la que acredite su personalidad (Credencial para
            votar emitida por el Instituto Nacional Electoral [“INE”], Pasaporte
            vigente, Cédula Profesional, o en caso de ser de nacionalidad
            extranjera, su documento migratorio vigente);
          </li>
          <li>
            En caso de no ser el titular quien presente la solicitud, el
            documento que acredite la existencia de la representación, es decir
            instrumento público o carta poder firmada ante dos testigos, junto
            con la identificación del titular y del representante legal
            (Credencial del IFE, Pasaporte vigente, Cédula Profesional, o en
            caso de ser de nacionalidad extranjera, su documento migratorio
            vigente)
            <ul>
              <li>
                Para el caso de menores de edad, los documentos para acreditar
                la representación legal del menor, que serán: acta de nacimiento
                y credencial con fotografía del menor (la otorgada por la
                institución académica a donde acuda), credencial del Instituto
                Mexicano del Seguro Social (“IMSS”), Pasaporte vigente, o
                cualquier otra que cuente con fotografía del mismo, además que
                al acudir a presentar los documentos para su cotejo respectivo,
                la firma del documento que se anexará a la solicitud como
                “Acreditación de representación legal” se manifieste bajo
                protesta de decir verdad ser el responsable del menor;
              </li>
              <li>
                Para el caso de interdictos (incapacitados), los documentos para
                acreditar la representación legal serán: acta de interdicto y
                credencial con fotografía de la persona que ostente esta
                situación jurídica, ya sea Credencial para votar emitida por el
                Instituto Federal Electoral (“IFE”), Pasaporte vigente, Cédula
                Profesional o documento migratorio (este último caso para el
                caso de extranjeros).
              </li>
            </ul>
          </li>
          <li>
            Descripción clara y precisa de los datos personales respecto de los
            cuales busca ejercer alguno de los Derechos ARCO, cuál es el derecho
            a ejercer y las razones por las cuales desea ejercitarlo;
          </li>
          <li>
            Cualquier documento o información que acredite que sus datos
            personales se encuentran en propiedad de <strong>Grupo Gepp</strong>
            .
          </li>
          <li>
            En caso de solicitar una rectificación de datos, se indicarán
            también las modificaciones a realizarse y se aportará la
            documentación que sustente su petición (acta de nacimiento,
            comprobante de domicilio, o aquél en el que conste y se motive el
            cambio que se va a realizar en sus datos personales).
          </li>
        </ol>
        <p>
          El Responsable responderá su solicitud mediante correo electrónico o
          personalmente en el domicilio antes indicado, en un término de 20
          (veinte) días hábiles contados a partir de que se le envíe acuse de
          recibo de la misma. En caso de que la solicitud sea procedente, la
          respuesta podrá enviarse vía correo electrónico o de forma personal.
          Grupo Gepp podrá solicitarle para poder darle una respuesta, presente
          para cotejo en el domicilio antes descrito, original de los documentos
          que envió junto con su solicitud, dentro de los 5 (cinco) días hábiles
          siguientes a que le sean requeridos. Si pasado dicho término usted no
          ha presentado los documentos, su solicitud se archivará y el Aviso de
          Privacidad continuará vigente hasta en tanto no se presente una nueva
          solicitud.
        </p>
        <p>
          Cuando la solicitud sea procedente y se hayan llevado a cabo los
          cotejos correspondientes con respecto a la personalidad y titularidad
          de los Derechos ARCO, los términos para llevar a cabo la solicitud
          serán los siguientes:
        </p>
        <ul>
          <li>
            Para el acceso de los datos: dentro de un plazo de 15 (quince) días
            contados a partir de la respuesta afirmativa hecha por el
            Responsable, vía correo electrónico.
          </li>
          <li>
            Para la rectificación de los datos: dentro de un plazo de 15
            (quince) días contados a partir de la respuesta afirmativa hecha por
            el Responsable.
          </li>
          <li>
            Para la cancelación u oposición de los datos: se hará primero un
            bloqueo de los mismos, desde el momento en que se hizo el cotejo de
            la documentación requerida, en donde el acceso a los datos
            personales estará restringido a toda persona hasta que haya una
            respuesta a la solicitud ya sea afirmativa o negativa, en el primer
            caso dentro de un plazo de 15 (quince) días contados a partir de la
            respuesta afirmativa hecha por el Responsable, y en el segundo caso
            se hará el desbloqueo de los mismos para continuar con el
            tratamiento.
          </li>
        </ul>
        <p>
          Los plazos referidos en los incisos anteriores se podrán prorrogar una
          sola vez por un periodo igual en caso de ser necesario y previa
          notificación hecha por el Responsable.
        </p>
        <p>
          <strong>“GEPP”</strong> podrá negar el ejercicio de los Derechos ARCO,
          en los siguientes supuestos:
        </p>
        <ol type="a">
          <li>
            Cuando usted no sea el titular de los datos personales, o no haya
            acreditado la representación del titular;
          </li>
          <li>
            Cuando sus datos personales no obren en la base de datos del
            Responsable;
          </li>
          <li>Cuando se lesionen los derechos de un tercero;</li>
          <li>
            Cuando exista un impedimento legal o la resolución de una autoridad
            competente que restrinja sus Derechos ARCO;
          </li>
          <li>
            En caso de cancelación, cuando los datos personales sean objeto de
            tratamiento para la prevención o para el diagnóstico médico o la
            gestión de servicios de salud, o;
          </li>
          <li>
            Cuando la rectificación, cancelación u oposición haya sido
            previamente realizada.
          </li>
        </ol>
        <p>
          La Negativa podrá ser parcial, en cuyo caso, <strong>“GEPP”</strong>{' '}
          efectuará el acceso, rectificación, cancelación u oposición en la
          parte procedente.
        </p>
        <p>
          El ejercicio de los “Derechos ARCO” será gratuito, pero si Usted
          reitera su solicitud en un periodo menor a 12 (doce) meses, los costos
          serán de 3 (tres) días de Salario Mínimo General Vigente en el
          Distrito Federal, más el Impuesto al Valor Agregado, a menos que
          existan modificaciones sustanciales al Aviso de Privacidad que motiven
          nuevas Solicitudes de Ejercicio de Derechos ARCO. Usted deberá de
          cubrir los gastos justificados de envío o el costo de reproducción en
          copias u otros formatos.
        </p>
        <p>
          Para mayor información, favor de contactar al Responsable de datos
          personales, en el correo electrónico señalado anteriormente.
        </p>
      </li>
      <li>
        <p>
          <strong>
            Mecanismos y procedimientos para que el titular, en su caso, revoque
            su consentimiento en cualquier momento
          </strong>
        </p>
        <p>
          En cualquier momento del tratamiento usted podrá revocar el
          consentimiento que ha otorgado para el tratamiento de sus datos, para
          ello es necesario que contacte al Responsable de protección de datos,
          donde le será indicado el procedimiento que debe seguir para realizar
          la revocación de su consentimiento.
        </p>
      </li>
      <li>
        <p>
          <strong>
            Transferencias de datos personales dentro de México y al extranjero
          </strong>
        </p>
        <p>
          Sus datos personales pueden ser transferidos y tratados dentro y fuera
          del país, por personas distintas a esta empresa. Nosotros compartimos
          sus datos entre nuestras filiales para cumplir con los objetos
          sociales de las mismas así como con instituciones bancarias para
          efecto de pagar la nómina y prestaciones de los empleados como la
          integración del seguro de gastos médicos, fondo de ahorro, revisiones
          médicas integrales, despachos externos para llevar a cabo el objeto de
          la sociedad.  Sus datos se encuentran protegidos en virtud de que
          nuestras filiales y subsidiarias, o bien nuestros socios comerciales
          y/o prestadores de servicio, operarán bajo la misma Política de
          Protección de Datos Personales. En todo caso su información será
          compartida solamente para las finalidades citadas en este aviso de
          privacidad.
        </p>
        <p>
          Consiento que mis datos personales sean compartidos en los términos de
          este aviso de privacidad.
        </p>
      </li>
      <li>
        <p>
          <strong>Medidas de seguridad implementadas</strong>
        </p>
        <p>
          Para la protección de sus datos personales hemos instrumentado medidas
          de seguridad de carácter administrativo, físico y técnico con el
          objeto de evitar pérdidas, mal uso o alteración de su información.
        </p>
        <p>
          Cuando comunicamos o compartimos su información con terceros que nos
          prestan algún servicio, requerimos y verificamos que cuenten con las
          medidas de seguridad necesarias para proteger sus datos personales,
          prohibiendo el uso de su información personal para fines distintos a
          los encargados; lo anterior en el entendido que no obstante lo
          anterior, cualquier incumplimiento por dichos terceros a lo previsto
          en la Ley Federal de Protección de Datos Personales en Posesión de los
          Particulares y su Reglamento es exclusiva responsabilidad de dichos
          terceros.
        </p>
      </li>
      <li>
        <p>
          <strong>Modificaciones al aviso de privacidad</strong>
        </p>
        <p>
          Nos reservamos el derecho de efectuar en cualquier momento
          modificaciones o actualizaciones al presente aviso de privacidad.
        </p>
        <p>
          Estas modificaciones estarán disponibles al público a través de
          cualquiera de los siguientes medios: (i) anuncios visibles en las
          oficinas corporativas; (ii) trípticos o folletos disponibles en las
          oficinas corporativas; (iii) en nuestra página de Internet{' '}
          <a
            href="http://www.gepp.com.mx"
            target="_blank"
            rel="noopener noreferrer"
          >
            www.gepp.com.mx
          </a>{' '}
          o (iv) se las haremos llegar al último correo electrónico que nos haya
          proporcionado.
        </p>
      </li>
      <li>
        <p>
          <strong>
            Derecho de promover los procedimientos de protección de derechos y
            de verificación que sustancia el Instituto
          </strong>
        </p>
        <p>
          Cualquier queja o información adicional respecto al tratamiento de sus
          datos personales o duda en relación con la Ley Federal de Protección
          de Datos Personales en Posesión de los Particulares o con su
          Reglamento, podrá dirigirla al IFAI. Para mayor información visite{' '}
          <a
            href="http://www.ifai.org.mx"
            target="_blank"
            rel="noopener noreferrer"
          >
            www.ifai.org.mx
          </a>
        </p>
      </li>
    </ol>
    <p>
      Nombre del Titular
      <br />
      ______________________________
    </p>
    <p>
      Firma
      <br />
      Autógrafa ____________________
    </p>
  </div>
);

export default PrivacyMx;
