import React from 'react';

import iconYT from '../../images/icon-youtube.svg';
import iconTW from '../../images/icon-twitter.svg';
import iconFB from '../../images/icon-facebook.svg';
import iconIG from '../../images/icon-instagram.svg';

const config = {
  'en-US': {
    socialLinksGears: [
      {
        className: '--yt',
        url: 'https://www.youtube.com/channel/UC3E8iPQGBdAnOQR_cmkEbsw',
        src: iconYT,
        alt: '',
      },
      {
        className: '--tw',
        url: 'https://twitter.com/gearsofwar',
        src: iconTW,
        alt: '',
      },
      {
        className: '--fb',
        url: 'https://www.facebook.com/gearsofwar',
        src: iconFB,
        alt: '',
      },
      {
        className: '--ig',
        url: 'https://www.instagram.com/gearsofwar',
        src: iconIG,
        alt: '',
      },
    ],
    socialLinksRs: [
      {
        className: '--yt',
        url: 'https://www.youtube.com/rockstar',
        src: iconYT,
        alt: '',
      },
      {
        className: '--tw',
        url: 'https://twitter.com/rockstarenergy',
        src: iconTW,
        alt: '',
      },
      {
        className: '--fb',
        url: 'https://www.facebook.com/rockstar',
        src: iconFB,
        alt: '',
      },
      {
        className: '--ig',
        url: 'https://www.instagram.com/rockstarenergy',
        src: iconIG,
        alt: '',
      },
    ],
  },
  'en-CA': {
    socialLinksGears: [
      {
        className: '--yt',
        url: 'https://www.youtube.com/channel/UC3E8iPQGBdAnOQR_cmkEbsw',
        src: iconYT,
        alt: '',
      },
      {
        className: '--tw',
        url: 'https://twitter.com/gearsofwar',
        src: iconTW,
        alt: '',
      },
      {
        className: '--fb',
        url: 'https://www.facebook.com/gearsofwar',
        src: iconFB,
        alt: '',
      },
      {
        className: '--ig',
        url: 'https://www.instagram.com/gearsofwar',
        src: iconIG,
        alt: '',
      },
    ],
    socialLinksRs: [
      {
        className: '--yt',
        url: 'https://www.youtube.com/rockstar',
        src: iconYT,
        alt: '',
      },
      {
        className: '--tw',
        url: 'https://twitter.com/Rockstar69CAN',
        src: iconTW,
        alt: '',
      },
      {
        className: '--fb',
        url: 'https://www.facebook.com/RockstarCanada/',
        src: iconFB,
        alt: '',
      },
      {
        className: '--ig',
        url: 'https://www.instagram.com/rockstarenergycanada/',
        src: iconIG,
        alt: '',
      },
    ],
  },
  'en-GB': {
    socialLinksGears: [
      {
        className: '--yt',
        url: 'https://www.youtube.com/channel/UC3E8iPQGBdAnOQR_cmkEbsw',
        src: iconYT,
        alt: '',
      },
      {
        className: '--tw',
        url: 'https://twitter.com/gearsofwar',
        src: iconTW,
        alt: '',
      },
      {
        className: '--fb',
        url: 'https://www.facebook.com/gearsofwar',
        src: iconFB,
        alt: '',
      },
      {
        className: '--ig',
        url: 'https://www.instagram.com/gearsofwar',
        src: iconIG,
        alt: '',
      },
    ],
    socialLinksRs: [
      {
        className: '--yt',
        url: 'https://www.youtube.com/rockstar',
        src: iconYT,
        alt: '',
      },
      {
        className: '--tw',
        url: 'https://twitter.com/rockstar_energy',
        src: iconTW,
        alt: '',
      },
      {
        className: '--fb',
        url: 'https://www.facebook.com/RockstarEnergyDrinkUK',
        src: iconFB,
        alt: '',
      },
      {
        className: '--ig',
        url: 'https://www.instagram.com/rockstarenergyuk/',
        src: iconIG,
        alt: '',
      },
    ],
  },
  'de-DE': {
    socialLinksGears: [
      {
        className: '--yt',
        url: 'https://www.youtube.com/channel/UC3E8iPQGBdAnOQR_cmkEbsw',
        src: iconYT,
        alt: '',
      },
      {
        className: '--tw',
        url: 'https://twitter.com/gearsofwar',
        src: iconTW,
        alt: '',
      },
      {
        className: '--fb',
        url: 'https://www.facebook.com/gearsofwar',
        src: iconFB,
        alt: '',
      },
      {
        className: '--ig',
        url: 'https://www.instagram.com/gearsofwar',
        src: iconIG,
        alt: '',
      },
    ],
    socialLinksRs: [
      {
        className: '--yt',
        url: 'https://www.youtube.com/rockstar',
        src: iconYT,
        alt: '',
      },
      {
        className: '--tw',
        url: 'https://twitter.com/rockstargermany?lang=en',
        src: iconTW,
        alt: '',
      },
      {
        className: '--fb',
        url: 'https://www.facebook.com/rockstarenergygermany/',
        src: iconFB,
        alt: '',
      },
      {
        className: '--ig',
        url: 'https://www.instagram.com/rockstargermany/',
        src: iconIG,
        alt: '',
      },
    ],
  },
  'es-MX': {
    socialLinksGears: [
      {
        className: '--yt',
        url: 'https://www.youtube.com/channel/UC3E8iPQGBdAnOQR_cmkEbsw',
        src: iconYT,
        alt: '',
      },
      {
        className: '--tw',
        url: 'https://twitter.com/gearsofwar',
        src: iconTW,
        alt: '',
      },
      {
        className: '--fb',
        url: 'https://www.facebook.com/gearsofwar',
        src: iconFB,
        alt: '',
      },
      {
        className: '--ig',
        url: 'https://www.instagram.com/gearsofwar',
        src: iconIG,
        alt: '',
      },
    ],
    socialLinksRs: [
      {
        className: '--yt',
        url: 'https://www.youtube.com/rockstar',
        src: iconYT,
        alt: '',
      },
      {
        className: '--tw',
        url: 'https://twitter.com/rockstarenergy',
        src: iconTW,
        alt: '',
      },
      {
        className: '--fb',
        url: 'https://www.facebook.com/rockstar',
        src: iconFB,
        alt: '',
      },
      {
        className: '--ig',
        url: 'https://www.instagram.com/rockstarenergy',
        src: iconIG,
        alt: '',
      },
    ],
  },
};

export default config;
