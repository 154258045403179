import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cx from 'classnames';

import Loader from '../../images/loader.svg';

const Alert = styled(({ className, loader, children, ...rest }) => (
  <div className={cx('Alert', className)} {...rest}>
    {loader && <div className="Alert__loader" />}
    {children}
  </div>
))`
  display: flex;
  align-items: center;
  color: ${p =>
    (p.status === 'success' && '#1bd002') ||
    (p.status === 'info' && '#009ee2') ||
    (p.status === 'error' && '#ff0725')};
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.88px;
  line-height: 1.1;

  .Alert__loader {
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 4px;
    background-image: url(${Loader});
    background-repeat: no-repeat;
    background-size: 20px 20px;
  }
`;

Alert.propTypes = {
  status: PropTypes.oneOf(['success', 'info', 'error']).isRequired,
  loader: PropTypes.bool,
};

Alert.defaultProps = {
  loader: false,
};

export default Alert;
