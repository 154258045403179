import styled from 'styled-components';
import * as defaultTheme from '../variables';

import SocialBg from '../../images/social-bg.jpg';

const SocialStyled = styled.section`
  padding: ${p => p.theme.space * 4}px 0;
  color: ${p => p.theme.textColorWhite};
  background-image: url(${SocialBg});
  background-position: center;
  background-repeat: no-repeat;
  background-size: 150%;

  @media (${p => p.theme.smMin}) {
    padding: ${p => p.theme.space * 8}px 0;
    background-size: cover;
  }

  @media (${p => p.theme.lgMin}) {
    padding: ${p => p.theme.space * 9}px 0;
  }

  .Social__inner {
    display: flex;
    max-width: ${p => p.theme.maxWidth}px;
    margin: 0 auto;

    @media (${p => p.theme.xsMax}) {
      flex-direction: column;
      align-items: center;
    }

    @media (${p => p.theme.smMin}) {
      justify-content: space-between;
      max-width: 690px;
    }
  }

  .Social__title {
    margin-bottom: ${p => p.theme.space * 4}px;
    font-family: 'AzbukaPro';
    font-size: 31px;
    font-weight: 900;
    line-height: 28px;
    text-align: center;
    text-transform: uppercase;
    text-shadow: 0 0 8px ${p => p.theme.textColorBlack},
      1px 1px 8px ${p => p.theme.textColorBlack},
      -1px -1px 8px ${p => p.theme.textColorBlack};
  }

  .Social__block {
    width: 256px;

    @media (${p => p.theme.xsMax}) {
      &:not(:last-child) {
        margin-bottom: ${p => p.theme.space * 4}px;
      }
    }

    @media (${p => p.theme.smMin}) {
      width: 314px;
    }
  }

  .Social__info {
    display: flex;
    align-items: center;
    width: 100%;
    height: 50px;
    padding: 0 ${p => p.theme.space}px;
    background-color: rgba(0, 0, 0, 0.6);
    text-transform: uppercase;
  }

  .Social__infoImg {
    margin-right: 10px;
  }

  .Social__infoTitle {
    font-family: 'AzbukaPro';
    font-size: 14px;
    font-weight: 900;
    line-height: 17px;

    &.--gears {
      color: ${p => p.theme.red};
    }

    &.--rs {
      color: ${p => p.theme.secondary};
    }
  }

  .Social__links {
    display: flex;
    justify-content: space-between;
    margin-top: ${p => p.theme.space * 2}px;
  }

  .Social__link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 52px;
    height: 52px;
    padding: 12px;
    transition: opacity ${p => p.theme.transitionFast};

    @media (${p => p.theme.smMin}) {
      width: 66px;
      height: 66px;
    }

    &:hover {
      opacity: 0.7;
    }

    &.--yt {
      background-color: #be2018;
    }

    &.--tw {
      background-color: #57a4da;
    }

    &.--fb {
      background-color: #385899;
    }

    &.--ig {
      background-color: #d14667;
      background-image: linear-gradient(to bottom, #d14667 0%, #f37c32 100%);
    }
  }
`;

SocialStyled.defaultProps = {
  theme: defaultTheme,
};

export default SocialStyled;
