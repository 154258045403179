import React from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';

import cardXboxLiveGold from '../../images/rewards-card-xbox-live-gold.png';
import cardXboxGamePass from '../../images/rewards-card-xbox-game-pass.png';
import cardBoost from '../../images/rewards-card-boost.png';
import cardSupplyDrops from '../../images/rewards-card-supply-drops.png';

import cardBannerDel from '../../images/rewards-card-banner-del.png';
import cardBannerJd from '../../images/rewards-card-banner-jd.png';
import cardBannerKait from '../../images/rewards-card-banner-kait.png';
import cardBannerMarcus from '../../images/rewards-card-banner-marcus.png';
import cardBannerScorpion from '../../images/rewards-card-banner-scorpion.png';
import cardBannerSwarm from '../../images/rewards-card-banner-swarm.png';

import cardLancerDel from '../../images/rewards-card-lancer-del.png';
import cardLancerJd from '../../images/rewards-card-lancer-jd.png';
import cardLancerKait from '../../images/rewards-card-lancer-kait.png';
import cardLancerMarcus from '../../images/rewards-card-lancer-marcus.png';
import cardLancerScorpion from '../../images/rewards-card-lancer-scorpion.png';
import cardLancerSwarm from '../../images/rewards-card-lancer-swarm.png';

const messages = defineMessages({
  pack1: {
    id: 'rewardsData.pack1',
    defaultMessage: 'PACK 1',
  },
  pack2: {
    id: 'rewardsData.pack2',
    defaultMessage: 'PACK 2',
  },
  pack3: {
    id: 'rewardsData.pack3',
    defaultMessage: 'PACK 3',
  },
  pack4: {
    id: 'rewardsData.pack4',
    defaultMessage: 'PACK 4',
  },
  pack5: {
    id: 'rewardsData.pack5',
    defaultMessage: 'PACK 5',
  },
  packBoost: {
    id: 'rewardsData.packBoost',
    defaultMessage: 'PACK 6+',
  },
  packWalmart: {
    id: 'rewardsData.packWalmart',
    defaultMessage: 'WALMART EXCLUSIVE',
  },
  packCircleK: {
    id: 'rewardsData.packCircleK',
    defaultMessage: 'CIRCLE K EXCLUSIVE',
  },

  desc1: {
    id: 'rewardsData.desc1',
    defaultMessage: 'Unlock with 1st can code:',
  },
  desc2: {
    id: 'rewardsData.desc2',
    defaultMessage: 'Unlock with 2nd can code:',
  },
  desc3: {
    id: 'rewardsData.desc3',
    defaultMessage: 'Unlock with 3rd can code:',
  },
  desc4: {
    id: 'rewardsData.desc4',
    defaultMessage: 'Unlock with 4th can code:',
  },
  desc5: {
    id: 'rewardsData.desc5',
    defaultMessage: 'Unlock with 5th can code:',
  },
  desc6Plus: {
    id: 'rewardsData.desc6Plus',
    defaultMessage:
      'Unlock Boost with 6th code and continue unlocking with every additional code.',
  },
  desc8Plus: {
    id: 'rewardsData.desc6',
    defaultMessage:
      'Unlock Boost with 8th code and continue unlocking with every additional code.',
  },
  descWalmart: {
    id: 'rewardsData.descWalmart',
    defaultMessage:
      'Codes printed on receipt at checkout.  Purchase any Rockstar Energy Product at Walmart to earn exclusive pack code + bonus unlock for standard pack and prize entry. Offer available 9/3/19 to 11/30/19.',
  },
  descCircleK: {
    id: 'rewardsData.descCircleK',
    defaultMessage:
      'Code issued as SMS text through digital display screen at checkout. Purchase any Gears 5 Collector Can at Circle K to earn exclusive pack code + bonus unlock for standard pack and prize entry.  Offer available 9/23/19 to 11/30/19.',
  },

  cardXboxGold: {
    id: 'rewardsData.cardXboxGold',
    defaultMessage: '7-DAY ACCESS',
  },
  cardGamePass: {
    id: 'rewardsData.cardGamePass',
    defaultMessage: '7-DAY ACCESS',
  },
  cardBoost: {
    id: 'rewardsData.cardBoost',
    defaultMessage: '24-HOUR BOOST',
  },
  cardSupplyDrops: {
    id: 'rewardsData.cardSupplyDrops',
    defaultMessage: 'SUPPLY DROP',
  },

  cardBannerDel: {
    id: 'rewardsData.cardBannerDel',
    defaultMessage: 'DEL BANNER',
  },
  cardBannerJd: {
    id: 'rewardsData.cardBannerJd',
    defaultMessage: 'JD BANNER',
  },
  cardBannerKait: {
    id: 'rewardsData.cardBannerKait',
    defaultMessage: 'KAIT BANNER',
  },
  cardBannerMarcus: {
    id: 'rewardsData.cardBannerMarcus',
    defaultMessage: 'MARCUS BANNER',
  },
  cardBannerScorpion: {
    id: 'rewardsData.cardBannerScorpion',
    defaultMessage: 'SCORPION BANNER',
  },
  cardBannerSwarm: {
    id: 'rewardsData.cardBannerSwarm',
    defaultMessage: 'SWARM BANNER',
  },

  cardLancerDel: {
    id: 'rewardsData.cardLancerDel',
    defaultMessage: 'DEL LANCER',
  },
  cardLancerJd: {
    id: 'rewardsData.cardLancerJd',
    defaultMessage: 'JD LANCER',
  },
  cardLancerKait: {
    id: 'rewardsData.cardLancerKait',
    defaultMessage: 'KAIT LANCER',
  },
  cardLancerMarcus: {
    id: 'rewardsData.cardLancerMarcus',
    defaultMessage: 'MARCUS LANCER',
  },
  cardLancerScorpion: {
    id: 'rewardsData.cardLancerScorpion',
    defaultMessage: 'SCORPION LANCER',
  },
  cardLancerSwarm: {
    id: 'rewardsData.cardLancerSwarm',
    defaultMessage: 'SWARM LANCER',
  },
  rewardsTitleLiveGold: {
    id: 'rewardsData.rewardsTitleLiveGold',
    defaultMessage: 'XBOX LIVE GOLD – 7-DAY ACCESS',
  },
  rewardsTitleGamePass: {
    id: 'rewardsData.rewardsTitleGamePass',
    defaultMessage: 'XBOX GAME PASS – 7-DAY CONSOLE ACCESS',
  },
  rewardsDescPacks: {
    id: 'myStuff.rewardsDescPacks',
    defaultMessage:
      'Upon redemption through Microsoft.com, in-game pack items will appear in your Gears 5 game inventory.',
    description: 'Rewards Description 3',
  },
  rewardsDescMembership: {
    id: 'myStuff.rewardsDescMembership',
    defaultMessage:
      'Your membership period begins upon redemption through Microsoft.com.',
    description: 'Rewards Description 1',
  },
});

const data = {
  chainExclusives: [
    {
      id: 'pack-walmart',
      tokenTypes: [
        {
          id: 'walmart',
          title: <FormattedMessage {...messages.packWalmart} />,
          desc: <FormattedMessage {...messages.rewardsDescPacks} />,
        },
      ],
      title: <FormattedMessage {...messages.packWalmart} />,
      // titleLong: 'WALMART EXCLUSIVE PACK',
      desc: {
        id: messages.descWalmart.id,
        defaultMessage: messages.descWalmart.defaultMessage,
      },
      slides: [
        {
          src: cardLancerSwarm,
          alt: '',
          title: <FormattedMessage {...messages.cardLancerSwarm} />,
          tokenType: 'walmart',
        },
        {
          src: cardBannerSwarm,
          alt: '',
          title: <FormattedMessage {...messages.cardBannerSwarm} />,
          tokenType: 'walmart',
        },
        {
          src: cardBoost,
          alt: '',
          title: <FormattedMessage {...messages.cardBoost} />,
          tokenType: 'walmart',
        },
      ],
    },
    {
      id: 'pack-circle-k',
      tokenTypes: [
        {
          id: 'circle-k',
          title: <FormattedMessage {...messages.packCircleK} />,
          desc: <FormattedMessage {...messages.rewardsDescPacks} />,
        },
      ],
      title: <FormattedMessage {...messages.packCircleK} />,
      // titleLong: 'WALMART EXCLUSIVE PACK',
      desc: {
        id: messages.descCircleK.id,
        defaultMessage: messages.descCircleK.defaultMessage,
      },
      slides: [
        {
          src: cardLancerDel,
          alt: '',
          title: <FormattedMessage {...messages.cardLancerDel} />,
          tokenType: 'circle-k',
        },
        {
          src: cardBannerDel,
          alt: '',
          title: <FormattedMessage {...messages.cardBannerDel} />,
          tokenType: 'circle-k',
        },
        {
          src: cardBoost,
          alt: '',
          title: <FormattedMessage {...messages.cardBoost} />,
          tokenType: 'circle-k',
        },
      ],
    },
  ],
  packs: [
    {
      id: 'pack-kait',
      tokenTypes: [
        {
          id: 'kait',
          title: <FormattedMessage tagName="p" {...messages.pack1} />,
          desc: <FormattedMessage {...messages.rewardsDescPacks} />,
        },
        {
          id: 'livegold',
          title: (
            <FormattedMessage tagName="p" {...messages.rewardsTitleLiveGold} />
          ),
          desc: <FormattedMessage {...messages.rewardsDescMembership} />,
        },
        {
          id: 'gamepass',
          title: (
            <FormattedMessage tagName="p" {...messages.rewardsTitleGamePass} />
          ),
          desc: <FormattedMessage {...messages.rewardsDescMembership} />,
        },
      ],
      title: <FormattedMessage tagName="p" {...messages.pack1} />,
      // titleLong: 'GEAR PACK 1',
      desc: {
        id: messages.desc1.id,
        defaultMessage: messages.desc1.defaultMessage,
      },
      slides: [
        {
          src: cardXboxLiveGold,
          alt: '',
          title: <FormattedMessage {...messages.cardXboxGold} />,
          tokenType: 'livegold',
        },
        {
          src: cardXboxGamePass,
          alt: '',
          title: <FormattedMessage {...messages.cardGamePass} />,
          tokenType: 'gamepass',
        },
        {
          src: cardBannerKait,
          alt: '',
          title: <FormattedMessage {...messages.cardBannerKait} />,
          tokenType: 'kait',
        },
        {
          src: cardSupplyDrops,
          alt: '',
          title: <FormattedMessage {...messages.cardSupplyDrops} />,
          tokenType: 'kait',
        },
        {
          src: cardBoost,
          alt: '',
          title: <FormattedMessage {...messages.cardBoost} />,
          tokenType: 'kait',
        },
      ],
    },
    {
      id: 'pack-jd',
      tokenTypes: [
        {
          id: 'jd',
          title: <FormattedMessage tagName="p" {...messages.pack2} />,
          desc: <FormattedMessage {...messages.rewardsDescPacks} />,
        },
        {
          id: 'livegold',
          title: (
            <FormattedMessage tagName="p" {...messages.rewardsTitleLiveGold} />
          ),
          desc: <FormattedMessage {...messages.rewardsDescMembership} />,
        },
        {
          id: 'gamepass',
          title: (
            <FormattedMessage tagName="p" {...messages.rewardsTitleGamePass} />
          ),
          desc: <FormattedMessage {...messages.rewardsDescMembership} />,
        },
      ],
      title: <FormattedMessage tagName="p" {...messages.pack2} />,
      desc: {
        id: messages.desc2.id,
        defaultMessage: messages.desc2.defaultMessage,
      },
      slides: [
        {
          src: cardXboxLiveGold,
          alt: '',
          title: <FormattedMessage {...messages.cardXboxGold} />,
          tokenType: 'livegold',
        },
        {
          src: cardXboxGamePass,
          alt: '',
          title: <FormattedMessage {...messages.cardGamePass} />,
          tokenType: 'gamepass',
        },
        {
          src: cardBannerJd,
          alt: '',
          title: <FormattedMessage {...messages.cardBannerJd} />,
          tokenType: 'jd',
        },
        {
          src: cardSupplyDrops,
          alt: '',
          title: <FormattedMessage {...messages.cardSupplyDrops} />,
          tokenType: 'jd',
        },
        {
          src: cardBoost,
          alt: '',
          title: <FormattedMessage {...messages.cardBoost} />,
          tokenType: 'jd',
        },
      ],
    },
    {
      id: 'pack-marcus',
      tokenTypes: [
        {
          id: 'marcus',
          title: <FormattedMessage tagName="p" {...messages.pack3} />,
          desc: <FormattedMessage {...messages.rewardsDescPacks} />,
        },
      ],
      title: <FormattedMessage tagName="p" {...messages.pack3} />,
      desc: {
        id: messages.desc3.id,
        defaultMessage: messages.desc3.defaultMessage,
      },
      slides: [
        {
          src: cardBannerMarcus,
          alt: '',
          title: <FormattedMessage {...messages.cardBannerMarcus} />,
          tokenType: 'marcus',
        },
        {
          src: cardSupplyDrops,
          alt: '',
          title: <FormattedMessage {...messages.cardSupplyDrops} />,
          tokenType: 'marcus',
        },
        {
          src: cardBoost,
          alt: '',
          title: <FormattedMessage {...messages.cardBoost} />,
          tokenType: 'marcus',
        },
      ],
    },
    {
      id: 'pack-scorpion',
      tokenTypes: [
        {
          id: 'scorpion',
          title: <FormattedMessage tagName="p" {...messages.pack4} />,
          desc: <FormattedMessage {...messages.rewardsDescPacks} />,
        },
      ],
      title: <FormattedMessage tagName="p" {...messages.pack4} />,
      // titleLong: 'GEAR PACK 4',
      desc: {
        id: messages.desc4.id,
        defaultMessage: messages.desc4.defaultMessage,
      },
      slides: [
        {
          src: cardBannerScorpion,
          alt: '',
          title: <FormattedMessage {...messages.cardBannerScorpion} />,
          tokenType: 'scorpion',
        },
        {
          src: cardSupplyDrops,
          alt: '',
          title: <FormattedMessage {...messages.cardSupplyDrops} />,
          tokenType: 'scorpion',
        },
        {
          src: cardBoost,
          alt: '',
          title: <FormattedMessage {...messages.cardBoost} />,
          tokenType: 'scorpion',
        },
      ],
    },
    {
      id: 'pack-lancers',
      tokenTypes: [
        {
          id: 'lancers',
          title: <FormattedMessage tagName="p" {...messages.pack5} />,
          desc: <FormattedMessage {...messages.rewardsDescPacks} />,
        },
      ],
      title: <FormattedMessage tagName="p" {...messages.pack5} />,
      // titleLong: 'GEAR PACK 5',
      desc: {
        id: messages.desc5.id,
        defaultMessage: messages.desc5.defaultMessage,
      },
      slides: [
        {
          src: cardLancerKait,
          alt: '',
          title: <FormattedMessage {...messages.cardLancerKait} />,
          tokenType: 'lancers',
        },
        {
          src: cardLancerJd,
          alt: '',
          title: <FormattedMessage {...messages.cardLancerJd} />,
          tokenType: 'lancers',
        },
        {
          src: cardLancerMarcus,
          alt: '',
          title: <FormattedMessage {...messages.cardLancerMarcus} />,
          tokenType: 'lancers',
        },
        {
          src: cardLancerScorpion,
          alt: '',
          title: <FormattedMessage {...messages.cardLancerScorpion} />,
          tokenType: 'lancers',
        },
        // {
        //   src: cardSupplyDrops,
        //   alt: '',
        //   title: <FormattedMessage {...messages.cardSupplyDrops} />,
        // },
        {
          src: cardBoost,
          alt: '',
          title: <FormattedMessage {...messages.cardBoost} />,
          tokenType: 'lancers',
        },
      ],
    },
    {
      id: 'pack-boost',
      tokenTypes: [
        {
          id: 'boost',
          title: <FormattedMessage tagName="p" {...messages.packBoost} />,
          desc: <FormattedMessage {...messages.rewardsDescPacks} />,
        },
      ],
      title: <FormattedMessage tagName="p" {...messages.packBoost} />,
      // titleLong: 'GEAR PACK BOOST',
      desc: {
        id: messages.desc6Plus.id,
        defaultMessage: messages.desc6Plus.defaultMessage,
      },
      slides: [
        {
          src: cardBoost,
          alt: '',
          title: <FormattedMessage {...messages.cardBoost} />,
          tokenType: 'boost',
        },
      ],
    },
  ],
};

export default data;
