import logoEsrbUs from '../../images/logo-esrb-en-US.png';
import logoEsrbGb from '../../images/logo-esrb-en-GB.png';
import logoEsrbDe from '../../images/logo-esrb-de-DE.png';
import logoEsrbFr from '../../images/logo-esrb-fr-CA.png';

import logoRs from '../../images/logo-rs.svg';
import logoGears from '../../images/logo-gears5.png';
import logoXbox from '../../images/logo-xbox.png';

const config = {
  'en-US': {
    logoLinks: [
      {
        url: 'https://www.esrb.org/ratings-guide/',
        src: logoEsrbUs,
        alt: '',
      },
      {
        url: 'http://rockstarenergy.com',
        src: logoRs,
        alt: '',
      },
      {
        url: 'https://gearsofwar.com',
        src: logoGears,
        alt: '',
      },
      {
        url: 'https://www.xbox.com',
        src: logoXbox,
        alt: '',
      },
    ],
  },
  'en-CA': {
    logoLinks: [
      {
        url: 'https://www.esrb.org/ratings-guide/',
        src: logoEsrbFr, // logoEsrbUs,
        alt: '',
      },
      {
        url: 'http://rockstarenergy.com',
        src: logoRs,
        alt: '',
      },
      {
        url: 'https://gearsofwar.com',
        src: logoGears,
        alt: '',
      },
      {
        url: 'https://www.xbox.com',
        src: logoXbox,
        alt: '',
      },
    ],
  },
  'en-GB': {
    logoLinks: [
      {
        url: 'http://www.pegi.info/en/index/',
        src: logoEsrbGb,
        alt: '',
      },
      {
        url: 'http://rockstarenergy.com',
        src: logoRs,
        alt: '',
      },
      {
        url: 'https://gearsofwar.com',
        src: logoGears,
        alt: '',
      },
      {
        url: 'https://www.xbox.com',
        src: logoXbox,
        alt: '',
      },
    ],
  },
  'de-DE': {
    logoLinks: [
      {
        url: 'https://usk.de',
        src: logoEsrbDe,
        alt: '',
      },
      {
        url: 'http://rockstarenergy.com',
        src: logoRs,
        alt: '',
      },
      {
        url: 'https://gearsofwar.com',
        src: logoGears,
        alt: '',
      },
      {
        url: 'https://www.xbox.com',
        src: logoXbox,
        alt: '',
      },
    ],
  },
  'es-MX': {
    logoLinks: [
      {
        url: 'https://www.esrb.org/ratings-guide/',
        src: logoEsrbUs,
        alt: '',
      },
      {
        url: 'http://rockstarenergy.com',
        src: logoRs,
        alt: '',
      },
      {
        url: 'https://gearsofwar.com',
        src: logoGears,
        alt: '',
      },
      {
        url: 'https://www.xbox.com',
        src: logoXbox,
        alt: '',
      },
    ],
  },
  // 'fr-CA': {
  //   logoLinks: [
  //     {
  //       url: '/',
  //       src: logoEsrbFr,
  //       alt: '',
  //     },
  //     {
  //       url: '/',
  //       src: logoRs,
  //       alt: '',
  //     },
  //     {
  //       url: '/',
  //       src: logoGears,
  //       alt: '',
  //     },
  //     {
  //       url: '/',
  //       src: logoXbox,
  //       alt: '',
  //     },
  //   ],
  // },
};

export default config;
