import React from 'react';

const PrivacyGb = () => (
  <div>
    <p>
      This Privacy Policy constitutes an integral part of the Terms & Conditions
      that govern your use of the gears.rockstarenergy.com website (“Site”),
      which is owned and operated by Rockstar, Inc whose headquarters are at 101
      Convention Center Dr, Las Vegas, NV 89109-2001. (“Rockstar”). This Privacy
      Policy also constitutes part of the terms and conditions (such as official
      rules) that govern your participation in any and all online and offline
      activities sponsored by, or associated with, Rockstar (each, a “Rockstar
      Sponsored Event”).
    </p>
    <p>
      Rockstar respects your online privacy. This Privacy Policy informs you of
      what information we may collect from or about you on our Site, how we may
      use such information, and your rights and choices regarding our use of
      your information. PLEASE READ THIS PRIVACY POLICY CAREFULLY BEFORE USING
      OUR SITE. By using our Site or participating in a Rockstar Sponsored
      Event, you acknowledge that you have read this Privacy Policy.
    </p>
    <p>
      Rockstar is data controller of all personal data submitted to this Site.
      Where personal data relates to UK products, services and/or promotions, it
      will be shared with AG Barr Plc, the distributor of Rockstar products in
      the UK. The registered address of AG Barr Plc is at Westfield House, 4
      Mollins Road, Cumbernauld G68 9HD. AG Barr Plc is a data controller of
      personal data Rockstar shares with it and will comply with the terms of
      this policy. In this policy, “We” shall refer to either (i) Rockstar; or
      (ii) Rockstar and AG Barr Plc as appropriate.
    </p>

    <ol>
      <li>
        <p>
          <strong>TYPES OF INFORMATION WE COLLECT</strong>
        </p>
        <ol type="a">
          <li>
            Personal Information. This refers to your personally identifiable
            information, which may include your first and last name, telephone
            number, cellular number, text number, email address, social media
            account information for Instagram, Twitter, Facebook, or mailing
            address (as defined below). Many areas and features on our Site do
            not require registration and may be accessed by you without having
            to disclose any Personal Information. However, to the extent you
            voluntarily provide your Personal Information to us, we collect such
            information. We collect Personal Information from you: (i) when you
            register to become a member on our Site; (ii) when you register to
            participate in a Rockstar Sponsored Event; (iii) when you apply for
            a job; and (iv) when you submit feedback or queries.
          </li>
          <li>
            Aggregate and Anonymous Information. This refers to any information
            that does not identify you individually. For example, we collect
            aggregate various traffic data regarding our Site. We may also
            combine “User Activity Information” (as described below) about you
            and other users of our Site to generate aggregate information that
            reflects activities, habits, preferences, interests, etc. about our
            users as a group. In addition, we may take Personal Information and
            make it non-personally identifiable, such as by aggregating your
            information with information about other individuals, or by removing
            personally-identifiable elements (such as names) so as to anonymize
            your information.
          </li>
          <li>
            User Activity Information. This refers to information we collect
            about user activities on our Site. For example, our Site servers
            automatically collect and log clickstream data for all visitors to
            our Site, such as IP addresses, page requests, pages visited,
            content viewed, clicks and search queries made, etc. We also use
            cookies and web beacons to collect user activity information on our
            Site.
          </li>
        </ol>
        <p>
          To the extent we use or store User Activity Information in a manner
          that could identify a specific user individually, it will be treated
          as Personal Information. Otherwise, User Activity Information will be
          treated as Aggregate and Anonymous Information.
        </p>
        <p>We do not receive personal data about you from third parties.</p>
      </li>
      <li>
        <p>
          <strong>OUR USE OF INFORMATION</strong>
        </p>
        <p>
          We use information collected from and/or about our users and customers
          to improve our products and services, to improve the pages and content
          of our Site, to administer and maintain operations of our Site, to
          manage relationships with our users and customers, to offer, market
          and advertise products and services to our users and customers, and to
          conduct market research and analysis.
        </p>
        <ol type="a">
          <li>
            For Marketing and Advertising Purposes. We may use your Personal
            Information and/or User Activity Information to offer, market and
            advertise to you, on our Site and/or via email, our and/or
            third-party products and services that may be of interest to you.
            You may withdraw your consent to marketing at any time by contacting
            us using the details below.
          </li>
          <li>
            For Market Research Purposes. We may use Aggregate and Anonymous
            Information (including such information derived from Personal
            Information and User Activity Information) to conduct market
            research and analysis for ourselves as well as for our sponsors and
            business partners.
          </li>
        </ol>
        <p>
          We will only use your personal data when the law allows us to. Most
          commonly, we will use your personal data in the following
          circumstances:
        </p>
        <ul>
          <li>
            Where we need it to perform the contract we are about to enter into
            or have entered into with you;
          </li>
          <li>
            Where it is necessary for our legitimate interests (or those of a
            third party) and your interests and fundamental rights do not
            override those interests; and
          </li>
          <li>
            Where we need to comply with a legal or regulatory obligation.
          </li>
        </ul>
      </li>
      <li>
        <p>
          <strong>DISCLOSURE TO THIRD PARTIES</strong>
        </p>
        <ol type="a">
          <li>
            Disclosure to Non-Affiliates for Direct Marketing/Advertising
            Purposes. Where you give your consent for us to do so, we may share
            your Personal Information with non-affiliated third parties so that
            they may offer, market and advertise products and services directly
            to you.
          </li>
          <li>
            Disclosure to Co-Sponsors. From time to time, we may co-sponsor
            (online and offline) events and/or product/service offerings with
            non-affiliated third parties. If you participate in such
            co-sponsored events or offerings, any Personal Information and User
            Activity Information we collect from you based on your participation
            may be shared with our co-sponsors who may use such information for
            their own purposes. This information will only be used for marketing
            purposes where you have consented to receive marketing
            correspondence.
          </li>
          <li>
            Disclosure to Service Providers. We may disclose your Personal
            Information and User Activity Information to our outside vendors and
            contractors who need to access such information in order to perform
            their services (including without limitation
            survey/contest/sweepstakes administration and prize fulfillment,
            data research and analysis, data collection and processing, data
            storage, data security, hosting and technical support for our Site,
            and marketing and advertising support) to us (collectively, “Service
            Providers”). We require that our Service Providers use your
            information shared by us solely for the purposes of performing their
            services to us and that they maintain the confidentiality, security
            and integrity of such information and not further disclose the
            information to others.
          </li>
          <li>
            Disclosure Under Other Circumstances. We may disclose your Personal
            Information and User Activity Information to others if doing so is
            permitted by law, or in our good faith belief, is reasonably
            necessary to comply with any legal process, law enforcement, to
            enforce this Privacy Policy, respond to an emergency, or protect the
            rights, property or safety of Rockstar, AG Barr and/or the public.
          </li>
          <li>
            Unrestricted Disclosure of Aggregate and Anonymous Information.
            Given the anonymous, non-personally identifiable nature of such
            information, there are no restrictions under this Privacy Policy on
            how we may use or disclose such information. For example, we may
            freely share such information with non-affiliated third parties who
            may use such data for their own marketing, advertising, research, or
            other business purposes. We may also freely share such information
            with our Service Providers in order for them to perform services for
            us.
          </li>
        </ol>
      </li>
      <li>
        <p>
          <strong>INFORMATION DISCLOSURE: YOUR CHOICES</strong>
        </p>
        <p>
          You may opt-out of receiving marketing correspondence at any time by
          sending a request to us via email at
          optout.marketing@rockstarenergy.com, or by using the “Unsubscribe”
          link provided in a marketing email you may have previously received
          from us. Please note, however, that your opt-out will apply only to
          marketing and promotional emails from us and will not stop email
          communications from us relating to your Transaction or Request, such
          as notice of a material change in this Privacy Policy and/or the Terms
          and Conditions for our Site, notice of an actual or suspected security
          breach that affects your Personal Information stored by or for us,
          etc.
        </p>
      </li>
      <li>
        <p>
          <strong>YOUR RIGHTS</strong>
        </p>
        <p>
          Under applicable data protection legislation, you have the right under
          certain circumstances to:
        </p>
        <ul>
          <li>
            Request access to your Personal Information (commonly known as a
            “data subject access request”). This enables you to receive a copy
            of the Personal Information we hold about you and to check we are
            lawfully processing it;
          </li>
          <li>
            Request correction of the Personal Information that we hold about
            you. This enables you to have any incomplete or inaccurate
            information we hold about you corrected;
          </li>
          <li>
            Request erasure of your Personal Information. This enables you to
            ask us to delete or remove Personal Information where there is no
            good reason for us continuing to process it. You also have the right
            to ask us to delete or remove your Personal Information where you
            have exercised your right to object to processing (see below);
          </li>
          <li>
            Object to processing of your Personal Information where we are
            relying on a legitimate interest (or those of a third party). You
            also have the right to object where we are processing your Personal
            Information for direct marketing purposes;
          </li>
          <li>
            Request the restriction of processing of your Personal Information.
            This enables you to ask us to suspend the processing of Personal
            Information about you, for example if you want us to establish its
            accuracy or the reason for processing it;
          </li>
          <li>
            Request the transfer of your Personal Information to another party;
            and
          </li>
          <li>
            Withdraw your consent to the processing of your Personal
            Information, where we rely on your consent.
          </li>
        </ul>
        <p>
          If you would like to exercise any of your rights above, please contact
          us using the details below.
        </p>
      </li>
      <li>
        <p>
          <strong>HOW WE PROTECT YOUR INFORMATION</strong>
        </p>
        <p>
          We understand the importance of protecting the security and integrity
          of personal information that our customers have shared with us and
          will endeavor to safeguard your Personal Information. While we will
          attempt to safeguard your Personal Information, we cannot guarantee
          the absolute security of such information. You understand and agree
          that we shall not generally be liable for any breach of the security
          of your Personal Information resulting from causes or events that are
          beyond our control, including, without limitation, your own act or
          omission, corruption of storage media, defects in third-party data
          security products or services, power failures, natural phenomena,
          riots, acts of vandalism, hacking, sabotage, or terrorism. Please note
          that any information that you post in any public, community or
          interactive areas on our Site (e.g. chat rooms, bulletin boards,
          message boards, and discussion groups) will be accessible to, and may
          be collected and used by, others and may result in unsolicited or
          unwanted messages or contact from others.
        </p>
        <p>
          The information that you submit to our Site will be received in the
          USA by Rockstar. Where personal data is processed outside the European
          Economic Area, we will take steps necessary in accordance with
          applicable data protection legislation to ensure your information is
          treated securely and in accordance with this privacy policy, in
          particular ensuring that appropriate technical and organisational
          safeguards are in place.
        </p>
        <p>
          We will only retain your Personal Information for as long as necessary
          to fulfil the purposes we collected it for. To determine the
          appropriate retention period, we consider the amount, nature, and
          sensitivity of the personal data, the potential risk of harm from
          unauthorised use or disclosure of your personal data, the purposes for
          which we process your personal data and whether we can achieve those
          purposes through other means, and the applicable legal requirements.
        </p>
      </li>
      <li>
        <p>
          <strong>USE OF COOKIES</strong>
        </p>
        <p>
          We may send cookies, which are small pieces of data, to your web
          browser to facilitate your use of the Site. Cookies help us deliver
          content specific to your interests and permit our servers to recall
          information from prior to visits to our Site. We may use information
          collected from cookies together with Personal Information we have
          collected from you. We do not use cookies to access information on
          your computer or device. You can choose whether to accept cookies when
          first visiting our site, also by adjusting the settings of your
          browser. If your browser is set to reject cookies, you may still enter
          our Site, but you may not have full access to all areas within our
          Site.
        </p>
      </li>
      <li>
        <p>
          <strong>LINKS TO THIRD-PARTY WEBSITES</strong>
        </p>
        <p>
          Our Site may contain links to third-party websites that are now owned
          or controlled by or affiliated with Rockstar. Rockstar is not
          responsible for the privacy practices of such third-party sites. Once
          you enter such a third-party site, this Privacy Policy will no longer
          apply, and any information collected from or about you on that
          third-party site will be governed by the privacy policy of that third
          party. Such third-party sites’ privacy policies and practices may be
          substantially different from those of Rockstar. They may send their
          own cookies to you and may collect data about you and make use of that
          data in ways that we would not. You access such third-party sites
          entirely at your own risk. You should always read the privacy policy
          for a third-party site before disclosing any personal information on
          such site.
        </p>
      </li>
      <li>
        <p>
          <strong>PROTECTING CHILDREN UNDER 13</strong>
        </p>
        <p>
          Our Site is not designed or intended for children under the age of 13,
          and we do not knowingly collect personal information from children
          under 13 on our Site. Persons under the age of 13 are prohibited from
          registering on our Site. If we discover that any registration is made
          by a person under 13, such registration will be immediately and
          permanently canceled and removed from our Site, with or without
          notice.
        </p>
      </li>
      <li>
        <p>
          <strong>CHANGES TO PRIVACY POLICY</strong>
        </p>
        <p>
          We reserve the right to modify this Privacy Policy from time to time
          in our sole discretion. For example, we may amend this Privacy Policy
          in order to address new developments in consumer privacy laws, or to
          conform to changing industry practices. If we make changes to this
          Privacy Policy, we will announce and post such changes on our Site for
          the general public. If you are a registered user, we may also notify
          you of the changes via email. By continuing to use our Site after such
          notice, you are bound by this Privacy Policy as modified.
        </p>
      </li>
      <li>
        <p>
          <strong>OUR CONTACT INFORMATION</strong>
        </p>
        <p>
          Should you have questions about this Privacy Policy or our information
          collection, use and disclosure practices, you may contact us at
          privacy@rockstarenergy.com or dataprotection@agbarr.co.uk. We will use
          reasonable efforts to respond promptly to requests, questions or
          concerns you may have regarding our use of Personal Information about
          you.
        </p>
        <p>
          You have the right to make a complaint at any time to the Information
          Commissioner’s Office (ICO), the UK supervisory authority for data
          protection issues (www.ico.org.uk). We would, however, appreciate the
          chance to deal with your concerns before you approach the ICO so
          please contact us in the first instance using the details set out
          above.
        </p>
      </li>
    </ol>
  </div>
);

export default PrivacyGb;
