import React from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import { injectIntl, intlShape } from 'gatsby-plugin-intl';

import CollectorCansStyled from './CollectorCans.style';

import Heading from '../Heading/Heading';
import Carousel from '../Carousel/Carousel';

import { configIntl, configMx } from './config';

const messages = defineMessages({
  title1: {
    id: 'collectorCans.title1',
    defaultMessage: 'Collector',
    description: 'Title 1',
  },
  title2: {
    id: 'collectorCans.title2',
    defaultMessage: 'Cans',
    description: 'Title 2',
  },
  subtitle: {
    id: 'collectorCans.subtitle',
    defaultMessage:
      'Collect all 6 original designs created by UK-based illustrator Luke Preece. Each design in the collection is uniquely inspired by characters and elements featured in Gears 5.',
    description: 'Subtitle',
  },
});

const CollectorCans = ({ intl }) => {
  const localeConfig =
    configIntl[intl.locale] || configIntl[Object.keys(configIntl)[0]];

  const slidesMx =
    intl.locale === 'es-MX' &&
    configMx.cansGallery.map((slide, index) => (
      <div className="CollectorCans__listItem" key={index.toString()}>
        <img src={slide.src} alt={slide.alt} />
        {slide.overlay}
      </div>
    ));

  return (
    <CollectorCansStyled>
      <div className="CollectorCans__inner">
        <div className="CollectorCans__header">
          <Heading className="CollectorCans__heading" as="h3">
            <FormattedMessage {...messages.title1} />
            <FormattedMessage {...messages.title2} />
          </Heading>

          <div className="CollectorCans__subtitle">
            <FormattedMessage {...messages.subtitle} />
          </div>
        </div>
      </div>

      {intl.locale === 'es-MX' ? (
        <div className="CollectorCans__list">{slidesMx}</div>
      ) : (
        <Carousel slides={localeConfig.cansGallery} />
      )}
    </CollectorCansStyled>
  );
};

CollectorCans.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(CollectorCans);
