export function setCookie(name, value, days) {
  let expires;
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = `; expires=${date.toGMTString()}`;
  } else {
    expires = '';
  }
  document.cookie = `${name}=${value}${expires}; path=/`;
}

export function getCookie(cookieName) {
  let cookieStart;
  let cookieEnd;
  if (document.cookie.length > 0) {
    cookieStart = document.cookie.indexOf(`${cookieName}=`);
    if (cookieStart !== -1) {
      cookieStart = cookieStart + cookieName.length + 1;
      cookieEnd = document.cookie.indexOf(';', cookieStart);
      if (cookieEnd === -1) {
        cookieEnd = document.cookie.length;
      }
      return unescape(document.cookie.substring(cookieStart, cookieEnd));
    }
  }
  return '';
}
