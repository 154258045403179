import React from 'react';

const RulesCa = () => (
  <div>
    <h3>
      <strong>
        Rockstar Energy – Gears 5 Gewinnspiel – Teilnahmebedingungen
      </strong>
    </h3>

    <ol>
      <li>
        Lesen Sie diese Teilnahmebedingungen bitte sorgfältig durch. Durch das
        Absenden eines Teilnahmeformulars bestätigen Sie, dass Sie diese
        Bedingungen gelesen und akzeptiert haben.
      </li>
      <li>
        Veranstalter des Gewinnspiels ist die Rockstar, Inc, PO Box 27740, Las
        Vegas, NV 89126, USA (wir, unser, uns). Verantwortliche Stelle im Sinne
        des Datenschutzes ist ebenfalls die Rockstar, Inc, PO Box 27740, Las
        Vegas, NV 89126, USA, an die Ihre Daten zur Teilnahme an dem
        Gewinnspiel, wie Name, E-Mail-Adresse sowie Ihre Handynummer für die
        Registrierung übermittelt werden. Wir weisen hierbei darauf hin, dass
        nach EU-behördlichen Feststellungen ein mit der EU vergleichbares
        angemessenes Datenschutzniveau in den USA nicht gewährleistet ist.
      </li>
      <li>
        <strong>Teilnahmeberechtigung:</strong> Teilnahmeberechtigt sind alle
        gemeldete Einwohner der Bundesrepublik Deutschland, die zum Zeitpunkt
        der Anmeldung mindestens 18 Jahre alt sind. Davon ausgeschlossen sind
        (i) Mitarbeiter der PepsiCo Deutschland GmbH; (ii) alle Mitarbeiter von
        Dritten, die an der Umsetzung des Gewinnspiels beteiligt sind (z.B.
        beteiligte Agenturen); sowie (iii) die engsten Familienangehörigen zu
        (i) und (ii). Als Nachweis Ihrer Teilnahmeberechtigung können wir die
        nach unserem Ermessen erforderlichen Informationen verlangen,
        insbesondere Identitätspapiere (Ausweis/Führerschein) zum Nachweis Ihres
        Alters.
      </li>
      <li>
        <p>
          <strong>
            Teilnahmefrist: Um am Gewinnspiel teilzunehmen, melden Sie sich
          </strong>{' '}
          zwischen dem 01. September 2019, 00.00 Uhr und dem 30. November 2019.
          23.59 Uhr an. Diese Periode wird in zwei (2) Eintrittszeiträume
          unterteilt:
        </p>

        <ol type="i">
          <li>
            einundneunzig (91) tägliche Eintrittszeiträume, die jeweils von
            Mittag bis Mittag (beginnend um 12:00:01 Uhr ET und endend um
            11:59:59 Uhr ET am folgenden Tag) während des gesamten
            Gewinnspielzeitraums stattfinden.
          </li>
          <li>
            eine (1) Hauptpreiseinreichungsfrist, die mit der Gewinnspielzeit
            übereinstimmt.
          </li>
        </ol>
      </li>
      <li>
        <p>
          <strong>Die Verlosungen/ Aufbau der Aktion:</strong>
        </p>
        <ul>
          <li>
            <p>
              <strong>Tagesverlosungen:</strong> Aus dem täglichen
              Verlosungspool werden fünf (5) Gewinner durch eine zufällige
              Ziehung an den Daten ausgewählt.
            </p>
          </li>
          <li>
            <p>
              <strong>Hauptgewinnverlosung:</strong> Für die Verlosung des
              Hauptgewinns wird ein (1) Gewinner durch eine zufällige Ziehung
              während des Gewinnspielzeitraums ausgewählt. Die Ziehung wird vom
              Administrator unter allen gültigen Einreichungen durchgeführt, die
              im entsprechenden Verlosungspool enthalten sind.
            </p>
          </li>
        </ul>
      </li>
      <li>
        <p>
          <strong>Die Gewinner</strong>
        </p>
        <ul>
          <li>
            <p>
              <strong>Wie werden die Gewinner ermittelt?</strong> Die Gewinner
              werden nach dem Zufallsprinzip ausgelost.
            </p>
          </li>
          <li>
            <p>
              <strong>Wie erfahre ich, dass ich gewonnen habe?</strong> Wir
              benachrichtigen jeden Gewinner bis zum Ende des nächsten
              Arbeitstages nach der Auslosung. Wir benachrichtigen die Gewinner
              unter der E-Mail-Adresse, die uns bei der Anmeldung mitgeteilt
              wurde. Jeder hat zweiundsiebzig (72) Stunden Zeit, ab dem
              Zeitpunkt, an dem der Administrator eine Benachrichtigung über den
              Gewinner sendet, um auf die Benachrichtigung zu antworten. Für den
              Fall, dass ein potentieller Gewinner nicht auf eine
              Benachrichtigung des Gewinners innerhalb von zweiundsiebzig (72)
              Stunden antwortet, verliert er / sie sein / ihr Recht auf den
              Preis und der Preis kann nach alleinigem Ermessen des Sponsors an
              einen alternativen Gewinner vergeben werden, wenn die Zeit es
              erlaubt.
            </p>
          </li>
        </ul>
      </li>
      <li>
        <p>
          <strong>Die Preise</strong>
        </p>
        <ul>
          <li>
            Gesamtwert aller Preise ist: 121.506,00 €. Alle Garantien und
            Gewährleistungen für Preise unterliegen den Allgemeinen
            Geschäftsbedingungen des Herstellers. Der Preis wird vergeben wie er
            ist. Preise bestehen nur aus den Gegenständen, die als Teil des
            Preises aufgeführt sind. Kein Bargeld oder andere Ersetzung durch
            einen Gewinner sind erlaubt, der Sponsor kann allerdings einen Preis
            oder einen Teil davon für einen Preis von gleichem oder höherem Wert
            nach alleinigem Ermessen des Sponsors aus irgendeinem Grund
            ersetzen. Die Preise sind nicht übertragbar. Die Gewinner sind
            verantwortlich für die Zahlung aller anfallenden Steuern, die mit
            der Annahme ihres Preises verbunden sind.
          </li>
          <li>
            <strong>Wie/ Wann bekomme ich meinen Preis?</strong> Wir senden
            Ihren Preis per Post innerhalb von einem Monat nach der Mitteilung
            Ihrer Postadresse (nachdem wir Sie über Ihren Gewinn benachrichtigt
            haben).
          </li>
          <li>
            <strong>Der Hauptgewinn:</strong> Schwarzer Pick-up im Rockstar
            Design
          </li>
          <li>
            <strong>Was ist das?</strong> Ford Ranger Wild Track, schwarz,
            6-Gang-Automatik, Allrad, schwarze Polster
          </li>
          <li>
            <strong>Welchen Bedingungen unterliegt dieser Gewinn?</strong> Das
            genaue Jahr des Fahrzeugs liegt im alleinigen Ermessen des Sponsors
            und hängt von der Zeit ab, in der der Gewinner bestätigt wird und
            das Fahrzeug an den Händler ausgeliefert wird. Das Fahrzeug wird
            ausschließlich vom Sponsor geliefert. Der Wertunterschied zwischen
            hier angegebenem Wert und dem Wert zum Zeitpunkt der
            Preisbenachrichtigung, falls vorhanden, wird nicht in bar oder
            anderweitig vergeben. Alle Upgrades gehen zu Lasten des
            Hauptgewinners. Das Paket, die Fabrikoptionen und andere
            Besonderheiten, einschließlich der Farbe, werden vom Sponsor
            festgelegt und unterliegen der Verfügbarkeit. Der Gewinner des
            Hauptpreises muss volljährig sein und muss vor der Inbesitznahme des
            Fahrzeugs einen aktuell gültigen Führerschein und einen
            Versicherungsnachweis vorlegen. Andernfalls kann dies die
            Verweigerung des Hauptpreises und die Auswahl eines alternativen
            potentiellen Hauptpreisgewinner zur Folge haben. Der
            Hauptpreisgewinner ist für alle damit verbundenen Händlergebühren
            einschließlich aller Titel, Lizenz, Versicherung, Registrierung,
            Händlervorbereitung, anfallende Steuern oder Gebühren, Ziel- und
            Bearbeitungsgebühren und andere mit dem Fahrzeug verbundene
            Gebühren, einschließlich aller Reise- und Transportkosten im
            Zusammenhang mit der Abholung Fahrzeug vom lokalen Händler, wo es
            geliefert wird, verantwortlich. Alle anderen Kosten, die hier nicht
            ausdrücklich angegeben sind, liegen in der Verantwortung des
            Hauptgewinners. Der Gewinner des Hauptpreises muss das Fahrzeug
            innerhalb von dreißig (30) Tagen nach dem Datum, an dem der Sponsor
            den Gewinner des Hauptpreises darüber informiert, dass der
            Hauptpreis bei dem angegebenen Händler abgeholt werden kann oder der
            Gewinn verfällt. Alle Garantien und Gewährleistungen für den
            Hauptpreis unterliegen den Bedingungen des Herstellers, und der
            Hauptpreisgewinner stimmt zu, sich ausschließlich auf solche
            Hersteller für einen solchen Garantie- oder Garantieanspruch zu
            verlassen.
          </li>
          <li>
            <strong>Die täglichen Gewinne:</strong>
            <br />
            <span>Es gibt</span>
            <br />
            <ol>
              <li>
                455 Tagespreise (5 Tagespreise pro Tagesziehung) – Gears 5
                Ultimate Edition Games für XBOX im Wert von 80,00 € pro Stück
              </li>
              <li>
                91 Tagespreise (1 Tagespreis pro Tagesziehung) – Konsolen Paket:
                XBOX Gears 5 Limited Edition Konsole + Gears 5 Ultimate Edition
                Games für XBOX im Wert von 499,99 € pro Stück + 80,00 € pro
                Stück.
              </li>
            </ol>
          </li>
        </ul>
      </li>
      <li>
        <p>
          <strong>Wie Sie teilnehmen:</strong>
        </p>
        <ul>
          <li>
            Kaufen Sie ein Aktionspack von 500ml Rockstar während der Aktion.
          </li>
          <li>Sie finden einen Teilnahmecode unter der Öffnungslasche.</li>
          <li>
            <p>
              Gehen Sie auf gears.rockstarenergy.com/de, wo Sie aufgefordert
              werden:
            </p>
            <ol type="i">
              <li>die Online-Registrierung auszufüllen.</li>
              <li>
                geben Sie Ihren Namen, Ihre E-Mail-Adresse sowie Ihre
                Handynummer an und
              </li>
              <li>tragen Sie den Code ein.</li>
            </ol>
            <p>
              Auf der Aktions-Webseite erhalten Sie entsprechende Angaben zur
              Registrierung und Teilnahme.
            </p>
          </li>
          <li>
            <p>
              Die Zahl der gültigen Anmeldungen während der Aktion ist
              unbegrenzt. Die Aktionswebseite hat allerdings Kontrollen
              eingebaut, um betrügerische Aktivitäten zu verhindern. Dies
              bedeutet, dass die folgenden Bedingungen bei der Eingabe von Codes
              gelten:
            </p>
            <ul>
              <li>
                Wenn Sie 15 ungültige Codes innerhalb von 24 Stunden eingeben,
                werden Sie auf der Aktionswebseite für die nächsten 24 Stunden
                gesperrt.
              </li>
              <li>
                Der Gewinn von mehr als einem Preis aus den jeweiligen
                Verlosungstypen während der Aktionszeit ist nicht möglich.
                Soweit Sie als Gewinner einer Verlosung ausgelost werden, bei
                der Sie bereits gewonnen haben, wird stattdessen ein anderer
                Gewinner für diese Verlosung ausgewählt.
              </li>
              <li>
                Die Teilnehmer müssen ihre Öffnungslasche sowie den Kaufnachweis
                aufbewahren, da wir diese ggf. als Nachweis benötigen.
              </li>
              <li>
                Ihre Teilnahme müssen Sie persönlich anmelden. Sie dürfen keine
                Software oder andere Methoden verwenden, um sich mehrfach
                anzumelden.
              </li>
              <li>
                Es gibt keinen Barauszahlung oder eine andere Alternative für
                einen Preis (oder Teile davon). Wir behalten uns das Recht vor,
                einen Preis (einschließlich Teile davon) durch eine
                gleichwertige oder höherwertige Alternative zu ersetzen; dies
                gilt jedoch nur dann, soweit es nach unserem Ermessen infolge
                von Ereignissen außerhalb unserer zumutbaren Kontrolle
                erforderlich ist. Der Gewinner kann seinen Anspruch auf die
                Annahme seines Gewinns nur mit unserer Zustimmung auf eine
                andere Person übertragen.
              </li>
              <li>
                Wir dürfen uns nach eigenem Ermessen für die Vergabe eines
                Preises an einen anderen Gewinner entscheiden, der auf der
                gleichen Basis wie der ursprüngliche Gewinner gezogen wurde,
                soweit der ursprüngliche Gewinner unsere Überprüfung seiner
                Nachweise bzw. seiner Berechtigung nicht besteht oder nach
                Ziffer 18 disqualifiziert wird.
              </li>
              <li>
                Falls wir innerhalb einer Woche nach der Gewinnmitteilung keinen
                Kontakt zum Gewinner herstellen können, behalten wir uns vor,
                nach unserem eigenen Ermessen, einen neuen Gewinner zu
                ermitteln. Wir werden eine angemessene Anzahl von Versuchen im
                Laufe der Woche unternehmen, um den Kontakt zu Gewinnern
                herzustellen, bevor wir den Gewinn wegen fehlender Rückmeldung
                des Gewinners zurücknehmen. Dazu benutzen wir die
                Kontaktangaben, die uns der Gewinner bei der Anmeldung
                mitgeteilt hat.
              </li>
              <li>
                Wir behalten uns das Recht vor, ohne Ankündigung diese Aktion zu
                unterbrechen, zu ändern oder einzustellen und/oder diese
                Bedingungen zu ändern, soweit wir der Meinung sind, dass dies
                infolge von Ereignissen außerhalb unserer zumutbaren Kontrolle
                absolut notwendig ist. In diesem Fall werden wir alles tun, um
                die dadurch verursachte Enttäuschung gering zu halten.
              </li>
              <li>
                Wir dürfen jeden disqualifizieren, der (i) diese Bedingungen
                verletzt; oder (ii) in anderer Weise die Aktion in ihrem Bestand
                untergräbt oder gefährdet.
              </li>
              <li>
                Unsere Entscheidung in allen Angelegenheiten im Zusammenhang mit
                dieser Aktion ist endgültig.
              </li>
              <li>
                Soweit dies gesetzlich zulässig ist, haften wir nicht für: (i)
                Ihre Nichtteilnahme an dieser Aktion wegen Störungen ihres
                Telefons bzw. Netzwerks; (ii) Ihre Nichtteilnahme an dieser
                Aktion wegen technischer Probleme mit unserer Website; oder für
                (iii) Verluste oder Sach- oder Personenschäden, die die Gewinner
                im Zusammenhang mit der Annahme der Gewinne erleiden. Wir geben
                keine Gewährleistung oder Garantie im Zusammenhang mit Gewinnen.
                Dennoch werden wir die Gewährleistung bzw. Garantie, die wir vom
                Hersteller bzw. Verkäufer der Preise erhalten haben, soweit wie
                möglich an die Gewinner weitergeben.
              </li>
              <li>
                Wir benutzen die von Ihnen im Zusammenhang mit der Aktion
                mitgeteilten persönlichen Daten nur für die Durchführung dieser
                Aktion einschließlich der Weitergabe der Informationen an unsere
                Marketingpartner. Wir gehen davon aus, dass uns die Gewinner mit
                der Veröffentlichung ihrer Gewinne mit Inhalten in den Medien,
                in Bildern und in den sozialen Netzwerken unter Beachtung ihrer
                schutzwürdigen Interessen beauftragen möchten. Insoweit dürfen
                wir auch die mitgeteilten Inhalte im Rahmen der von uns
                benutzten Medien zur Förderung des Unternehmens und/oder der
                Marke Rockstar verwenden.
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ol>

    <p style={{ textAlign: 'center' }}>
      <strong>
        Rockstar – Gears 5 Gewinnspiel Einwilligungserklärung für die
        Übermittlung personenbezogener Daten in die USA
      </strong>
    </p>

    <p>
      [ ] Ich erkläre hiermit meine Einwilligung zur Übermittlung meiner
      personenbezogenen Daten an die Rockstar, Inc, PO Box 27740, Las Vegas, NV
      89126, USA, als für den Datenschutz verantwortliche Stelle für das
      Rockstar – Gears 5 Gewinnspiel, das in der Zeit vom 01. September 2019 bis
      30. November 2019 stattfindet. Diese Daten betreffen meinen Namen, E-
      Mail-Adresse sowie meine Handynummer, die für die Registrierung zur
      Teilnahme am Rockstar – Gears 5 Gewinnspiel unter
      gears.rockstarenergy.com/de einzugeben sind. Mir ist bekannt, dass ein in
      den USA im Vergleich zu den EU-Ländern vergleichbares angemessenes
      Datenschutzniveau nicht gewährleistet ist. Die Daten werden gleichwohl nur
      zu dem Zweck verarbeitet oder genutzt, zu dessen Erfüllung sie übermittelt
      werden. Mir ist ebenfalls bekannt, dass ich diese Zustimmungserklärung
      jederzeit frei widerrufen kann. In diesem Fall ist meine (weitere)
      Teilnahme am Gewinnspiel nicht möglich.
    </p>
  </div>
);

export default RulesCa;
